import {Component, OnDestroy, ViewChild} from '@angular/core';
import {ReplaySubject, takeUntil} from 'rxjs';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {StructSpinnerService, UiModule} from '@struct/ui/index';
import {CommonModule} from '@angular/common';
import {AccountsHeaderComponent} from '../../accounts-ui/accounts-header/accounts-header.component';
import {TenantDetailsComponent} from '../tenant-details/tenant-details.component';
import {TenantApiService} from "@struct/services/account-management";
import { cryptoHelper } from '@struct/helpers/lib/helpers/crypto-helper';
import { Tenant } from '@struct/models/accountmanagement/domain/tenants/tenant';
import { CreateTenantCommand } from '@struct/models/accountmanagement/domain/tenants/commands/create-tenant-command';

@Component({
    selector: 'accounts-create-tenant',
    templateUrl: './create-tenant.component.html',
    styleUrls: ['./create-tenant.component.scss'],
    imports: [CommonModule, UiModule, AccountsHeaderComponent, TenantDetailsComponent]
})
export class CreateTenantComponent implements OnDestroy {
  private destroyed$ = new ReplaySubject();
  @ViewChild('tenantForm') tenantForm!: NgForm;
  showError = false;
  tenant: Tenant = new Tenant();

  constructor(private tenantApi: TenantApiService, private spinnerService: StructSpinnerService, private router: Router) {
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  submitTenant() {
    if (this.tenantForm.valid && this.tenant) {
      this.spinnerService.changeShowSpinner(true);
      this.tenantApi
        .createTenant(
          new CreateTenantCommand({
            uid: cryptoHelper.randomUUID(),
            name: this.tenant.name,
            slug: this.tenant.slug,
            deploymentInfo: this.tenant.deploymentInfo,
            subscriptionUid: this.tenant.subscriptionUid,
            isEarlyAdopter: this.tenant.isEarlyAdopter
          })
        )
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.spinnerService.changeShowSpinner(false);
          this.router.navigateByUrl('/tenants');
        });
    } else {
      for (const i in this.tenantForm.controls) {
        this.tenantForm.controls[i].markAsTouched();
        this.showError = true;
      }
    }
  }
}
