<div class="flex absolute -top-64 w-64 h-64 bg-gray-50 z-50 shadow-lg p-2" *ngIf="hoverUrl">
    <img [src]="hoverUrl + '?width=200&height=200&bgcolor=white'" class="h-auto w-auto max-w-full max-h-full object-cover object-center animate-in fade-in duration-1000" />
  </div>

<div class="flex gap-1 w-full flex-wrap max-h-16 overflow-y-auto struct-scrollbar">
  <ng-container *ngFor="let url of urls">
    <div class="flex flex-w bg-gray-50 items-center justify-center text-gray-300 h-16 w-16 relative rounded ring-2 ring-gray-50 p-1 group" (mouseover)="hoverUrl = url" (mouseleave)="hoverUrl = ''">
      <img [src]="url + '?width=200&height=200&bgcolor=white'" class="h-auto w-auto max-w-full max-h-full object-cover object-center animate-in fade-in duration-500" />
    </div>
  </ng-container>
</div>
