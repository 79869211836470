import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@struct/ui/ui.module';
import { Router, RouterModule } from '@angular/router';
import { SignInRequest } from '@struct/models/accountmanagement/api/models/authentication/sign-in-request';
import { FormGroup } from '@angular/forms';
import { UserModel } from '@struct/models/accountmanagement/api/models/authentication/user-model';
import { UiSignInContract } from '@struct/ui/struct-sign-in';
import { Observable, map } from 'rxjs';
import { SignInResponse } from '@struct/models/accountmanagement/api/models/authentication/sign-in-response';
import { AuthenticationService } from '../authentication.service';
import { UsersApiService } from '@struct/services/account-management';

@Component({
    selector: 'accounts-sign-in',
    imports: [CommonModule, UiModule, TranslateModule, RouterModule],
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  loginForm!: FormGroup;
  authFailed: boolean = false;
  authenticatedUser: UserModel | null = null;
  signInService: UiSignInContract;

  constructor(private authService: AuthenticationService, private userApi: UsersApiService, private router: Router) {
    this.signInService = new AccountSignInContract(this.authService, this.userApi, this.router);
  }

  ngOnInit(): void {
    console.log('Sign in component initialized');
    this.authService.getAuthenticatedUser().subscribe(user => {
      this.authenticatedUser = user;
      if (user !== null) {
        this.router.navigate(['/']);
      }
    });
  }
}

class AccountSignInContract implements UiSignInContract {

  constructor(private authService: AuthenticationService, private userApi: UsersApiService, private router: Router) {}

  signIn(request: SignInRequest): Observable<SignInResponse> {
    return this.authService.signIn(request).pipe(
      map(response => {
        if (response.isSuccess) {
          this.router.navigate(['/']);
        }
        return response;
      })
    );
  }

  public signInUsingMicrosoft() : void {
    this.authService.redirectToEntraIdLogin(null);
  }
}
