import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, Input,
  Output,
  ViewChild
} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatFormFieldAppearance } from '@angular/material/form-field';


@Component({
    selector: 'struct-input',
    templateUrl: './struct-input.component.html',
    styleUrls: ['./struct-input.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: false
})
export class StructInputComponent implements AfterViewInit {

  @Input() inputType: string | null = null;
  @Input() required: boolean;
  @Input() appearance: string | null = null;
  @Input() tabIndex: number | null = null;
  @Input() textAlign: string | null = null;
  @Input() placeholder: string | null = null;
  @Input() hintStart: string | null = null;
  @Input() hintEnd: string | null = null;
  @Input() floatLabelValue = true;
  @Input() matAutocomplete: MatAutocomplete | null = null;

  _value: number | string | null | null = null;
  @Input() set value(val: number | string | null){
    this._value = val;
  };
  @Input() disabled: boolean | string;
  @Input() name: string | null = null;
  @Output() keyDown: EventEmitter<KeyboardEvent|MouseEvent> = new EventEmitter<KeyboardEvent |MouseEvent>();
  @Output() valueChange = new EventEmitter();

  @ViewChild('matPrefixRef') matPrefixRef: ElementRef<HTMLDivElement> | undefined = undefined;
  @ViewChild('matSuffixRef') matSuffixRef: ElementRef<HTMLDivElement> | undefined = undefined;

  showSuffix = true;
  showPrefix = true;

  random: string;

  constructor() {
    if (this.inputType ===null) {
      this.inputType = "text";
    }
    this.disabled = false;
    this.required = false;
    this.random = this.makeRandom();
  }

  ngAfterViewInit(): void {

    this.showSuffix = this.matSuffixRef !== undefined && this.matSuffixRef.nativeElement.children.length > 0;
    this.showPrefix = this.matPrefixRef !== undefined && this.matPrefixRef.nativeElement.children.length > 0;
  }

  private makeRandom() {
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-";
    let text = "";
    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  onNgModelChange(newModel: number | string): void {
      this.valueChange.emit(newModel);
  }

  getApperance(): MatFormFieldAppearance {
    if (this.appearance) {
      return this.appearance as MatFormFieldAppearance;
    }
    return 'outline';
  }

  onKeyDown($event: KeyboardEvent | MouseEvent) {
    this.keyDown.emit($event);
  }
}
