import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'accounts-list',
    templateUrl: './accounts-list.component.html',
    styleUrls: ['./accounts-list.component.scss'],
    imports: [CommonModule]
})
export class AccountsListComponent {
  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entities: any;

  @Input()
  entityIconName: string | null = null;

  @Input()
  entityName: string | null = null;
}
