import { Component } from '@angular/core';
import { StructNotificationService } from '@struct/ui/struct-notification/struct-notification.service';
import { StructDialogService } from '@struct/ui/struct-dialog';
import { StructConfirmDialogService} from "@struct/ui/struct-confirm-dialog";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

// dialogs
import { StructMediaPickerAddNewDialogComponent } from './struct-media-picker-add-new-dialog/struct-media-picker-add-new-dialog.component';
import { StructMediaPickerEditDialogComponent } from './struct-media-picker-edit-dialog/struct-media-picker-edit-dialog.component';
import { StructMediaPickerMaximizeDialogComponent } from './struct-media-picker-maximize-dialog/struct-media-picker-maximize-dialog.component';
import { StructMediaPickerReplaceDialogComponent } from './struct-media-picker-replace-dialog/struct-media-picker-replace-dialog.component';
import { StructMediaPickerDownloadDialogComponent } from './struct-media-picker-download-dialog/struct-media-picker-download-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { FileHandle } from '../directives/FileHandle';

@Component({
    selector: 'struct-media-picker',
    templateUrl: './struct-media-picker.component.html',
    styleUrls: ['./struct-media-picker.component.scss'],
    standalone: false
})
export class StructMediaPickerComponent {
  files: any[] = [

  ];

  constructor(
    private dialog: StructDialogService,
    private notificationService: StructNotificationService,
    private tr: TranslateService,
    private confirmDialogService: StructConfirmDialogService
  ) {}

  /**
   * on file drop handler
   */
  onFileDropped($event: FileHandle[]) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files: FileHandle[]) {
    this.prepareFilesList(files);
  }

  /**
   * Add new file from files list
   */
  addNewFile() {
    //this.files.splice(index, 1);
    this.dialog.open(StructMediaPickerAddNewDialogComponent, {});
  }

  /**
   * Edit file from files list
   * @param index (File index)
   */
  editFile(index: number) {
    //this.files.splice(index, 1);
    console.log("Edit file: " + index);
    this.dialog.open(StructMediaPickerEditDialogComponent, {});
  }

  /**
   * Replace file from files list
   * @param index (File index)
   */
  replaceFile(index: number) {
    //this.files.splice(index, 1);
    console.log("Edit file: " + index);
    this.dialog.open(StructMediaPickerReplaceDialogComponent, {});
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Go to file from files list
   * @param index (File index)
   */
  goToFile(index: number) {
    //this.files.splice(index, 1);
    console.log("Go to file: " + index);
  }

  /**
   * Maximize file from files list
   * @param index (File index)
   */
  maximizeFile(index: number) {
    //this.files.splice(index, 1);
    console.log("Maximize file: " + index);
    this.dialog.open(StructMediaPickerMaximizeDialogComponent, {});
  }


  /**
   * Download file from files list
   * @param index (File index)
   */
  downloadFile(index: number) {
    //this.files.splice(index, 1);
    console.log("Download file: " + index);
    this.dialog.open(StructMediaPickerDownloadDialogComponent, {});
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 50);
      }
    }, 100);
  }

  sortDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.files, event.previousIndex, event.currentIndex);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<FileHandle>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }
}
