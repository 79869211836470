import {Component, OnInit} from '@angular/core';
import {IRowAction} from '@struct/ui/struct-table/models/row-action';
import {RowActionResult} from '@struct/ui/struct-table/models/row-action-result';
import {IRowSingleAction} from '@struct/ui/struct-table/models/row-single-action';
import {TableColumn} from '@struct/ui/struct-table/models/table-column';
import {ITableDefinition} from '@struct/ui/struct-table/models/table-definition';
import {Observable, of} from 'rxjs';
import {Row} from '@struct/ui/struct-table/models/row';
import {CommonModule} from '@angular/common';
import {UiModule} from '@struct/ui/ui.module';
import {AccountsHeaderComponent} from '../../accounts-ui/accounts-header/accounts-header.component';
import {DisplayType, Query} from "@struct/models/struct/shared/search";
import {TenantApiService} from "@struct/services/account-management";
import { TranslateService } from '@ngx-translate/core';
import { ClientSideSearchDataProvider, ClientSideSearchTableDefinition } from '@struct/ui/struct-table';
import { Tenant } from '@struct/models/accountmanagement/domain/tenants/tenant';
import { User } from '@struct/models/struct/app/domain/usermanagement/user';
import { Router } from '@angular/router';
import { StructDialogService } from '@struct/ui/struct-dialog';

@Component({
    selector: 'accounts-tenants',
    templateUrl: './tenants.component.html',
    styleUrls: ['./tenants.component.scss'],
    imports: [CommonModule, UiModule, AccountsHeaderComponent]
})
export class TenantsComponent implements OnInit {
  tenants: Tenant[] | null = null;
  user: User | null = null;
  rows: Row[] = [];
  tableDefinition: ITableDefinition | null = null;
  tableScope = 'tenants';
  filter: Query | null = null;
  rowRouteTemplate: string | null = null;

  constructor(private tenantApi: TenantApiService, private tr : TranslateService, private router : Router, private dialogService: StructDialogService) {}
  
  ngOnInit(): void {
    const rowActions: IRowAction[] = [new EditTenantAction(), new DeleteTenantAction(), new CloneTenantToTemplateAction()];
    this.tr
      .get([
        'Tenants.Name',
        'Tenants.Slug',
        'Tenants.Created',
        'Tenants.CreatedBy',
        'Tenants.DeploymentStamp',
        "Tenants.Subscription",
        "Tenants.Status",
        "Tenants.DbTemplate"
      ])
      .subscribe(t => {
        const tableColumns = [
          new TableColumn({id: 'uid', title: 'Uid', type: DisplayType.Text}),
          new TableColumn({id: 'created', title: t['Tenants.Created'], type: DisplayType.Date}),
          new TableColumn({id: 'createdBy', title: t['Tenants.CreatedBy'], type: DisplayType.Text}),
          new TableColumn({id: 'deploymentStamp', title: t['Tenants.DeploymentStamp'], type: DisplayType.Text}),
          new TableColumn({id: 'subscription', title: t['Tenants.Subscription'], type: DisplayType.Text}),
          new TableColumn({id: 'status', title: t['Tenants.Status'], type: DisplayType.FixedList}),
          new TableColumn({id: 'dbTemplate', title: t['Tenants.DbTemplate'], type: DisplayType.Text}),
          new TableColumn({id: 'name', title: t['Tenants.Name'], type: DisplayType.Text}),
          new TableColumn({id: 'slug', title: t['Tenants.Slug'], type: DisplayType.Text}),
        ];
        const dataProvider = new AttributeDataProvider(this.tenantApi);
        this.tableDefinition = new ClientSideSearchTableDefinition('tenants', tableColumns, rowActions, 'uid', 'attribute_24', '/tenants/:id', dataProvider);
      });

    }

    createTenant(): void {
      this.router.navigateByUrl('/tenants/create');
    }
}

export class AttributeDataProvider implements ClientSideSearchDataProvider {
  constructor(private tenantApi: TenantApiService) {
  }

  getData(): Observable<any[]> {
    return this.tenantApi.getTenants();
  }
}


export class DeleteTenantAction implements IRowSingleAction {
  name = 'Delete tenant';
  icon = 'delete';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onClick(rowId: string): Observable<RowActionResult> {
    return of(new RowActionResult(true));
  }
}

export class EditTenantAction implements IRowSingleAction {
  name = 'Edit tenant';
  icon = 'edit';

  onClick(rowId: string): Observable<RowActionResult> {
    return of(new RowActionResult(false, `/tenants/${rowId}`));
  }
}


export class CloneTenantToTemplateAction implements IRowSingleAction {
  name = 'Clone tenant to template';
  icon = 'copy';

  onClick(rowId: string): Observable<RowActionResult> {
    return of(new RowActionResult(false, `/tenants/clone/${rowId}`));
  }
}

