import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NotificationData } from '../models/notification-data';

@Component({
    selector: 'struct-success-notification',
    templateUrl: './success-notification.component.html',
    styleUrls: ['./success-notification.component.scss'],
    standalone: false
})
export class SuccessNotificationComponent  {

  percentageTillClose = 0;

  constructor(
    public snackbarRef: MatSnackBarRef<SuccessNotificationComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: NotificationData
  ) {
  }  
}
