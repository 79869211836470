import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableColumn } from '../models/table-column';
import { ITableRow } from '../models/row';
import { StructTableService } from '../struct-table.service';
import {DisplayType} from "@struct/models/struct/shared/search";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'struct-sorting-table',
    templateUrl: './struct-sorting-table.component.html',
    styleUrls: ['./struct-sorting-table.component.scss'],
    standalone: false
})
export class StructSortingTableComponent implements OnInit {
  @Input() rows: ITableRow[] = [];
  @Input() columns: TableColumn[] = [];
  @Output() hasChanges: EventEmitter<boolean> = new EventEmitter<boolean>();
  sortField: string | null = null;
  sortAsc = true;
  originalSortOrder: ITableRow[] = [];

  constructor(public tableService: StructTableService) {}

  ngOnInit(): void {
    this.rows.forEach(row => {
      this.originalSortOrder.push(row)
    });
  }

  drop(event: CdkDragDrop<HTMLElement, HTMLElement>) {
    moveItemInArray(this.rows, event.previousIndex, event.currentIndex);
    this.sortField = null;
    this.sortAsc = true;
    this.hasChanges.emit(this.testIfSortHasChanged());
  }

  compare(column: TableColumn, a: ITableRow, b: ITableRow): number {
    if (a.data[column.id] < b.data[column.id]) {
      return -1;
    }
    if (a.data[column.id] > b.data[column.id]) {
      return 1;
    }
    return 0;
  }

  sortBy(column: TableColumn): void {
    if (this.sortField !== column.id) {
      this.sortAsc = true;
      this.sortField = column.id;
    } else if (this.sortField === column.id && this.sortAsc) {
      this.sortAsc = false;
      this.sortField = column.id;
    } else {
      this.sortAsc = false;
      this.sortField = null;
    }

    this.rows.sort((a, b) => this.compare(column, a, b));
    if (!this.sortAsc) {
      this.rows.reverse();
    }
    this.hasChanges.emit(this.testIfSortHasChanged());
  }

  private testIfSortHasChanged(): boolean {
    for(let i = 0; i < this.rows.length; i++) {
      if(this.rows[i].id != this.originalSortOrder[i].id) {
        return true;
      }
    }
    return false;
  }

  public get DisplayType(): typeof DisplayType {
    return DisplayType;
  }

  getValue(row: ITableRow, column: string) {
    return row.data[column];
  }

  trackById(index: number, row: ITableRow) {
    return row.id;
  }
}
