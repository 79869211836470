// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMyTenantCreateTenantCommand  {
  name: string;
  slug: string;
  subscriptionUid: string;
  deploymentStampUid: string;
  databaseTemplateUid: string;
  adminsToInvite: string[];
}

export class MyTenantCreateTenantCommand  implements IMyTenantCreateTenantCommand {
  name = "";

  slug = "";

  subscriptionUid = "00000000-0000-0000-0000-000000000000";

  deploymentStampUid = "00000000-0000-0000-0000-000000000000";

  databaseTemplateUid = "00000000-0000-0000-0000-000000000000";

  adminsToInvite: string[] = [];


  constructor(data?: Partial<IMyTenantCreateTenantCommand>) {
    Object.assign(this, data);
  }
}