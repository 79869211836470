import { Component, Input } from '@angular/core';
import { DateFieldQuery, DateQueryOperator, FieldQuery } from '@struct/models/struct/shared/search/datafieldqueries';
import { StructColumnHeaderBaseComponent } from '../struct-column-header-base';
import { DisplayType } from '@struct/models/struct/shared/search/display-type';

enum DateSearchInputType {
  Date = 'date',
  DateOffset = 'dateoffset',
}

@Component({
    selector: 'struct-date-header',
    templateUrl: './struct-date-header.component.html',
    styleUrls: ['./struct-date-header.component.scss'],
    standalone: false
})
export class StructDateHeaderComponent extends StructColumnHeaderBaseComponent {
  inputType = DateSearchInputType.Date;
  dateInputTypes = DateSearchInputType;
  value: any;
  @Input({required: true}) displayType: DisplayType = DisplayType.DateTime;

  getFieldQuery(str: string | null | undefined): FieldQuery | null {
    if (str === null || str === undefined || str.length === 0) {
      return null;
    }

    const queryOperator = this.getQueryOperator(str);
    return new DateFieldQuery({
      fieldUid: this.column.id,
      queryOperator: queryOperator,
      filterValue: this.getCleanSearchValue(str, queryOperator),
    });
  }

  getQueryOperator(str: string): DateQueryOperator {
    if (str === null || str === undefined || str === '') {
      return DateQueryOperator.Equals;
    }
    if (str.startsWith('!""')) {
      return DateQueryOperator.IsNotEmpty;
    }
    if (str.startsWith('""')) {
      return DateQueryOperator.IsEmpty;
    }
    if (str.startsWith('!=')) {
      return DateQueryOperator.NotEquals;
    }
    if (str.startsWith('!')) {
      return DateQueryOperator.NotEquals;
    }
    if (str.startsWith('=')) {
      return DateQueryOperator.Equals;
    }
    if (str.startsWith('>=')) {
      return DateQueryOperator.LargerThanOrEqualTo;
    }
    if (str.startsWith('<=')) {
      return DateQueryOperator.SmallerThanOrEqualTo;
    }
    if (str.startsWith('>')) {
      return DateQueryOperator.LargerThan;
    }
    if (str.startsWith('<')) {
      return DateQueryOperator.SmallerThan;
    }
    return DateQueryOperator.Equals;
  }

  getUiText(query: FieldQuery) {
    const typed = query as DateFieldQuery;
    let prefix = '';
    if (typed.queryOperator === DateQueryOperator.Equals) {
      prefix = '';
    } else if (typed.queryOperator === DateQueryOperator.NotEquals) {
      prefix = '!';
    } else if (typed.queryOperator === DateQueryOperator.IsEmpty) {
      prefix = '""';
    } else if (typed.queryOperator === DateQueryOperator.IsNotEmpty) {
      prefix = '!""';
    } else if (typed.queryOperator === DateQueryOperator.SmallerThan) {
      prefix = '<';
    } else if (typed.queryOperator === DateQueryOperator.LargerThan) {
      prefix = '>';
    } else if (typed.queryOperator === DateQueryOperator.SmallerThanOrEqualTo) {
      prefix = '<=';
    } else if (typed.queryOperator === DateQueryOperator.LargerThanOrEqualTo) {
      prefix = '>=';
    }
    return prefix + typed.filterValue;
  }

  getCleanSearchValue(str: string, operator: DateQueryOperator): Date | null {
    //Remove first character
    if (operator === DateQueryOperator.LargerThan || operator === DateQueryOperator.SmallerThan) {
      return new Date(Date.parse(str.substring(1, str.length)));
    }
    //Remove first two characters
    if (operator === DateQueryOperator.LargerThanOrEqualTo || operator === DateQueryOperator.SmallerThanOrEqualTo || operator === DateQueryOperator.IsEmpty) {
      return new Date(Date.parse(str.substring(2, str.length)));
    }
    //Remove first three characters
    if (operator === DateQueryOperator.IsNotEmpty) {
      return new Date(Date.parse(str.substring(3, str.length)));
    }
    if (operator === DateQueryOperator.Equals && str.startsWith('=')) {
      return new Date(Date.parse(str.substring(1, str.length)));
    }
    if (operator === DateQueryOperator.NotEquals && str.startsWith('!=')) {
      return new Date(Date.parse(str.substring(2, str.length)));
    }
    if (operator === DateQueryOperator.NotEquals && str.startsWith('!')) {
      return new Date(Date.parse(str.substring(1, str.length)));
    }
    if(operator === DateQueryOperator.Equals && str.length > 0) {
      return new Date(Date.parse(str));
    }
    return null;
  }
}
