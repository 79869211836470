import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ReplaySubject} from "rxjs";
import { Icon } from './pack/icon';
import { IconService } from './icon.service';

@Component({
    selector: 'struct-icon-chooser-dialog',
    templateUrl: './struct-icon-chooser-dialog.component.html',
    styleUrls: ['./struct-icon-chooser-dialog.component.scss'],
    standalone: false
})
export class StructIconChooserDialogComponent implements OnInit, OnDestroy {
    availableIcons: Icon[] = [];
    selectedIcon: Icon | null = null;
    shownIcons: Icon[] = [];
    changed=true;
    private destroyed$ = new ReplaySubject();

    constructor(public dialogRef: MatDialogRef<StructIconChooserDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: SelectedIconDialogData, private iconService: IconService) {
    }

    ngOnDestroy(): void {
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }

    ngOnInit(): void {
        this.availableIcons= this.iconService.getIcons(['mat']);
        const preSelected = this.availableIcons.filter(p=>p.id===this.data.selectedIcon);
        if(preSelected.length>0){
            this.selectedIcon =preSelected[0];
        }
        this.onSearchChanged(null);
    }

    onSearchChanged(searchText: string | null): void {
        if (searchText === null || searchText.length === 0) {
            this.shownIcons = this.availableIcons;
        } else {
            this.shownIcons = this.availableIcons.filter(c => {
                return this.selectedIcon !== c && (c.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
            });
        }
    }

    selectIcon(icon: Icon): void {
        this.selectedIcon = icon;
        this.changed=icon.id!==this.data.selectedIcon;
    }

    updateIcon(): void {
        this.dialogRef.close(new SelectedIconDialogData(this.selectedIcon?.id ?? null));
    }

    cancel(): void {
        this.dialogRef.close();
    }
}

export class SelectedIconDialogData {
    selectedIcon: string | null = null;

    constructor(selectedIcon: string | null = null) {
        this.selectedIcon = selectedIcon;
    }
}
