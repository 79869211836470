import { Injectable } from '@angular/core';
import { AssetAdapterApiService } from '@struct/services/frontend-api';
import { Observable, Subject } from 'rxjs';
import { ResolvedAsset } from './ResolvedAsset';

@Injectable({ providedIn: 'root' })
export class DeferredAssetResolverService {
  constructor(private assetApi: AssetAdapterApiService) {}
  private timer: any | null = null;

  private pendingRequests: { assetIds: string[]; observer: Subject<ResolvedAsset[]> }[] = [];

  getAssetUrls(assetIds: string[]): Observable<ResolvedAsset[]> {
    clearTimeout(this.timer);
    const subject = new Subject<ResolvedAsset[]>();
    this.pendingRequests.push({ assetIds, observer: subject });

    this.timer = setTimeout(() => {
      const allAssetIds = this.pendingRequests.flatMap(req => req.assetIds);
      const uniqueAssetIds = Array.from(new Set(allAssetIds));
      const processedRequests = this.pendingRequests;
      this.pendingRequests = [];
      this.assetApi.getAsssetUrls(uniqueAssetIds).subscribe(x => {
        const resolvedAssets = Object.entries(x).flatMap(([key, url]) => new ResolvedAsset(key, url));
        processedRequests.forEach(req => {
          const assets = resolvedAssets.filter(asset => req.assetIds.indexOf(asset.assetId) !== -1);
          req.observer.next(assets);
          req.observer.complete();
        });
      });
    }, 10);

    return subject;
  }
}
