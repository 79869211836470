import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@struct/ui/ui.module';
import { RouterModule } from '@angular/router';
import { AuthenticationApiService } from '@struct/services/account-management';
import { ForgotPasswordCommand } from '@struct/models/struct/usermanagementmodule/commands/forgot-password-command';
import { Observable } from 'rxjs';
import { CommandResponse } from '@struct/models/struct/shared/models';

@Component({
    selector: 'accounts-forgot-password',
    imports: [CommonModule, UiModule, TranslateModule, RouterModule],
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  forgotPasswordService: AccountForgotPasswordContract;

  constructor(authApi: AuthenticationApiService) {
    this.forgotPasswordService = new AccountForgotPasswordContract(authApi);
  }
}

class AccountForgotPasswordContract {
  constructor(private authApi: AuthenticationApiService) {}
  forgotPassword(request: ForgotPasswordCommand) : Observable<CommandResponse> {
    return this.authApi.forgotPassword(request);
  }
}
