import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiModule } from '@struct/ui/ui.module';

@Component({
    selector: 'accounts-list-search',
    templateUrl: './accounts-list-search.component.html',
    styleUrls: ['./accounts-list-search.component.scss'],
    imports: [CommonModule, UiModule]
})
export class AccountsListSearchComponent {
  
  searchTerm = "";
  
  @Output()
  searchChanged = new EventEmitter<string>();

  @Input()
  placeholder : string | null = null;

  public search() : void {
    this.searchChanged.emit(this.searchTerm);
  }
  
}
