import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { StructDialogService } from '@struct/ui/struct-dialog';
import { PageChange } from '@struct/ui/struct-paginator';
import { SelectColumnsDialogData, SelectColumnsDialogDataResult, StructColumnsDialogComponent } from '@struct/ui/struct-table/struct-columns-dialog';
import { StructTableService } from './struct-table.service';
import { IRowAction } from './models/row-action';
import { IRowSingleAction } from './models/row-single-action';
import { TableColumn } from './models/table-column';
import { ITableDefinition } from './models/table-definition';
import { ITableRow } from './models/row';
import { UserColumn } from './models/user-column';
import { Observable, ReplaySubject, Subject, Subscription, debounceTime, of, switchMap, takeUntil, tap, map } from 'rxjs';
import { BooleanOperator, Query, SimpleQuery, DisplayType, BooleanQuery } from '@struct/models/struct/shared/search';
import { SearchDefinitionModel } from '@struct/models/struct/shared/models';
import { TextFieldQuery, TextQueryOperator } from '@struct/models/struct/shared/search/datafieldqueries';
import { AssetRenderingComponent } from '../base-classes/asset-rendering-component';

export enum TableDisplayMode {
  Table = 1,
  Card = 2,
}

@Component({
    selector: 'struct-table',
    templateUrl: './struct-table.component.html',
    styleUrls: ['./struct-table.component.scss'],
    standalone: false
})
export class StructTableComponent extends AssetRenderingComponent implements OnInit, OnDestroy {
  private _tableDefinition!: ITableDefinition;
  @Input({ required: true })
  get tableDefinition(): ITableDefinition {
    return this._tableDefinition;
  }
  set tableDefinition(value: ITableDefinition) {
    this._tableDefinition = value;
    if (this.ngOnInitCalled) {
      //We need to wait for the next tick to make sure the other input fields have been updated
      setTimeout(() => {
        this.initializeTable().subscribe(() => {
          this.initialized.emit();
        });
      }, 0);
    }
  }

  @Input() defaultColumnIds: string[] = [];
  @Input() pageSizeOptions: number[] = [10, 25, 50];
  @Input() showSaveSearchButtonWhenSearchIsNotEmpty = false;
  @Input() useTheseColumns: UserColumn[] | null = null;
  @Input() showPageSizeSelector = true;
  @Input() showFirstLastPageButton = false;
  @Input() defaultPageSize = 10;
  @Input() showFixedMediaColumn: boolean = false;
  @Input() showIcons = true;
  @Input() disableEntitySelection = false;
  @Input() allowMultiSelect = true;
  @Input() defaultDisplayMode: TableDisplayMode = TableDisplayMode.Table;
  @Output() rowsSelected = new EventEmitter<Set<string>>();
  @Output() initialized = new EventEmitter();
  @Output() filterRemoved = new EventEmitter();
  @Output() searchReset = new EventEmitter();
  @Output() saveSearchClicked = new EventEmitter();
  @Input() conditionalRowActionByProperty: string | null = null;
  @Input() invertedBooleanRowAction = false;
  @Input() showResetSearch = false;
  @Input() searchDebounceTime = 200;
  searchText: string | null = null;
  loaded = false;
  working = false;
  resizeObserver: ResizeObserver;
  pressed = false;
  currentResizeIndex = 0;
  startX = 0;
  startWidth = 0;
  resizableMousemove?: () => void;
  resizableMouseup?: () => void;
  singleSearchValue = '';
  searchSubscription: Subscription | null = null;
  singleSearchUpdate = new Subject<string>();

  TableDisplayMode = TableDisplayMode;

  booleanOptions = [
    { label: 'All', value: null },
    { label: 'Only true', value: 'true' },
    { label: 'Only false', value: 'false' },
  ];

  rows: ITableRow[] = [];
  displayedColumns: TableColumn[] = [];
  selectedRows = new Set<string>();
  searchDefinition: SearchDefinitionModel = new SearchDefinitionModel();
  availableColumns: TableColumn[] = [];
  totalHits = 0;
  searchIsEmpty = true;
  allSelected = false;
  someSelected = false;
  tableWidth: number | null = null;
  checkBoxColumnWidth: number | null = null;
  private ngOnInitCalled = false;
  private destroyed$ = new ReplaySubject();

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private renderer: Renderer2,
    public el: ElementRef,
    public tableService: StructTableService,
    private dialogService: StructDialogService,
    private elRef: ElementRef
  ) {
    super();
    this.resizeObserver = new ResizeObserver(() => {
      const newWidth = this.el.nativeElement.getBoundingClientRect().width;
      if (newWidth !== this.tableWidth) {
        this.tableWidth = newWidth;
        this.setColumnWidth();
        cdr.detectChanges();
      }
    });
    this.resizeObserver.observe(this.el.nativeElement);

    this.searchSubscription = this.singleSearchUpdate
      .pipe(
        tap(this.searchSubscription?.unsubscribe),
        debounceTime(this.searchDebounceTime),
        switchMap((searchText: string) => {
          this.singleSearchValue = searchText;
          this.searchDefinition.pageIndex = 0;
          return this.search();
        })
      )
      .subscribe();
  }

  @HostListener('document:keydown.control.shift.c', ['$event'])
  hotkeyManageColumns(event: KeyboardEvent): void {
    event.preventDefault();
    this.manageColumns();
  }

  @HostListener('document:keydown.control.shift.g', ['$event'])
  hotkeyGrid(event: KeyboardEvent): void {
    event.preventDefault();
    this.changeDisplayMode(TableDisplayMode.Card);
  }

  @HostListener('document:keydown.control.shift.s', ['$event'])
  hotkeySaveSearch(event: KeyboardEvent): void {
    event.preventDefault();
    this.saveSearch();
  }

  @HostListener('document:keydown.control.shift.h', ['$event'])
  hotkeyTable(event: KeyboardEvent): void {
    event.preventDefault();
    this.changeDisplayMode(TableDisplayMode.Table);
  }

  @HostListener('document:keydown.control.a', ['$event'])
  hotkeySelectAll(event: KeyboardEvent): void {
    event.preventDefault();
    this.selectAll();
  }

  @HostListener('document:keydown.esc', ['$event'])
  hotkeyDeselectAll(event: KeyboardEvent): void {
    if (this.selectedRows.size > 0) {
      event.preventDefault();
      this.deselectAll();
    }
  }

  ngOnInit(): void {
    this.ngOnInitCalled = true;
    this.defaultDisplayMode = this.tableService.getDisplayType(this.tableDefinition.tableScope) ?? this.defaultDisplayMode;
    this.initializeTable().subscribe(() => {
      this.initialized.emit();
    });
  }

  initializeTable(): Observable<void> {
    this.searchDefinition.pageSize = this.defaultPageSize;
    const userColumns = this.useTheseColumns !== null && this.useTheseColumns.length > 0 ? this.useTheseColumns : this.tableService.getUserColumns(this.tableDefinition.tableScope);
    const queries = this.tableService.getTableQuery(this.tableDefinition.tableScope);
    this.searchDefinition.pageIndex = this.tableService.getPage(this.tableDefinition.tableScope);
    this.tableWidth = this.el.nativeElement.getBoundingClientRect().width;
    this.checkBoxColumnWidth = 0;
    if (this.allowMultiSelect || this.showIcons) {
      this.checkBoxColumnWidth = this.showIcons ? 96 : 80;
    }

    return this.tableDefinition.getAvailableColumns().pipe(
      takeUntil(this.destroyed$),
      switchMap(x => {
        this.availableColumns = x;
        this.displayedColumns = [];
        if (userColumns !== null && userColumns.length > 0) {
          userColumns.forEach(c => {
            const column = this.availableColumns.find(x => x.id === c.id);
            if (column) {
              column.width = c.width;
              this.displayedColumns.push(column);
              this.setColumnQuery(column, queries);
            }
          });
        } else {
          const columns: TableColumn[] = [];

          this.defaultColumnIds.forEach(c => {
            const column = this.availableColumns.find(x => x.id === c);
            if (column) {
              columns.push(column);
              this.setColumnQuery(column, queries);
            }
          });
          this.displayedColumns = columns;
          this.setColumnWidth();
          this.tableService.setColumns(
            this.tableDefinition.tableScope,
            columns.map(x => new UserColumn({ id: x.id, width: x.width ?? 0 }))
          );
        }

        return this.search();
      })
    );
  }

  removeFilter(): void {
    this.filterRemoved.emit();
  }

  setColumnQuery(column: TableColumn, queries: SimpleQuery[]) {
    const query = queries.find(x => x.fieldQueries.length > 0 && x.fieldQueries[0].fieldUid === column.id);
    if (query !== undefined) {
      column.query = query;
    }
  }

  ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.el.nativeElement);
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  onResizeColumn(event: any, index: number) {
    this.currentResizeIndex = index;
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = event.target.parentNode.clientWidth;
    event.preventDefault();
    this.mouseMove(index);
  }

  mouseMove(index: number) {
    this.resizableMousemove = this.renderer.listen('document', 'mousemove', event => {
      if (this.pressed && event.buttons) {
        const dx = event.pageX - this.startX;
        const width = this.startWidth + dx;
        if (this.currentResizeIndex === index) {
          this.setColumnWidthChanges(index, width);
        }
      }
    });
    this.resizableMouseup = this.renderer.listen('document', 'mouseup', () => {
      if (this.pressed) {
        this.pressed = false;
        this.currentResizeIndex = -1;
        this.resizableMousemove?.();
        this.resizableMouseup?.();
        this.saveColumnSetup();
      }
    });
  }

  public booleanInputChanged(): void {
    this.search().subscribe();
  }

  public get DisplayType(): typeof DisplayType {
    return DisplayType;
  }

  onRowClick(row: ITableRow): void {
    if (this.tableDefinition.rowRouteTemplate !== null && this.isRowActionEnabled(row)) {
      this.router.navigate([this.tableDefinition.rowRouteTemplate.replace(':id', row.id)]);
    } else if (this.tableDefinition.rowRouteTemplate === null) {
      this.toggleRowSelection(row);
    }
  }

  resetColumnWidth(): void {
    if (this.tableWidth !== null && this.checkBoxColumnWidth !== null) {
      let perColumnWidth = (this.tableWidth - this.checkBoxColumnWidth) / this.displayedColumns.length;
      perColumnWidth = perColumnWidth < 120 ? 120 : perColumnWidth;
      this.displayedColumns.forEach(column => {
        column.width = perColumnWidth;
      });
    }
    this.saveColumnSetup();
  }

  setColumnWidth(): void {
    if (this.tableWidth !== null && this.checkBoxColumnWidth !== null) {
      this.displayedColumns.forEach(column => {
        if (column.width === null) {
          column.width = 120;
        }
      });

      const totalColumnWidth = this.displayedColumns.reduce((acc, column) => acc + (column.width ?? 0), 0);

      const columnfactor = (this.tableWidth - this.checkBoxColumnWidth) / totalColumnWidth;
      this.displayedColumns.forEach(column => {
        column.width = column.width ? column.width * columnfactor : 120;
      });
    }
  }

  setColumnWidthChanges(index: number, width: number) {
    const orgWidth = this.displayedColumns[index].width;
    const dx = width - (orgWidth ?? 0);
    if (dx !== 0) {
      const j = index + 1;
      const newWidth = (this.displayedColumns[j].width ?? 0) - dx;
      if (width > 120 && newWidth > 120) {
        this.displayedColumns[index].width = width;
        this.displayedColumns[j].width = newWidth;
      }
    }
  }

  drop(event: CdkDragDrop<HTMLElement, HTMLElement>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
    this.saveColumnSetup();
  }

  saveColumnSetup(): void {
    const c = this.displayedColumns.map(x => new UserColumn({ id: x.id, width: x.width ?? 0 }));
    this.tableService.setColumns(this.tableDefinition.tableScope, c);
  }

  saveQuery(): void {
    const queries: SimpleQuery[] = [];
    this.displayedColumns.forEach(column => {
      if (column.query !== null) {
        queries.push(column.query);
      }
    });

    this.tableService.setTableQuery(this.tableDefinition.tableScope, queries);
    this.tableService.setPage(this.tableDefinition.tableScope, this.searchDefinition.pageIndex);
  }

  getQuery(): SimpleQuery[] {
    return this.tableService.getTableQuery(this.tableDefinition.tableScope);
  }

  public getSelectedItems(): Set<string> {
    return this.selectedRows;
  }

  resetSearch(): void {
    this.displayedColumns?.forEach(x => {
      x.searchText = null;
      x.query = null;
    });
    this.displayedColumns?.forEach(x => (x.query = null));
    this.searchReset.emit();
    this.search().subscribe();
  }

  toggleRowSelection(row: ITableRow) {
    if(this.disableEntitySelection){
      return;
    }
    if (!this.allowMultiSelect) {
      this.deselectAll();
    }
    if (this.selectedRows.has(row.id)) {
      this.selectedRows.delete(row.id);
      row.selected = false;
    } else {
      this.selectedRows.add(row.id);
      row.selected = true;
    }
    this.calculateHowMuchIsSelected();
    this.rowsSelected.emit(this.selectedRows);
  }

  saveSearch(): void {
    if (this.searchIsEmpty && !this.showResetSearch) {
      return;
    }
    this.saveSearchClicked.emit(this.searchDefinition.searchQuery);
  }

  sortBy(column: TableColumn): void {
    if (this.searchDefinition.sortField !== column.id) {
      this.searchDefinition.sortAsc = true;
      this.searchDefinition.sortField = column.id;
    } else if (this.searchDefinition.sortField === column.id && this.searchDefinition.sortAsc) {
      this.searchDefinition.sortAsc = false;
      this.searchDefinition.sortField = column.id;
    } else {
      this.searchDefinition.sortAsc = false;
      this.searchDefinition.sortField = null;
    }
    this.search().subscribe();
  }

  getHelp(): void {
    console.log('open help');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRowMouseEnter(event: any): void {
    this.clearRowEvents();
    event.target.className += ' row-is-hover';
  }

  clearRowEvents(): void {
    const hoverRows = document.querySelectorAll('.row-is-hover');
    for (let i = 0; i < hoverRows.length; i++) {
      hoverRows[i].classList.remove('row-is-hover');
    }
  }

  manageColumns(): void {
    const dialog = this.dialogService.open<SelectColumnsDialogDataResult>(StructColumnsDialogComponent, new SelectColumnsDialogData(this.availableColumns, this.displayedColumns));
    dialog.subscribe(x => {
      if (x) {
        x.removedColumns.forEach(x => {
          x.searchText = null;
          x.sortAsc = false;
          x.width = null;
        });
        this.displayedColumns = x.selectedColumns;
        this.resetColumnWidth();
        this.search().subscribe();
      }
    });
  }

  executeAction(action: IRowAction, rowId: string) {
    const a = <IRowSingleAction>action;
    this.working = true;
    a.onClick(rowId, this)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(x => {
        this.working = false;
        if (x?.reloadSearch) {
          this.search(true).subscribe();
        }
        const route = x.getRoute(rowId);
        if (route) {
          this.router.navigateByUrl(route);
        }
      });
  }

  toggleAll(): void {
    if(this.disableEntitySelection){
      return;
    }
    if (!this.allSelected) {
      this.selectAll();
    } else {
      this.rows.forEach(x => (x.selected = false));
      this.allSelected = false;
      this.someSelected = false;
      this.selectedRows.clear();
      this.rowsSelected.emit(this.selectedRows);
    }
  }

  selectAll(): void {
    if(this.disableEntitySelection){
      return;
    }
    this.tableDefinition
      .getAllIds(this.searchDefinition.searchQuery)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(result => {
        result.forEach(id => {
          if (!this.selectedRows.has(id)) {
            this.selectedRows.add(id);
          }
        });
        this.rows.forEach(x => (x.selected = true));
        this.allSelected = true;
        this.someSelected = false;
        this.rowsSelected.emit(this.selectedRows);
      });
  }

  private calculateHowMuchIsSelected(searchIsReset: boolean | null = null): void {
    if (this.totalHits === 0) {
      this.someSelected = false;
      this.allSelected = false;
    } else {
      if (searchIsReset) {
        this.allSelected = this.selectedRows.size >= this.totalHits && this.rows.every(x => x.selected);
        this.someSelected = this.rows.some(x => x.selected) && !this.allSelected;
      } else {
        this.allSelected = this.selectedRows.size >= this.totalHits;
        this.someSelected = this.selectedRows.size > 0 && !this.allSelected;
      }
    }
  }

  selectAllOnThisPage(): void {
    if(this.disableEntitySelection){
      return;
    }
    this.rows.forEach(row => {
      if (!this.selectedRows.has(row.id)) {
        this.selectedRows.add(row.id);
        row.selected = true;
      }
    });
    this.calculateHowMuchIsSelected();
    this.rowsSelected.emit(this.selectedRows);
  }

  selectNoneOnThisPage(): void {
    if(this.disableEntitySelection){
      return;
    }
    this.rows.forEach(row => {
      if (this.selectedRows.has(row.id)) {
        this.selectedRows.delete(row.id);
        row.selected = false;
      }
    });
    this.calculateHowMuchIsSelected();
    this.rowsSelected.emit(this.selectedRows);
  }

  deselectAll(): void {
    if(this.disableEntitySelection){
      return;
    }
    this.rows.forEach(x => (x.selected = false));
    this.selectedRows.clear();
    this.allSelected = false;
    this.someSelected = false;
    this.rowsSelected.emit(this.selectedRows);
  }

  searchChanged(): void {
    this.searchSubscription?.unsubscribe();
    this.searchDefinition.pageIndex = 0;
    this.searchSubscription = this.search().subscribe();
  }

  pageChanged(change: PageChange): void {
    this.searchDefinition.pageIndex = change.pageIndex;
    this.searchDefinition.pageSize = change.pageSize;
    this.saveQuery();
    this.search().subscribe();
  }

  refresh(): Observable<void> {
    this.deselectAll();
    return this.search(true);
  }

  search(fromRefresh = false): Observable<void> {
    this.working = true;
    this.searchDefinition.searchQuery = this.buildSearchQuery();
    this.saveQuery();
    return this.tableDefinition
      .getSearchResult(
        this.searchDefinition,
        this.displayedColumns.map(x => x.id),
        fromRefresh
      )
      .pipe(takeUntil(this.destroyed$))
      .pipe(
        switchMap(source => {
          const rows = source.hits;
          rows.forEach(r => {
            if (this.selectedRows.has(r.id)) {
              r.selected = true;
            } else {
              r.selected = false;
            }
          });
          this.totalHits = source.totalHits;
          this.searchIsEmpty = this.isSearchEmpty(this.searchDefinition.searchQuery);
          this.calculateHowMuchIsSelected(true);
          return of(rows);
        }),
        switchMap(rows => {
          if (this.showFixedMediaColumn || this.defaultDisplayMode === TableDisplayMode.Card) {
            return this.tableDefinition.mapThumbnailInfo(rows).pipe(
              map(() => {
                this.rows = rows;
                this.loaded = true;
                this.working = false;
                return void 0;
              })
            );
          } else {
            this.rows = rows;
            this.loaded = true;
            this.working = false;
            return of(void 0);
          }
        })
      );
  }

  isSearchEmpty(query: Query | null): boolean {
    if (query === null || query === undefined) {
      return true;
    }
    if (query.queryType === BooleanQuery.name) {
      const q = query as BooleanQuery;
      return q.subQueries.every(q => this.isSearchEmpty(q));
    }
    if (query.queryType === SimpleQuery.name) {
      const q = query as SimpleQuery;
      return q.fieldQueries.length === 0;
    }
    return true;
  }

  buildSearchQuery(): Query {
    if (this.defaultDisplayMode === TableDisplayMode.Card) {
      if (this.singleSearchValue === '') {
        return new BooleanQuery();
      }
      const q = new BooleanQuery();
      const sq = new SimpleQuery({ booleanOperator: BooleanOperator.Or });
      this.tableDefinition.defaultSearchFields.forEach(f => {
        sq.fieldQueries.push(
          new TextFieldQuery({
            fieldUid: f,
            filterValue: this.singleSearchValue,
            queryOperator: TextQueryOperator.Contains,
          })
        );
      });
      q.subQueries.push(sq);
      return q;
    } else {
      const q = new BooleanQuery();
      q.booleanOperator = BooleanOperator.And;
      this.displayedColumns?.forEach(c => {
        const query = c.query;
        if (query !== null) {
          q.subQueries.push(query);
        }
      });
      return q;
    }
  }

  getValue(row: ITableRow, column: string) {
    const foundData = row.data[column];
    return foundData;
  }

  isRowActionEnabled(row: ITableRow): boolean {
    if (this.conditionalRowActionByProperty) {
      const enabled = row.data[this.conditionalRowActionByProperty] as boolean;
      return this.invertedBooleanRowAction ? !enabled : enabled;
    }
    return true;
  }

  trackById(index: number, row: ITableRow) {
    return row.id;
  }

  changeDisplayMode(mode: TableDisplayMode): void {
    this.tableService.setDisplayMode(this.tableDefinition.tableScope, mode);
    if(mode === TableDisplayMode.Card){
      this.tableDefinition.mapThumbnailInfo(this.rows).subscribe();
    }
    this.defaultDisplayMode = mode;
  }
}
