import { ComponentType } from '@angular/cdk/overlay';
import { Component, effect, Input, OnInit, ViewChild } from '@angular/core';
import { ISidebarMenuComponent } from '../struct-app-sidebar-menu/ISidebarMenuComponent';
import { DynamicDirective } from '../../directives/dynamicHost.directive';
import { StructAppSidebarService } from '../struct-app-sidebar.service';

@Component({
    selector: 'struct-app-sidebar-page-menu',
    templateUrl: './struct-app-sidebar-page-menu.component.html',
    styleUrls: ['./struct-app-sidebar-page-menu.component.scss'],
    standalone: false
})
export class StructAppSidebarPageMenuComponent implements OnInit {
  @Input() component: ComponentType<ISidebarMenuComponent> | null = null;
  @ViewChild(DynamicDirective, { static: true }) structDynamicHost!: DynamicDirective;
  public menuComponent: ISidebarMenuComponent | null = null;
  collapsed = false;

  constructor(private sidebarService: StructAppSidebarService) {
    this.collapsed = this.sidebarService.sidebarIsCollapsed();
    effect(() => {
      this.collapsed = this.sidebarService.sidebarIsCollapsed();
      if(this.menuComponent !== null){
        this.menuComponent.collapsed = this.collapsed;
      }
    });
  }

  ngOnInit(): void {
    if (this.component == null) {
      throw new Error('A component to show must be provided to open the menu');
    }

    const viewContainerRef = this.structDynamicHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<ISidebarMenuComponent>(this.component);
    this.menuComponent = componentRef.instance;
    if (this.menuComponent == null) {
      return;
    }
    this.menuComponent.collapsed = this.collapsed;
  }

  public toggleMenuCollapse($event: MouseEvent, collapsed: boolean): void {
    //Prevent the click to be registered by the document clickout listener
    $event.stopPropagation();
    if (this.menuComponent == null) {
      return;
    }
    this.sidebarService.setSidebarCollapsed(collapsed);
  }

  public expandTemporarilyIfCollapsed() : void {
    this.sidebarService.setSidebarCollapsed(false);
  }
}
