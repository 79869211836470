import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'accounts-form-section-description',
    templateUrl: './accounts-form-section-description.component.html',
    styleUrls: ['./accounts-form-section-description.component.scss'],
    imports: [CommonModule]
})
export class AccountsFormSectionDescriptionComponent {

}
