import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ReplaySubject, takeUntil} from 'rxjs';
import { UserModel } from '@struct/models/accountmanagement/api/models/authentication/user-model';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../authentication/authentication.service';

@Component({
    selector: 'accounts-user-navigation',
    templateUrl: 'user-navigation.component.html',
    standalone: false
})
export class UserNavigationComponent implements OnInit, OnDestroy {
  private destroyed$ = new ReplaySubject();

  user: UserModel | null = null;
  constructor(private router: Router, private sidenav: MatSidenav, private observer: BreakpointObserver, private authService: AuthenticationService) {}
  isSmallScreen?: boolean;

  ngOnInit() {
    this.observer.observe(['(max-width: 767px)']).subscribe(res => {
      this.isSmallScreen = res.matches;
    });

    this.authService.getAuthenticatedUser()
    .pipe(takeUntil(this.destroyed$))
    .subscribe(user => {
      this.user = user;
    });
  }
  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  public closeSideNav(): void {
    if (this.isSmallScreen) {
      this.sidenav.close();
    }
  }

  logout() {
    this.authService.signOut().subscribe(x => 
      {
        this.router.navigate(['/sign-in']);
      }
    );
  }

}
