import { Component, Input } from '@angular/core';
import { isObservable, Observable, of} from 'rxjs';
import { ContentHeaderBreadcrumb, ContentHeaderBreadcrumbItem } from '../struct-app-content-header/Breadcrumb';
import { StructDialogService } from '@struct/ui/struct-dialog';
import { StructMediaEditorDialogComponent } from '@struct/ui/struct-media-editor-dialog';

@Component({
    selector: 'struct-app-dam-simple-header',
    templateUrl: './struct-app-dam-simple-header.component.html',
    styleUrls: ['./struct-app-dam-simple-header.component.css'],
    standalone: false
})
export class StructAppDamSimpleHeaderComponent {
  @Input()
  goBackToRoute: string | null = null;

  @Input({required: true})
  image: string | null = null;

  @Input()
  title: Observable<string> | string | null = null;

  @Input()
  breadcrumb: ContentHeaderBreadcrumb | null = null;

  isPinned = false;

  constructor(
    private dialog: StructDialogService){}

    toggleHeaderPinned() {
      this.isPinned = !this.isPinned;
    }

  breadcrumbClicked(item: ContentHeaderBreadcrumbItem) {
    if (item.action) {
      item.action();
    }
  }

  getLabel(item: ContentHeaderBreadcrumbItem): Observable<string | null>  {
    return isObservable(item.label) ? item.label : of(item.label);

  }

  openMediaEditorDialog() {
    this.dialog.open(StructMediaEditorDialogComponent, {});
  }
}
