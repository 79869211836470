import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { UiModule } from "@struct/ui/ui.module";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from "../../authentication/authentication.service";
import { ActivatedRoute } from "@angular/router";
import { StructNotificationService } from "@struct/ui/struct-notification";
import { NgForm } from "@angular/forms";
import { AccountsHeaderComponent } from "../../accounts-ui/accounts-header/accounts-header.component";
import { MyTenantCloneTenantCommand } from "@struct/models/accountmanagement/domain/mytenants/commands/my-tenant-clone-tenant-command";
import { MyTenantApiService } from "@struct/services/account-management";
import { of } from "rxjs";

@Component({
    selector: 'accounts-mytenants-clone-dialog',
    templateUrl: './mytenants-clone-dialog.component.html',
    imports: [
        CommonModule,
        UiModule,
        AccountsHeaderComponent,
    ]
})


  export class MyTenantsCloneTenantDialogComponent implements OnInit {
    command = new MyTenantCloneTenantCommand();
    @ViewChild('cloneform') inviteForm: NgForm | null = null;

    constructor(
      @Inject(MAT_DIALOG_DATA) public data: string,
      private authService: AuthenticationService,
       private currentRoute: ActivatedRoute,
       private dialogRef: MatDialogRef<MyTenantsCloneTenantDialogComponent>,
       private notificationService: StructNotificationService,
       private mytenantService: MyTenantApiService
       ) {}

    ngOnInit(): void {
      this.command.tenantUid =this.data;
    }

    cloneTenant(): void {
      this.mytenantService.cloneTenant(this.command).subscribe((response) => {
        if(!response.succeeded) {
          this.notificationService.showErrorNotification(of(response.errors[0].message));
        } else {
          this.dialogRef.close();
        }
      })
    }

    cancel(): void {
      this.dialogRef.close();
    }

  }
