<struct-app-content-container>
  <accounts-header [goBackToRoute]="'/users'" [headerButtonText]="'Delete user'" [headerButtonIsDelete]="true" (headerButtonClicked)="deleteUser()"> View User details </accounts-header>
  <struct-app-content>
    <struct-app-content-workarea>
      <form #userform="ngForm">
        <accounts-form>
          <accounts-form-section>
            <accounts-form-section-title> User Details </accounts-form-section-title>
            <accounts-form-section-description> See user details </accounts-form-section-description>
            <accounts-form-section-content>
              <struct-form-layout>
                <struct-form-row [borderBelow]="false">
                  <struct-form-row-headline>
                    First name
                  </struct-form-row-headline>
                  <struct-form-row-description>
                    First name description
                  </struct-form-row-description>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="12">
                      <struct-input [name]="'First name'" [(value)]="user.firstName"></struct-input>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>

                <struct-form-row [borderBelow]="false">
                  <struct-form-row-headline>
                    Last name
                  </struct-form-row-headline>
                  <struct-form-row-description>
                    Last name description
                  </struct-form-row-description>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="12">
                      <struct-input [name]="'last name'"  [(value)]="user.lastName"></struct-input>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>

                <struct-form-row [borderBelow]="false">
                  <struct-form-row-headline>
                    Email
                  </struct-form-row-headline>
                  <struct-form-row-description>
                    Email description
                  </struct-form-row-description>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="12">
                      <struct-input [name]="'Email'" [disabled]="true" [(value)]="user.email"></struct-input>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>

                <struct-form-row [borderBelow]="true">
                  <struct-form-row-headline>
                    Phone number
                  </struct-form-row-headline>
                  <struct-form-row-description>
                    Phone number description
                  </struct-form-row-description>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="12">
                      <struct-input [name]="'Phone number'"  [(value)]="user.phoneNumber"></struct-input>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>

                <struct-form-row [borderBelow]="false">
                  <struct-form-row-headline>
                    Organization
                  </struct-form-row-headline>
                  <struct-form-row-description>
                    Organization description
                  </struct-form-row-description>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="12">
                      <struct-input [name]="'organization'" [(value)]="user.organization"></struct-input>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>

                <struct-form-row [borderBelow]="false">
                  <struct-form-row-headline>
                    Role
                  </struct-form-row-headline>
                  <struct-form-row-description>
                    Role description
                  </struct-form-row-description>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="12">
                      <struct-input [name]="'Role'" [disabled]="true" [(value)]="user.role"></struct-input>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>

                <struct-form-row [borderBelow]="false">
                  <struct-form-row-headline> Culture code </struct-form-row-headline>
                  <struct-form-row-description> Regional format (your number, time, date and currency format) </struct-form-row-description>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="6">
                      <struct-simple-select
                        [options]="cultures"
                        bindLabel="name"
                        bindValue="cultureCode"
                        [(ngModel)]="user.cultureCode"
                        [placeholder]="'Regional format (your number, time, date and currency format)'"
                        name="cultureCode"></struct-simple-select>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>
                <struct-form-row [borderBelow]="false">
                  <struct-form-row-headline> Timezone </struct-form-row-headline>
                  <struct-form-row-description> Timezone description </struct-form-row-description>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="6">
                      <struct-simple-select
                        [options]="timeZones"
                        bindLabel="displayName"
                        bindValue="id"
                        [(ngModel)]="user.timeZoneId"
                        [placeholder]="'Timezone'"
                        name="timeZoneId"></struct-simple-select>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>
              </struct-form-layout>
              <!-- <struct-input [name]="'First name'" [disabled]="true" [(value)]="user.firstName"></struct-input> -->
              <!-- <struct-input [name]="'last name'" [disabled]="true" [(value)]="user.lastName"></struct-input> -->
              <!-- <struct-input [name]="'Email'" [disabled]="true" [(value)]="user.email"></struct-input> -->
              <!-- <struct-input [name]="'Phone number'" [disabled]="true" [(value)]="user.phoneNumber"></struct-input> -->
              <!-- <struct-input [name]="'organization'" [disabled]="true" [(value)]="user.organization"></struct-input>
              <struct-input [name]="'Role'" [disabled]="true" [(value)]="user.role"></struct-input> -->

              <struct-button [color]="'primary'" (click)="updateUser()">Update</struct-button>

            </accounts-form-section-content>
          </accounts-form-section>

          <accounts-form-section>
            <accounts-form-section-title> Authentication info </accounts-form-section-title>
            <accounts-form-section-description> Information about users' authentication status </accounts-form-section-description>
            <accounts-form-section-content>
              <struct-form-layout>
                <struct-form-row [borderBelow]="false">
                  <struct-form-row-headline>
                    Email is confirmed
                  </struct-form-row-headline>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="12">
                      <struct-checkbox [disabled]="true" [(value)]="user.emailConfirmed"></struct-checkbox>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>

                <struct-form-row [borderBelow]="false">
                  <struct-form-row-headline>
                    Phone is confirmed
                  </struct-form-row-headline>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="12">
                      <struct-checkbox [disabled]="true" [(value)]="user.phoneNumberConfirmed"></struct-checkbox>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>

                <struct-form-row [borderBelow]="true">
                  <struct-form-row-headline>
                    Two factor enabled
                  </struct-form-row-headline>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="12">
                      <struct-checkbox [disabled]="true" [(value)]="user.twoFactorEnabled"></struct-checkbox>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>

                <struct-form-row [borderBelow]="false">
                  <struct-form-row-headline>
                    Locked out
                  </struct-form-row-headline>
                  <struct-form-row-grid>
                    <struct-form-row-grid-tile data-cols="12">
                      <struct-checkbox [disabled]="true" [(value)]="user.lockedOut"></struct-checkbox>
                      <struct-input *ngIf="user.lockedOut" [name]="'Locked out until'" [disabled]="true" [value]="user.lockoutEnd?.toString() ?? ''"></struct-input>
                    </struct-form-row-grid-tile>
                  </struct-form-row-grid>
                </struct-form-row>
              </struct-form-layout>
              <!-- <div><struct-checkbox [disabled]="true" [(value)]="user.emailConfirmed"></struct-checkbox> Email is confirmed</div> -->
              <!-- <div><struct-checkbox [disabled]="true" [(value)]="user.phoneNumberConfirmed"></struct-checkbox> Phone is confirmed</div> -->
              <!-- <div><struct-checkbox [disabled]="true" [(value)]="user.twoFactorEnabled"></struct-checkbox> Two factor enabled</div> -->

              <!-- <div>
                <struct-checkbox [disabled]="true" [(value)]="user.lockedOut"></struct-checkbox> Locked out
                <struct-input *ngIf="user.lockedOut" [name]="'Locked out until'" [disabled]="true" [value]="user.lockoutEnd?.toString() ?? ''"></struct-input>
              </div> -->

              <div *ngIf="!user.lockedOut">
                <struct-button [color]="'primary'" (click)="lockUser()">Lock user</struct-button>
              </div>
              <div *ngIf="user.lockedOut">
                <struct-button [color]="'primary'" (click)="unlockUser()">unlock user</struct-button>
              </div>
            </accounts-form-section-content>
          </accounts-form-section>
        </accounts-form>

        <div class="px-0 py-0 text-right mt-10">

        </div>

        <!-- <accounts-form-danger-zone [buttonText]="'Delete user'" [title]="'Delete user'" [description]="'Deleting a user cannot be undone'" (deleteClicked)="deleteUser()"></accounts-form-danger-zone> -->
      </form>
    </struct-app-content-workarea>
  </struct-app-content>
</struct-app-content-container>
