import { Component, Inject } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

export interface ConfirmDialogData {
  userValueConfirm?: boolean;
  userValueConfirmString?: string;
  description: Observable<string>;
}

export interface ConfirmDialogResult{
  confirmed: boolean;
}

@Component({
    selector: 'struct-confirm-dialog',
    templateUrl: './struct-confirm-dialog.component.html',
    styleUrls: ['./struct-confirm-dialog.component.scss'],
    standalone: false
})
export class StructConfirmDialogComponent {
  confirmStringInput = '';
  userConfirmString: string | undefined = '';

  constructor(
    public dialogRef: MatDialogRef<StructConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {
    this.userConfirmString = data.userValueConfirmString;
  }

  onCancelClick(): void {
    this.dialogRef.close(<ConfirmDialogResult>{confirmed: false});
  }

  onOkClick(): void{
    this.dialogRef.close(<ConfirmDialogResult>{confirmed: true});
  }

  confirmValueCheck(): boolean{
    if (this.confirmStringInput === this.userConfirmString) {
      return true;
    }
    return false;
  }
}
