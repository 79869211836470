<struct-app-content-container>
  <accounts-header [goBackToRoute]="'/tenants'" [headerButtonText]="'Delete tenant'" [headerButtonIsDelete]="true" (headerButtonClicked)="deleteTenant()">
    Edit tenant
  </accounts-header>
  <struct-app-content>
    <struct-app-content-workarea>
      <form #tenantform="ngForm">

        <accounts-tenant-details *ngIf="loaded" #tenantDetails [tenant]="tenant"></accounts-tenant-details>

        <!-- <accounts-form-danger-zone
          [buttonText]="'Delete tenant'"
          [title]="'Delete tenant'"
          [description]="'Deleting a tenant cannot be undone'"
          (deleteClicked)="deleteTenant()"
          ></accounts-form-danger-zone> -->
      </form>
    </struct-app-content-workarea>
  </struct-app-content>
  <struct-app-content-footer>
    <div *ngIf="showError" class="text-red-400 font-semibold">One or more fields are not correctly filled</div>
    <struct-button color="primary" buttonType="submit" (click)="submitTenant()">Update tenant</struct-button>
  </struct-app-content-footer>
</struct-app-content-container>
