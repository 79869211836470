import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableColumn } from '../models/table-column';

@Component({
    selector: 'struct-columns-dialog',
    templateUrl: './struct-columns-dialog.component.html',
    styleUrls: ['./struct-columns-dialog.component.scss'],
    standalone: false
})
export class StructColumnsDialogComponent implements OnInit {
  allAvailableColumns: TableColumn[] = [];
  shownAvailableColumns: TableColumn[] = [];
  selectedColumns: TableColumn[] = [];
  searchText = '';
  selectedField : TableColumn | null = null;
  currentSelectedIndex: number = 0;  

  @HostListener('document:keydown.ArrowDown', ['$event'])
  hotArrowDown(event: KeyboardEvent): void {
    if(this.currentSelectedIndex > this.shownAvailableColumns.length - 1){
      return;
    }
    this.currentSelectedIndex++;
    this.selectFieldAsPerIndex(this.currentSelectedIndex);
  }

  @HostListener('document:keydown.ArrowUp', ['$event'])
  hotArrowUp(event: KeyboardEvent): void {
    if(this.currentSelectedIndex === 0){
      return;
    }
    this.currentSelectedIndex--;
    this.selectFieldAsPerIndex(this.currentSelectedIndex);
  }

  @HostListener('document:keydown.control.enter', ['$event'])
  hotUpdate(event: KeyboardEvent): void {
    this.updateColumns();
  }

  @HostListener('document:keydown.enter', ['$event'])
  hotKeySelect(event: KeyboardEvent): void {
    if(this.selectedField !== null){
      event.preventDefault();
      this.addColumn(this.selectedField);
      this.selectedField = null;
      this.currentSelectedIndex = 0;
      this.selectFieldAsPerIndex(this.currentSelectedIndex);
    }
  }

  constructor(public dialogRef: MatDialogRef<StructColumnsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: SelectColumnsDialogData) {}
  ngOnInit(): void {
    this.allAvailableColumns = this.data.availableColumns;
    this.shownAvailableColumns = this.data.availableColumns;
    this.selectedColumns = [...this.data.selectedColumns];
    this.onSearchChanged(null);
  }

  selectFieldAsPerIndex(index: number): void {
    if(index < 0 || index >= this.shownAvailableColumns.length) return;
    this.selectedField = this.shownAvailableColumns[index];
  }

  onSearchChanged(searchText: string | null): void {
    if (searchText === null || searchText.length === 0) {
      this.shownAvailableColumns = this.allAvailableColumns.filter(column => !this.selectedColumns.some(selectedColumn => selectedColumn === column));
    } else {
      const terms = searchText.split(' ');
      this.shownAvailableColumns = this.allAvailableColumns.filter(column => {
        return (
          !this.selectedColumns.some(selectedColumn => selectedColumn === column) &&
          terms.every(term => column.title.toLowerCase().includes(term.toLowerCase()))
        );
      }).sort((a, b) => {
        //First sort so that the columns that start with the first search term are first
        if(a.title.toLowerCase().startsWith(terms[0].toLowerCase()) && !b.title.toLowerCase().startsWith(terms[0].toLowerCase())) return -1;
        if(!a.title.toLowerCase().startsWith(terms[0].toLowerCase()) && b.title.toLowerCase().startsWith(terms[0].toLowerCase())) return 1
        //Then sort by length of title
        if(a.title.length < b.title.length) return -1;
        if(a.title.length > b.title.length) return 1;
        //Then sort alphabetically
        return a.title.localeCompare(b.title);
      });
      this.currentSelectedIndex = 0;
      this.selectFieldAsPerIndex(this.currentSelectedIndex);
    }
  }

  addColumn(column: TableColumn): void {
    this.shownAvailableColumns = this.shownAvailableColumns.filter(c => c !== column);
    this.selectedColumns.push(column);
  }

  removeColumn(column: TableColumn): void {
    this.shownAvailableColumns.push(column);
    this.selectedColumns = this.selectedColumns.filter(c => c !== column);
  }

  updateColumns(): void {
    const removedColumns = this.data.selectedColumns.filter(currentColumn => !this.selectedColumns.some(newColumn => newColumn === currentColumn));
    const addedColumns = this.selectedColumns.filter(newColumn => !this.data.selectedColumns.some(currentColumn => newColumn === currentColumn));
    this.dialogRef.close(new SelectColumnsDialogDataResult(this.selectedColumns, addedColumns, removedColumns));
  }

  cancel(): void {
    this.dialogRef.close();
  }

  drop(event: CdkDragDrop<TableColumn[]>, target: 'selected' | 'available'): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if(target === 'selected'){
        const itemToMove = this.shownAvailableColumns[event.previousIndex];
        this.shownAvailableColumns = this.shownAvailableColumns.filter(c => c !== itemToMove)
        this.shownAvailableColumns = [...this.shownAvailableColumns];
        this.selectedColumns.splice(event.currentIndex, 0, itemToMove);
      }
      else{
        const itemToMove = this.selectedColumns[event.previousIndex];        
        this.selectedColumns = this.selectedColumns.filter(c => c !== itemToMove);
        this.shownAvailableColumns.splice(event.currentIndex, 0, itemToMove)
        this.shownAvailableColumns = [...this.shownAvailableColumns];
      }
    }
  }
}

export class SelectColumnsDialogData {
  availableColumns: TableColumn[];
  selectedColumns: TableColumn[];

  constructor(availableColumns: TableColumn[], selectedColumns: TableColumn[]) {
    this.availableColumns = availableColumns;
    this.selectedColumns = selectedColumns;
  }
}

export class SelectColumnsDialogDataResult {
  selectedColumns: TableColumn[];
  addedColumns: TableColumn[];
  removedColumns: TableColumn[];

  constructor(selectedColumns: TableColumn[], addedColumns: TableColumn[], removedColumns: TableColumn[]) {
    this.selectedColumns = selectedColumns;
    this.addedColumns = addedColumns;
    this.removedColumns = removedColumns;
  }
}
