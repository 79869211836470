import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'accounts-form-section-content',
    templateUrl: './accounts-form-section-content.component.html',
    styleUrls: ['./accounts-form-section-content.component.scss'],
    imports: [CommonModule]
})
export class AccountsFormSectionContentComponent {

}
