import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {MatPaginator, PageEvent} from "@angular/material/paginator";

@Component({
    selector: 'struct-paginator',
    templateUrl: './struct-paginator.component.html',
    styleUrls: ['./struct-paginator.component.scss'],
    standalone: false
})
export class StructPaginatorComponent implements AfterViewInit {

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  private _pageIndex  = 0;
  @Input() hidePageSize  = true;
  @Input() totalHits  = 0;
  @Input()
  get pageIndex() { return this._pageIndex; }
  set pageIndex(value : number) {
    this._pageIndex = value;
    if(this.paginator){
      this.paginator.pageIndex = value;
    }
  }
  @Input() pageSize  = 10;
  @Input() pageSizeOptions : number[] = [10];
  @Input() showFirstLastButtons  = false;
  @Input() classList  = '';

  @Output() pageChange = new EventEmitter<PageChange>();

  onPageChange(change : PageEvent) : void {
    this.pageChange.emit(new PageChange(change.pageIndex, change.pageSize));
  }

  ngAfterViewInit(): void {
    this.paginator.pageIndex = this.pageIndex;
  }
}

export class PageChange{
  pageIndex : number;
  pageSize : number;

  constructor(pageIndex : number, pageSize : number){
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
  }
}
