import { Component, Input, OnInit } from '@angular/core';
import { DeferredAssetResolverService } from './deferred-asset-resolver.service';

@Component({
    selector: 'struct-media-column',
    templateUrl: './struct-media-column.component.html',
    styleUrls: ['./struct-media-column.component.scss'],
    standalone: false
})
export class StructMediaColumnComponent implements OnInit {
  @Input() value? : string;

  urls: string[] = [];
  hoverUrl: string | null = null;

  constructor(private assetResolver: DeferredAssetResolverService){}

  ngOnInit(): void {
    const ids = this.value ? this.value.split(",").map(x => x.trim()) : [];
    this.assetResolver.getAssetUrls(ids).subscribe(x => {
      ids.forEach(id => {
        const asset = x.find(a => a.assetId === id);
        if(asset){
          this.urls.push(asset.assetUrl);
        }
      });
    });
  }
}
