import { Component, Input } from '@angular/core';

@Component({
    selector: 'struct-text-column',
    templateUrl: './struct-text-column.component.html',
    styleUrls: ['./struct-text-column.component.scss'],
    standalone: false
})
export class StructTextColumnComponent {
  
  @Input() value? : string;

}
