import {Component, OnInit} from '@angular/core';
import {IRowAction} from '@struct/ui/struct-table/models/row-action';
import {RowActionResult} from '@struct/ui/struct-table/models/row-action-result';
import {IRowSingleAction} from '@struct/ui/struct-table/models/row-single-action';
import {TableColumn} from '@struct/ui/struct-table/models/table-column';
import {ITableDefinition} from '@struct/ui/struct-table/models/table-definition';
import {map, Observable, of, switchMap} from 'rxjs';
import {Row} from '@struct/ui/struct-table/models/row';
import {CommonModule} from '@angular/common';
import {UiModule} from '@struct/ui/ui.module';
import {AccountsHeaderComponent} from '../../accounts-ui/accounts-header/accounts-header.component';
import {DisplayType, Query} from "@struct/models/struct/shared/search";
import {TenantApiService} from "@struct/services/account-management";
import { TranslateService } from '@ngx-translate/core';
import { ClientSideSearchDataProvider, ClientSideSearchTableDefinition } from '@struct/ui/struct-table';
import { User } from '@struct/models/struct/app/domain/usermanagement/user';
import { Router } from '@angular/router';
import { DatabaseTemplate } from '@struct/models/accountmanagement/domain/databasetemplates';
import { ConfirmDialogData, StructConfirmDialogService } from '@struct/ui/struct-confirm-dialog';

@Component({
    selector: 'accounts-database-template-list',
    templateUrl: './database-template-list.component.html',
    styleUrls: ['./database-template-list.component.scss'],
    imports: [CommonModule, UiModule, AccountsHeaderComponent]
})
export class DatabaseTemplateListComponent implements OnInit {
  databaseTemplates: DatabaseTemplate[] | null = null;
  user: User | null = null;
  rows: Row[] = [];
  tableDefinition: ITableDefinition | null = null;
  tableScope = 'databasetemplates';
  filter: Query | null = null;
  rowRouteTemplate: string | null = null;

  constructor(private tenantApi: TenantApiService, private tr : TranslateService, private router : Router, private confirmDialogService: StructConfirmDialogService,) {}

  ngOnInit(): void {
    const rowActions: IRowAction[] = [new DeleteDatabaseTemplateAction(this.confirmDialogService, this.tr, this.tenantApi)];
    this.tr
      .get([
        'DatabaseTemplate.Name',
        'DatabaseTemplate.Created',
        'DatabaseTemplate.CreatedBy',
        'DatabaseTemplate.Description',
        "DatabaseTemplate.Status",
      ])
      .subscribe(t => {
        const tableColumns = [
          new TableColumn({id: 'uid', title: 'Uid', type: DisplayType.Text}),
          new TableColumn({id: 'created', title: t['DatabaseTemplate.Created'], type: DisplayType.Date}),
          new TableColumn({id: 'createdBy', title: t['DatabaseTemplate.CreatedBy'], type: DisplayType.Text}),
          new TableColumn({id: 'name', title: t['DatabaseTemplate.Name'], type: DisplayType.Text}),
          new TableColumn({id: 'status', title: t['DatabaseTemplate.Status'], type: DisplayType.FixedList}),
          new TableColumn({id: 'description', title: t['DatabaseTemplate.Description'], type: DisplayType.Text}),
        ];
        const dataProvider = new AttributeDataProvider(this.tenantApi);
        this.tableDefinition = new ClientSideSearchTableDefinition('databasetemplates', tableColumns, rowActions, 'uid', 'database', '/databasetemplates/:id', dataProvider);
      });

    }

    // createTenant(): void {
    //   this.router.navigateByUrl('/tenants/create');
    // }
}

export class AttributeDataProvider implements ClientSideSearchDataProvider {
  constructor(private tenantApi: TenantApiService) {
  }

  getData(): Observable<any[]> {
    return this.tenantApi.getDatabaseTemplates();
  }
}


export class DeleteDatabaseTemplateAction implements IRowSingleAction {
  name = 'Delete database template';
  icon = 'delete';
  constructor(private confirmDialogService: StructConfirmDialogService, private tr: TranslateService, private tenantApi: TenantApiService) {

  }
  onClick(rowId: string): Observable<RowActionResult> {

    return this.confirmDialogService.open(<ConfirmDialogData>{ description: this.tr.get('DatabaseTemplate.Delete') }).pipe(
      switchMap(t => {
        if (t.confirmed) {
          return this.tenantApi.deleteDatabaseTemplate(rowId).pipe(
            map(() => {
              return new RowActionResult(true);
            })
          );
        } else {
          return of(new RowActionResult(false));
        }
      })
    );
  }
}
