import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { UiModule } from '@struct/ui/ui.module';
import { ReplaySubject, takeUntil } from 'rxjs';
import { AccountsFormSectionContentComponent } from '../../accounts-ui/accounts-form/accounts-form-section-content/accounts-form-section-content.component';
import { AccountsFormSectionDescriptionComponent } from '../../accounts-ui/accounts-form/accounts-form-section-description/accounts-form-section-description.component';
import { AccountsFormSectionTitleComponent } from '../../accounts-ui/accounts-form/accounts-form-section-title/accounts-form-section-title.component';
import { AccountsFormSectionComponent } from '../../accounts-ui/accounts-form/accounts-form-section/accounts-form-section.component';
import { AccountsFormComponent } from '../../accounts-ui/accounts-form/accounts-form/accounts-form.component';
import { SubscriptionPlan } from '@struct/models/struct/shared/subscription/subscription-plan';
import { SubscriptionPlanSetup } from '@struct/models/struct/shared/subscription/subscription-plan-setup';

@Component({
    selector: 'accounts-subscription-plan-details',
    templateUrl: './subscription-plan-details.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    imports: [
        CommonModule,
        UiModule,
        AccountsFormComponent,
        AccountsFormSectionComponent,
        AccountsFormSectionTitleComponent,
        AccountsFormSectionDescriptionComponent,
        AccountsFormSectionContentComponent,
    ]
})
export class SubscriptionPlanDetailsComponent implements OnInit, OnDestroy {
  @Input() subscriptionPlan: SubscriptionPlan;


  private destroyed$ = new ReplaySubject();

  constructor() {
    this.subscriptionPlan = new SubscriptionPlan();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  ngOnInit() {
    return;
  }

  public prepareSubscriptionplanForSubmission(): void {
    return;      
  }

  private buildViewModel() {
    if (this.subscriptionPlan.setup === null) {
      this.subscriptionPlan.setup = new SubscriptionPlanSetup();
    }
  }
}
