import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UiModule } from '@struct/ui/ui.module';

export interface ProductStructureSection {
  name: string;
  variants: boolean;
  selected: boolean;
}

@Component({
    selector: 'accounts-actions-dialog',
    templateUrl: './actions-dialog.component.html',
    styleUrls: ['./actions-dialog.component.scss'],
    imports: [CommonModule, UiModule]
})
export class ActionsDialogComponent {
  currentView = 'intro';

  productStructures: ProductStructureSection[] = [
    {
      name: 'Non-food',
      variants: true,
      selected: true,
    },
    {
      name: 'Wine',
      variants: false,
      selected: false,
    },
    {
      name: 'Events',
      variants: true,
      selected: false,
    },
    {
      name: 'Food',
      variants: false,
      selected: false,
    },
  ];

  productCopyHasVariantsControl = new FormControl(false);

  productCopyOptions = this._formBuilder.group({
    productCopyVariants: [this.productCopyHasVariantsControl, Validators.required],
  });

  constructor(private _formBuilder: FormBuilder, public dialogRef: MatDialogRef<ActionsDialogComponent>) {}

  public changeView(view: string): void {
    this.currentView = view;
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
