import { Component, Injectable } from '@angular/core';
import { CollectionViewer, SelectionChange, DataSource } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActionsDialogComponent } from './actions-dialog/actions-dialog.component';
import { QuickActionsDialogComponent } from './quick-actions-dialog/quick-actions-dialog.component';
import {MatDialog} from "@angular/material/dialog";
import { CommonModule } from '@angular/common';
import { UiModule } from '@struct/ui/ui.module';

/** Flat node with expandable and level information */
export class DynamicFlatNode {
  constructor(public item: string, public level = 1, public expandable = false, public isLoading = false) {}
}

/**
 * Database for dynamic data. When expanding a node in the tree, the data source will need to fetch
 * the descendants data from the database.
 */
@Injectable({ providedIn: 'root' })
export class DynamicDatabase {
  dataMap = new Map<string, string[]>([
    ['Building materials', ['Wood', 'Tiles']],
    ['Clothing', ['T-shirts', 'Shoes', 'Pants']],
    ['Tools', ['Power tools', 'Hand tools', 'Accessories', 'Kitchen appliances']],
    ['Electronics', ['Laptops', 'Monitors', 'TVs', 'Mobile', 'Printers']],
    ['Events', ['Wine tasting', 'Courses']],
    ['Food & drink', ['Wine', 'Bread']],

    ['Power tools', ['Power drills', 'Grinders']],
    ['Hand tools', ['Hammers', 'Hand saws', 'Screwdrivers']],
  ]);

  rootLevelNodes: string[] = ['Building materials', 'Clothing', 'Tools', 'Electronics', 'Events', 'Food & drink'];

  /** Initial data from database */
  initialData(): DynamicFlatNode[] {
    return this.rootLevelNodes.map(name => new DynamicFlatNode(name, 0, true));
  }

  getChildren(node: string): string[] | null {
    return this.dataMap.get(node) ?? null;
  }

  isExpandable(node: string): boolean {
    return this.dataMap.has(node);
  }
}
/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `FileNode` with nested
 * structure.
 */
export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }
  set data(value: DynamicFlatNode[]) {
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(private _treeControl: FlatTreeControl<DynamicFlatNode>, private _database: DynamicDatabase) {}

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this._treeControl.expansionModel.changed.subscribe(change => {
      if ((change as SelectionChange<DynamicFlatNode>).added || (change as SelectionChange<DynamicFlatNode>).removed) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  disconnect(collectionViewer: CollectionViewer): void {}

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: DynamicFlatNode, expand: boolean) {
    const children = this._database.getChildren(node.item);
    const index = this.data.indexOf(node);
    if (!children || index < 0) {
      // If no children, or cannot find the node, no op
      return;
    }

    node.isLoading = true;

    setTimeout(() => {
      if (expand) {
        const nodes = children.map(name => new DynamicFlatNode(name, node.level + 1, this._database.isExpandable(name)));
        this.data.splice(index + 1, 0, ...nodes);
      } else {
        let count = 0;
        for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) // eslint-disable-next-line no-empty
        {}
        this.data.splice(index + 1, count);
      }

      // notify the change
      this.dataChange.next(this.data);
      node.isLoading = false;
    }, 1000);
  }
}

@Component({
    selector: 'accounts-product-page',
    templateUrl: './product-page.component.html',
    styleUrls: ['./product-page.component.scss'],
    imports: [CommonModule, UiModule]
})
export class ProductPageComponent {
  hasChanges = false;
  advancedFieldMetaActive = false;
  translationActive = false;
  metaSidebarVisible = true;
  isMetaDrawerActive = false;

  translationAccOpen = 't1';

  countryStatus = 'All';
  countries: { id: number; name: string }[] = [
    { id: 0, name: 'England' },
    { id: 10, name: 'Denmark' },
    { id: 20, name: 'Germany' },
    { id: 30, name: 'Sweden' },
    { id: 40, name: 'Norway' },
    { id: 50, name: 'Finland' },
    { id: 60, name: 'Ukrane' },
  ];

  constructor(database: DynamicDatabase, public actionsDialog: MatDialog, public quickActionsDialog: MatDialog) {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(this.treeControl, database);

    this.dataSource.data = database.initialData();
  }

  treeControl: FlatTreeControl<DynamicFlatNode>;

  dataSource: DynamicDataSource;

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;

  public toggleTranslation(): void {
    this.translationActive = !this.translationActive;
    if (this.translationActive) {
      this.hideMetaSidebar();
    } else {
      this.showMetaSidebar();
    }
  }

  public toggleAdvancedFieldMeta(): void {
    this.advancedFieldMetaActive = !this.advancedFieldMetaActive;
  }

  public hideMetaSidebar(): void {
    this.metaSidebarVisible = false;
  }

  public showMetaSidebar(): void {
    this.metaSidebarVisible = true;
  }

  openActionsDialog(): void {
    const dialogRef = this.actionsDialog.open(ActionsDialogComponent, {
      enterAnimationDuration: '0',
      exitAnimationDuration: '0',
      height: '50vh',
      minHeight: '600px',
      width: '50vw',
      minWidth: '900px',
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openQuickActionsDialog(): void {
    const dialogRef = this.quickActionsDialog.open(QuickActionsDialogComponent);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
