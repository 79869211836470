import { Component, Input } from '@angular/core';
import { StructBadgeItemModel } from './struct-badge-item-model';

@Component({
    selector: 'struct-badge-item-column',
    templateUrl: './struct-badge-item-column.component.html',
    standalone: false
})
export class StructBadgeItemColumnComponent {
  @Input() value : StructBadgeItemModel[] = [];
}
