import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'struct-icon-button',
    templateUrl: './struct-icon-button.component.html',
    styleUrls: ['./struct-icon-button.component.scss'],
    standalone: false
})
export class StructIconButtonComponent {
  @Input() icon: string | null = null;
  @Input() disabled: boolean;
  @Input() selected: boolean;
  @Input() selectedLabel: string;
  @Input() buttonType: string;
  @Input() iconSize: string;
  @Input() size: string;
  @Input() useMaterialIcons = false;
  @Input() hasBadge = false;
  @Input() badgeSize: string | null = null;
  @Input() badgeBackgroundColor: string | null = null;
  @Input() badgeIcon: string | null = null;
  @Input() badgeIconColor: string | null = null;

  @Output() clicked = new EventEmitter();

  constructor() {
    this.buttonType = 'button';
    this.iconSize = '16';
    this.size = 'base';
    this.disabled = false;
    this.selected = false;
    this.selectedLabel = '';
  }
}
