
import { Component, inject, OnInit } from "@angular/core";

import { Tenant } from "@struct/models/accountmanagement/domain/tenants";
import { Observable, of, Subscription } from "rxjs";
import { DeploymentStampModel } from "@struct/models/accountmanagement/api/models/tenant/deployment-stamp-model";
import { DatabaseTemplateModel } from "@struct/models/accountmanagement/api/models/tenant/database-template-model";
import { MyTenantApiService, OrganizationsApiService, SubscriptionApiService, TenantApiService } from "@struct/services/account-management";
import { MatChipInputEvent } from "@angular/material/chips";
import { AccountsHeaderComponent } from "../../accounts-ui/accounts-header/accounts-header.component";
import { AccountsFormComponent } from "../../accounts-ui/accounts-form/accounts-form/accounts-form.component";
import { AccountsFormSectionComponent } from "../../accounts-ui/accounts-form/accounts-form-section/accounts-form-section.component";
import { AccountsFormSectionTitleComponent } from "../../accounts-ui/accounts-form/accounts-form-section-title/accounts-form-section-title.component";
import { AccountsFormSectionDescriptionComponent } from "../../accounts-ui/accounts-form/accounts-form-section-description/accounts-form-section-description.component";
import { AccountsFormSectionContentComponent } from "../../accounts-ui/accounts-form/accounts-form-section-content/accounts-form-section-content.component";
import { UiModule } from "../../../../../../libs/ui/src/lib/ui.module";
import { ActivatedRoute } from "@angular/router";
import { MyTenantCreateTenantCommand } from "@struct/models/accountmanagement/domain/mytenants/commands/my-tenant-create-tenant-command";
import { StructNotificationService } from "@struct/ui/struct-notification";
import { LiveAnnouncer } from "@angular/cdk/a11y";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'accounts-mytenant-create-tenant',
  templateUrl: './mytenant-create-tenant.component.html',
  styleUrls: [],
  standalone: true,
  imports: [CommonModule, AccountsHeaderComponent, AccountsFormComponent, AccountsFormSectionComponent, AccountsFormSectionTitleComponent, AccountsFormSectionDescriptionComponent, AccountsFormSectionContentComponent, UiModule],
})
export class MyTenantCreateTenantComponent implements OnInit {
    working = false;
  announcer = inject(LiveAnnouncer);
  tenantCommand = new MyTenantCreateTenantCommand();
  subscriptions$: Observable<Subscription[]> | null = null;
  deploymentStamps$: Observable<DeploymentStampModel[]> | null = null;
  dbTemplates$: Observable<DatabaseTemplateModel[]> | [] = [];
  dbTemplates: DatabaseTemplateModel[] = [];
//  showerror: false;
  authenticationProviders: { key: string; value: string }[] = [];
  tenantStatuses: { key: string; value: string }[] = [];

  constructor(private myTenantsService : MyTenantApiService, private currentRoute: ActivatedRoute,private subscriptionApi: SubscriptionApiService, private tenantApi : TenantApiService, private organizationApi : OrganizationsApiService,
    private notificationService: StructNotificationService) {}

  ngOnInit(): void {
    //this.subscriptions$ = this.subscriptionApi.getSubscriptions();
    const uid = this.currentRoute.snapshot.paramMap.get('uid');
    let iUid = ''
    if(uid)
        iUid = uid;

    this.organizationApi.getOrganizationDatabaseTemplates().subscribe(data => {
        this.dbTemplates = data;
    })
    
  }

  createTenant():void {
    this.working = true;

    
    this.myTenantsService.createTenant(this.tenantCommand).subscribe((response) => {
       
          if(!response.succeeded) {
            
            this.notificationService.showErrorNotification(of(response.errors[0].message));
          } else {
            window.location.href = '/mytenants';
          }
  })

}
addInvite(event: MatChipInputEvent): void {
  const value = (event.value || '').trim();
  if (value) {
    this.tenantCommand.adminsToInvite.push(value);
  }
  // Clear the input value
  console.log(this.tenantCommand.adminsToInvite);
  event.chipInput!.clear();
}

removeInvite(invite: string): void {
  const index = this.tenantCommand.adminsToInvite.indexOf(invite);

  if (index >= 0) {
    this.tenantCommand.adminsToInvite.splice(index, 1);
    this.announcer.announce(`Removed ${invite}`);
  }
}

}
