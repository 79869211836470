import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'struct-app-content',
    templateUrl: './struct-app-content.component.html',
    styleUrls: ['./struct-app-content.component.scss'],
    standalone: false
})
export class StructAppContentComponent {
  @HostBinding('class') class = '';

  @Input()
  noScrolling: boolean = false;
}
