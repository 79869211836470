import { Component, inject, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { UiModule } from '@struct/ui/ui.module';
import { AccountsFormComponent } from '../../accounts-ui/accounts-form/accounts-form/accounts-form.component';
import { AccountsFormSectionComponent } from '../../accounts-ui/accounts-form/accounts-form-section/accounts-form-section.component';
import { AccountsFormSectionTitleComponent } from '../../accounts-ui/accounts-form/accounts-form-section-title/accounts-form-section-title.component';
import { AccountsFormSectionDescriptionComponent } from '../../accounts-ui/accounts-form/accounts-form-section-description/accounts-form-section-description.component';
import { AccountsFormSectionContentComponent } from '../../accounts-ui/accounts-form/accounts-form-section-content/accounts-form-section-content.component';
import { SubscriptionApiService } from "@struct/services/account-management/subscription-api.service";
import { Tenant } from "@struct/models/accountmanagement/domain/tenants";
import { TenantApiService } from '@struct/services/account-management';
import { DeploymentStampModel } from '@struct/models/accountmanagement/api/models/tenant/deployment-stamp-model';
import { DatabaseTemplateModel } from '@struct/models/accountmanagement/api/models/tenant/database-template-model';
import { MatChipInputEvent } from '@angular/material/chips';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Subscription } from '@struct/models/struct/shared/subscription/subscription';
import { nameof } from '@struct/utilities/index';

@Component({
    selector: 'accounts-tenant-details',
    templateUrl: './tenant-details.component.html',
    styleUrls: ['./tenant-details.component.scss'],
    imports: [CommonModule, UiModule, AccountsFormComponent, AccountsFormSectionComponent, AccountsFormSectionTitleComponent, AccountsFormSectionDescriptionComponent, AccountsFormSectionContentComponent, AccountsFormSectionComponent]
})
export class TenantDetailsComponent implements OnInit, OnDestroy {
  @Input() tenant!: Tenant;
  private destroyed$ = new ReplaySubject();
  announcer = inject(LiveAnnouncer);
  subscriptions$: Observable<Subscription[]> | null = null;
  deploymentStamps$: Observable<DeploymentStampModel[]> | null = null;
  dbTemplates$: Observable<DatabaseTemplateModel[]> | null = null;
  invalidApplicationParam$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isEarlyAdopterOrg: boolean = true;
  authenticationProviders: { key: string; value: string }[] = [];
  tenantStatuses: { key: string; value: string }[] = [];

  constructor(private subscriptionApi: SubscriptionApiService, private tenantApi : TenantApiService) {}

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.subscriptions$ = this.subscriptionApi.getSubscriptions();
    this.deploymentStamps$ = this.tenantApi.getDeploymentStamps();
    this.dbTemplates$ = this.tenantApi.getDatabaseTemplates();
    this.isEarlyAdopterOrg = this.tenant.isEarlyAdopter;
  }

  addInvite(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our tag
    if (value) {
      this.tenant.deploymentInfo.adminUsersToInvite.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  removeInvite(invite: string): void {
    const index = this.tenant.deploymentInfo.adminUsersToInvite.indexOf(invite);

    if (index >= 0) {
      this.tenant.deploymentInfo.adminUsersToInvite.splice(index, 1);

      this.announcer.announce(`Removed ${invite}`);
    }
  }
}
