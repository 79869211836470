import { Component } from '@angular/core';
import { StructColumnHeaderBaseComponent } from '../struct-column-header-base';
import { FieldQuery, TextFieldQuery, TextQueryOperator } from '@struct/models/struct/shared/search/datafieldqueries';

@Component({
    selector: 'struct-valuereference-header',
    templateUrl: './struct-valuereference-header.component.html',
    styleUrls: ['./struct-valuereference-header.component.scss'],
    standalone: false
})
export class StructValuereferenceHeaderComponent extends StructColumnHeaderBaseComponent {

  getFieldQuery(str : string | null | undefined) : FieldQuery | null {
    if (str === null || str === undefined || str.length === 0) {
      return null;
    }
    
    const queryOperator = this.getQueryOperator(str);
    return new TextFieldQuery({
      fieldUid: this.column.id,
      queryOperator: queryOperator,
      filterValue: this.getCleanSearchValue(str, queryOperator),
    });
  }

  getQueryOperator(str: string): TextQueryOperator {
    if(str === null || str === undefined || str === "")
    {
      return TextQueryOperator.StartsWith;
    }
    if(str.startsWith("!*")){
      return TextQueryOperator.NotContains;
    }
    if(str.startsWith("!\"\"")){
      return TextQueryOperator.IsNotEmpty;
    }
    if(str.startsWith("\"\"")){
      return TextQueryOperator.IsEmpty;
    }    
    if(str.startsWith("!=")){
      return TextQueryOperator.NotEquals;
    }
    if(str.startsWith("!")){
      return TextQueryOperator.NotStartsWith;
    }
    if(str.startsWith("*")){
      return TextQueryOperator.Contains;
    }
    if(str.startsWith("=")){
      return TextQueryOperator.Equals;
    }
    return TextQueryOperator.StartsWith;
  }

  getUiText(query: FieldQuery){
    const typed = query as TextFieldQuery;
    let prefix = "";
    if(typed.queryOperator === TextQueryOperator.Equals){
      prefix = "=";
    }
    else if(typed.queryOperator === TextQueryOperator.NotEquals){
      prefix = "!=";
    }
    else if(typed.queryOperator === TextQueryOperator.Contains){
      prefix = "*";
    }
    else if(typed.queryOperator === TextQueryOperator.NotContains){
      prefix = "!*";
    }
    else if(typed.queryOperator === TextQueryOperator.IsEmpty){
      prefix = "\"\"";
    }
    else if(typed.queryOperator === TextQueryOperator.IsNotEmpty){
      prefix = "!\"\"";
    }
    else if(typed.queryOperator === TextQueryOperator.NotStartsWith){
      prefix = "!";
    }
    return prefix + typed.filterValue;
  }

  getCleanSearchValue(str: string, operator: TextQueryOperator) : string {
    //Remove first character
    if(operator === TextQueryOperator.Contains || operator === TextQueryOperator.Equals || operator === TextQueryOperator.NotStartsWith){
      return str.substring(1, str.length);
    }
    //Remove first two characters
    if(operator === TextQueryOperator.NotContains || operator === TextQueryOperator.NotEquals || operator === TextQueryOperator.IsEmpty)
    {
      return str.substring(2, str.length);
    }
    //Remove first two characters
    if(operator === TextQueryOperator.IsNotEmpty)
    {
      return str.substring(3, str.length);
    }
    return str;
  }
}
