<div class="relative flex flex-1 flex-col struct-table" id="struct-table-component" data-cy="struct.table">
  <div class="z-0 w-full shrink-0 grow-0 struct-header-row mb-4" role="row">
    <div class="flex items-center struct-header-cell">
      <div class="grow struct-header-left" *ngIf="tableDefinition.filter !== null">
        <div class="flex h-16 flex-1 items-center overflow-hidden bg-yellow-200/25 p-4 duration-500 animate-in slide-in-from-bottom-12">
          <div class="mr-3 flex h-full grow-0 items-center border-r border-black/10 pr-3 duration-700 space-x-2 animate-in slide-in-from-bottom-12">
            <struct-icon icon="warning" size="16"></struct-icon>
            <span class="font-medium text-md">Filter applied</span>
          </div>

          <div class="flex grow items-center duration-1000 space-x-1 animate-in slide-in-from-bottom-12">
            <span class="text-lg font-semibold">{{tableDefinition.filter.label}}</span>
          </div>
          <div class="grow-0 flex items-center">
            <struct-icon-button icon="remove" iconSize="16" matTooltip="Clear filters" [matTooltipPosition]="'above'" (click)="removeFilter()"></struct-icon-button>
          </div>
        </div>
      </div>
      <div class="flex items-center grow justify-end pr-4 text-right struct-header-right">

        <!--paging-->
        <struct-paginator
          *ngIf="defaultDisplayMode === TableDisplayMode.Card"
          [classList]="'mr-4'"
          [totalHits]="totalHits"
          [pageSizeOptions]="pageSizeOptions"
          [pageSize]="defaultPageSize"
          [showFirstLastButtons]="showFirstLastPageButton"
          [hidePageSize]="!showPageSizeSelector"
          [pageIndex]="searchDefinition.pageIndex"
          (pageChange)="pageChanged($event)"></struct-paginator>
        <!--paging-->

        <!--Search -->
        <div class="w-[200px] flex flex-col flex-shrink-0 items-start z-10 mr-4" *ngIf="defaultDisplayMode === TableDisplayMode.Card">
          <div class="relative w-full">
            <div class="flex items-center relative">
              <input
                [ngModel]="singleSearchValue"
                (ngModelChange)="singleSearchUpdate.next($event)"
                type="text"
                name="search"
                id="search-field"
                placeholder="{{ 'General.SearchFor' | translate }}"
                class="block w-full h-10 bg-white outline-none focus:bg-white border-gray-300 pr-12 text-gray-100 focus:text-gray-800 py-2 px-4 ring-2 ring-gray-100 focus:ring-blue-300" />
              <div class="absolute right-2">
                <!-- <mat-spinner class="mr-2" diameter="16" color="primary"></mat-spinner>
                <struct-icon icon="remove" size="16" class="cursor-pointer"></struct-icon> -->
              </div>
            </div>
          </div>
        </div>
        <!--Search -->

        <!-- Table actions -->
        <div class="flex items-center">

          <button mat-flat-button color="transparent" (click)="saveSearch()" *ngIf="(!searchIsEmpty || showResetSearch) && showSaveSearchButtonWhenSearchIsNotEmpty" matTooltip="{{ 'SavedSearch.SaveCurrentSearchDescription' | translate }}" [matTooltipPosition]="'below'" class="mr-2">
            <!-- <mat-icon>saved_search</mat-icon> -->
            <struct-icon icon="search_advanced" size="16"></struct-icon>
            <span>{{ 'SavedSearch.SaveCurrentSearch' | translate }}</span>
          </button>
          <div *ngIf="!searchIsEmpty && showSaveSearchButtonWhenSearchIsNotEmpty" class="w-px h-10 bg-gray-200 block mx-4"></div>

          <ng-content select="[entitiesSelector]"></ng-content>

          <div class="flex gap-1 items-center">
            <struct-icon-button
              icon="table_view"
              (click)="changeDisplayMode(1)"
              [selected]="defaultDisplayMode === TableDisplayMode.Table"
              selectedLabel="Table"
              matTooltip="{{ 'General.Table.ViewAsTable' | translate }} (ctrl+shift+h)"
              [matTooltipPosition]="'below'"
              >
            </struct-icon-button>
            <struct-icon-button
              icon="grid_view"
              (click)="changeDisplayMode(2)"
              [selected]="defaultDisplayMode === TableDisplayMode.Card"
              selectedLabel="Grid"
              matTooltip="{{ 'General.Table.ViewAsGrid' | translate }} (ctrl+shift+g)"
              [matTooltipPosition]="'below'"
              >
            </struct-icon-button>
          </div>

          <div class="w-px h-10 bg-gray-200 block mx-4"></div>

          <ng-content select="struct-table-actions"></ng-content>

          <struct-icon-button icon="view_column" iconSize="16" matTooltip="{{ 'General.Table.ManageColumns' | translate }} (ctrl+shift+c)" (click)="manageColumns()"></struct-icon-button>
          <!-- <struct-icon-button icon="help" iconSize="16" matTooltip="{{ 'General.Table.GetHelp' | translate }}" (click)="getHelp()"></struct-icon-button> -->
        </div>
        <!-- Table actions -->

      </div>
    </div>
  </div>

  <!-- Table view -->
  <section *ngIf="defaultDisplayMode === TableDisplayMode.Table">
    <div class="relative shrink-0 grow-0 bg-white struct-header-row" role="row">
      <mat-progress-bar *ngIf="working" mode="indeterminate" class="absolute top-0 h-1"></mat-progress-bar>
      <div class="flex items-center struct-header-cell">
        <div class="flex-grow">
          <div *ngIf="allowMultiSelect" [ngClass]="{ 'is-active': selectedRows.size > 0 }" class="ml-3 flex items-center struct-header-left-content">
            <div class="mr-3 flex items-center gap-2 overflow-hidden">
              <div class="font-semibold text-md flex items-center gap-1">
                <span class="min-w-4 max-w-8 overflow-hidden flex items-center justify-center">{{ selectedRows.size }}</span>
                <span>selected</span>
              </div>
              <struct-icon-button icon="close" iconSize="16" [useMaterialIcons]="true" (click)="deselectAll()"></struct-icon-button>
            </div>
            <div class="flex items-center">
              <div class="h-6 border-l border-gray-100 pl-3"></div>
              <ng-container *ngTemplateOutlet="actionsOutlet"></ng-container>
            </div>
          </div>
        </div>
        <div class="flex-grow">
          <div class="flex items-center justify-end text-right" *ngIf="loaded">
            <ng-content select="[struct-table-right-side-actions]"></ng-content>
            <struct-icon-button (click)="resetSearch()" *ngIf="!searchIsEmpty || showResetSearch" iconSize="16" icon="reset_search" matTooltip="Reset search" [matTooltipPosition]="'above'"></struct-icon-button>
            <struct-paginator
              [totalHits]="totalHits"
              [pageSizeOptions]="pageSizeOptions"
              [pageSize]="defaultPageSize"
              [showFirstLastButtons]="showFirstLastPageButton"
              [hidePageSize]="!showPageSizeSelector"
              [pageIndex]="searchDefinition.pageIndex"
              (pageChange)="pageChanged($event)"></struct-paginator>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col bg-white struct-table-inner-content" (mouseleave)="clearRowEvents()">
      <div class="grow-0 bg-white struct-header-row mb-1" role="row" cdkDropList cdkDropListOrientation="horizontal" cdkDropListLockAxis="x" (cdkDropListDropped)="drop($event)">
        <div
          class="p-3"
          *ngIf="allowMultiSelect && !disableEntitySelection"
          [ngStyle]="{ width: showIcons ? '80px' : '96px', 'flex-basis': showIcons ? '80px' : '96px', 'flex-grow': '0', 'flex-shrink': '0' }">
          <form class="struct-table-multiselect" *ngIf="totalHits > 0">
            <div class="relative flex items-center p-1 struct-table-multiselect-checkbox" *ngIf="!disableEntitySelection">
              <struct-checkbox matTooltip="Select all" matTooltipPosition="above" [indeterminate]="someSelected" [value]="allSelected" (valueChange)="toggleAll()"></struct-checkbox>
              <div class="w-px h-full bg-gray-100"></div>
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Select other" class="struct-table-multiselect-arrow" [disableRipple]="true">
                <struct-icon icon="arrow_sm_down" size="12"></struct-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="selectAllOnThisPage()">
                  <mat-icon>playlist_add_check</mat-icon>
                  <span>All on this page</span>
                </button>
                <button mat-menu-item (click)="selectNoneOnThisPage()">
                  <mat-icon>playlist_remove</mat-icon>
                  <span>None on this page</span>
                </button>
              </mat-menu>
            </div>
          </form>
        </div>
        <div *ngIf="!allowMultiSelect && showIcons" class="w-[25px]"></div>
        <div *ngIf="showFixedMediaColumn" class="w-12 px-2"></div>
        <div class="struct-header-cell px-3 py-3 relative struct-column-{{ column.id }} cursor-move min-w-32" [ngStyle]="{ width: column.width + 'px' }" *ngFor="let column of displayedColumns; let i = index">
          <form cdkDrag (submit)="searchChanged()">
            <ng-container *ngIf="column.type === DisplayType.Text || column.type === DisplayType.UserUserGroup || column.type === DisplayType.BadgeItem || column.type === DisplayType.Media">
              <struct-text-header
                [column]="column"
                [sortField]="searchDefinition.sortField"
                [sortAsc]="searchDefinition.sortAsc"
                (sortingChanged)="sortBy(column)"
                (searchChanged)="searchChanged()"></struct-text-header>
            </ng-container>
            <ng-container *ngIf="column.type === DisplayType.Culture">
              <struct-culture-header
                [column]="column"
                [sortField]="searchDefinition.sortField"
                [sortAsc]="searchDefinition.sortAsc"
                (sortingChanged)="sortBy(column)"
                (searchChanged)="searchChanged()"></struct-culture-header>
            </ng-container>
            <ng-container *ngIf="column.type === DisplayType.Number || column.type === DisplayType.Percentage || column.type === DisplayType.CommentsCount">
              <struct-number-header
                [column]="column"
                [sortField]="searchDefinition.sortField"
                [sortAsc]="searchDefinition.sortAsc"
                (sortingChanged)="sortBy(column)"
                (searchChanged)="searchChanged()"></struct-number-header>
            </ng-container>
            <ng-container *ngIf="column.type === DisplayType.Boolean">
              <struct-boolean-header
                [column]="column"
                [sortField]="searchDefinition.sortField"
                [sortAsc]="searchDefinition.sortAsc"
                (sortingChanged)="sortBy(column)"
                (searchChanged)="searchChanged()"></struct-boolean-header>
            </ng-container>
            <ng-container *ngIf="column.type === DisplayType.FixedList">
              <struct-valuereference-header
                [column]="column"
                [sortField]="searchDefinition.sortField"
                [sortAsc]="searchDefinition.sortAsc"
                (sortingChanged)="sortBy(column)"
                (searchChanged)="searchChanged()"></struct-valuereference-header>
            </ng-container>
            <ng-container *ngIf="column.type === DisplayType.Date || column.type === DisplayType.DateTime">
              <struct-date-header
                [column]="column"
                [displayType]="column.type"
                [sortField]="searchDefinition.sortField"
                [sortAsc]="searchDefinition.sortAsc"
                (sortingChanged)="sortBy(column)"
                (searchChanged)="searchChanged()"></struct-date-header>
            </ng-container>
          </form>
          <div *ngIf="i < displayedColumns.length" class="absolute top-0 -right-2 z-10 flex justify-center h-full w-4 py-2 cursor-col-resize group" (mousedown)="onResizeColumn($event, i)" matTooltip="Resize column" [matTooltipPosition]="'above'">
            <span class="block w-px h-full group-hover:bg-gray-200"></span>
          </div>
        </div>
      </div>

      <div *ngIf="!loaded" class="relative flex h-64 border-collapse border-spacing-0 items-center justify-center border border-r-0 border-b-0 border-l-0 border-t-gray-100 bg-white p-10 text-center">
        <struct-spinner></struct-spinner>
      </div>
      <!-- Empty search -->
      <div
        *ngIf="rows.length === 0 && loaded"
        class="relative flex border-collapse border-spacing-0 items-center justify-center border border-r-0 border-b-0 border-l-0 border-t-gray-100 bg-white p-10 text-center"
        role="row">
        <div class="flex w-96 flex-col items-center justify-center">
          <svg class="w-48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
            <g stroke-linecap="square" stroke-linejoin="miter" stroke-width="3" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" stroke-miterlimit="10">
              <path d="M56,58H8a5,5,0,0,1-5-5V6H24l6,10H61V53A5,5,0,0,1,56,58Z"></path>
              <line x1="37.1" y1="42.1" x2="45" y2="50"></line>
              <circle cx="30" cy="35" r="10"></circle>
            </g>
          </svg>
          <h4 class="mt-4 text-xl font-medium">No items to show</h4>
          <div class="mt-6 flex items-center" *ngIf="!searchIsEmpty">
            <span class="mr-2 text-md">Refine your filters or</span>
            <struct-button (click)="resetSearch()" *ngIf="!searchIsEmpty" matTooltip="Reset search" [matTooltipPosition]="'above'">Reset search</struct-button>
          </div>
        </div>
      </div>
      <div *ngIf="loaded">
        <div
          class="struct-row"
          role="row"
          *ngFor="let row of rows; trackBy: trackById"
          [ngClass]="{ 'is-selected': selectedRows.has(row.id) }"
          (mouseenter)="onRowMouseEnter($event)"
          [attr.data-cy]="'struct.table.row.' + row.id"
          [cdkContextMenuTriggerFor]="table_row_context_menu">
          <div
            class="py-1 pl-4 struct-cell"
            *ngIf="allowMultiSelect && !disableEntitySelection"
            [ngStyle]="{ width: showIcons ? '80px' : '96px', 'flex-basis': showIcons ? '80px' : '96px', 'flex-grow': '0', 'flex-shrink': '0' }"
            role="gridcell">
            <form>
              <struct-checkbox (valueChange)="toggleRowSelection(row)" [value]="row.selected" [attr.data-cy]="'struct.table.checkbox_' + row.id"></struct-checkbox>
            </form>
            <div *ngIf="showIcons" class="">
              <struct-icon *ngIf="row.icon !== null" [icon]="row.icon" class="pl-2 text-gray-500" size="16"></struct-icon>
            </div>
          </div>

          <ng-container *ngIf="showFixedMediaColumn">
            <div class="flex items-center justify-center text-gray-300 h-12 w-12 relative rounded group">
              <div class="h-12 w-12 overflow-hidden bg-gray-50" *ngIf="isRenderableImage(row.thumbnailLabel)">
                <img ngSrc="{{row.thumbnailUrl}}" width="400" height="300" priority class="h-full w-full object-contain mix-blend-multiply" />
              </div>

              <div class="hidden opacity-0 group-hover:opacity-100 group-hover:flex absolute -right-72 -translate-y-50% w-64 h-64 bg-gray-50 z-50 shadow-lg p-0" *ngIf="isRenderableImage(row.thumbnailLabel)">
                <img ngSrc="{{row.thumbnailUrl}}" width="600" height="400" priority class="h-full w-full object-cover object-center" />
              </div>

              <div class="h-12 w-12 overflow-hidden bg-gray-50" *ngIf="isRenderableVideo(row.thumbnailLabel)">
                <video width="400" height="300" priority class="h-full w-full object-contain mix-blend-multiply">
                  <source src="{{row.thumbnailUrl}}" type="video/mp4" />
                </video>
              </div>

              <div class="hidden opacity-0 group-hover:opacity-100 group-hover:flex absolute -right-72 -translate-y-50% w-64 h-64 bg-gray-50 z-50 shadow-lg p-0" *ngIf="isRenderableVideo(row.thumbnailLabel)">
                <video autoplay width="400" height="300" priority class="h-full w-full object-contain mix-blend-multiply">
                  <source src="{{row.thumbnailUrl}}" type="video/mp4" />
                </video>
              </div>

              <!-- Render the filetype instead of image -->
              <struct-filetype-icon
                size="36"
                [filetype]="row.thumbnailLabel"
                *ngIf="isOfficeDocument(row.thumbnailLabel) || isPdf(row.thumbnailLabel) || isUnknownFile(row.thumbnailLabel)">
              </struct-filetype-icon>
            </div>
          </ng-container>

          <div
            (click)="onRowClick(row)"
            class="struct-cell px-4 py-2 struct-column-{{ column.id }} flex-grow"
            [ngStyle]="{ minHeight: '43px', width: column.width + 'px' }"
            role="gridcell"
            *ngFor="let column of displayedColumns">
            <ng-container *ngIf="column.type === DisplayType.Text">
              <struct-text-column [value]="getValue(row, column.id)"></struct-text-column>
            </ng-container>
            <ng-container *ngIf="column.type === DisplayType.Number">
              <struct-number-column [value]="getValue(row, column.id)"></struct-number-column>
            </ng-container>
            <ng-container *ngIf="column.type === DisplayType.Percentage">
              <struct-percentage-column [value]="getValue(row, column.id)"></struct-percentage-column>
            </ng-container>
            <ng-container *ngIf="column.type === DisplayType.CommentsCount">
              <struct-comments-column [value]="getValue(row, column.id)"></struct-comments-column>
            </ng-container>
            <ng-container *ngIf="column.type === DisplayType.FixedList">
              <struct-fixedlist-column [value]="getValue(row, column.id)"></struct-fixedlist-column>
            </ng-container>
            <ng-container *ngIf="column.type === DisplayType.UserUserGroup">
              <struct-user-usergroup-column [value]="getValue(row, column.id)"></struct-user-usergroup-column>
            </ng-container>
            <ng-container *ngIf="column.type === DisplayType.BadgeItem">
              <struct-badge-item-column [value]="getValue(row, column.id)"></struct-badge-item-column>
            </ng-container>
            <ng-container *ngIf="column.type === DisplayType.Media">
              <struct-media-column class="w-full" [value]="getValue(row, column.id)"></struct-media-column>
            </ng-container>
            <ng-container *ngIf="column.type === DisplayType.Boolean">
              <struct-boolean-column [value]="getValue(row, column.id)?.toString()?.toLowerCase() === 'true'"></struct-boolean-column>
            </ng-container>
            <ng-container *ngIf="column.type === DisplayType.Date || column.type === DisplayType.DateTime">
              <struct-date-column [displayType]="column.type" [value]="getValue(row, column.id)"></struct-date-column>
            </ng-container>
            <ng-container *ngIf="column.type === DisplayType.Culture">
              <struct-culture-column [value]="getValue(row, column.id)"></struct-culture-column>
            </ng-container>
          </div>

          <div *ngIf="tableDefinition.rowActions && tableDefinition.rowActions.length > 0 && isRowActionEnabled(row)" class="struct-row-actions">
            <struct-icon-button
              *ngFor="let action of tableDefinition.rowActions"
              iconSize="16"
              [icon]="action.icon"
              [matTooltip]="action.name | translate"
              (click)="executeAction(action, row.id)"
              [attr.data-cy]="'struct.table.action.' + action.name + '.' + row.id"></struct-icon-button>
          </div>

          <ng-template #table_row_context_menu>
            <div class="struct-context-menu" cdkMenu *ngIf="tableDefinition.rowActions && tableDefinition.rowActions.length > 0 && isRowActionEnabled(row)">

              <button
                class="struct-context-menu-item"
                cdkMenuItem
                *ngFor="let action of tableDefinition.rowActions"
                (click)="executeAction(action, row.id)"
                [attr.data-cy]="'struct.table.action.' + action.name + '.' + row.id">
                  <struct-icon size="16" icon="{{ action.icon }}"></struct-icon>
                  {{ action.name | translate }}
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </section>

  <!-- Grid view -->
  <section *ngIf="defaultDisplayMode === TableDisplayMode.Card">
    <div *ngIf="!loaded" class="relative flex h-64 border-collapse border-spacing-0 items-center justify-center border border-r-0 border-b-0 border-l-0 border-t-gray-100 bg-white p-10 text-center">
      <struct-spinner></struct-spinner>
    </div>

    <div class="flex items-center struct-header-cell h-16 -mt-20 mb-4">
      <div class="flex-grow">
        <div *ngIf="allowMultiSelect" [ngClass]="{ 'is-active': selectedRows.size > 0 }" class="ml-3 flex items-center struct-header-left-content">
          <div class="mr-4 flex items-center gap-2 overflow-hidden w-32">
            <span class="font-medium text-md">{{ selectedRows.size }} selected</span>
            <struct-icon-button size="small" icon="close" iconSize="16" [useMaterialIcons]="true" (click)="deselectAll()"></struct-icon-button>
          </div>
          <div class="flex items-center">
            <div class="h-6 border-l border-gray-200 pl-4"></div>
            <ng-container *ngTemplateOutlet="actionsOutlet"></ng-container>
          </div>
        </div>
      </div>
      <div class="flex-grow"></div>
    </div>

    <div
      *ngIf="rows.length === 0 && loaded"
      class="relative flex border-collapse border-spacing-0 items-center justify-center border border-r-0 border-b-0 border-l-0 border-t-gray-100 bg-white p-10 text-center"
      role="row">
      <div class="flex w-96 flex-col items-center justify-center">
        <svg class="w-48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <g stroke-linecap="square" stroke-linejoin="miter" stroke-width="3" transform="translate(0.5 0.5)" fill="none" stroke="currentColor" stroke-miterlimit="10">
            <path d="M56,58H8a5,5,0,0,1-5-5V6H24l6,10H61V53A5,5,0,0,1,56,58Z"></path>
            <line x1="37.1" y1="42.1" x2="45" y2="50"></line>
            <circle cx="30" cy="35" r="10"></circle>
          </g>
        </svg>
        <h4 class="mt-4 text-xl font-medium">No items matched your search</h4>
        <div class="mt-6 flex items-center">
          <span class="mr-2 text-md">Refine your filters or</span>
          <struct-button (click)="resetSearch()" *ngIf="!searchIsEmpty || showResetSearch" matTooltip="Reset search" [matTooltipPosition]="'above'">Reset search</struct-button>
        </div>
      </div>
    </div>

    <div *ngIf="loaded">
      <div
        class="grid grid-cols-1 @xl/workarea:grid-cols-2 @4xl/workarea:grid-cols-3 @8xl/workarea:grid-cols-4 @10xl/workarea:grid-cols-6 @12xl/workarea:grid-cols-8 @16xl/workarea:grid-cols-9 @20xl/workarea:grid-cols-10 @24xl/workarea:grid-cols-12 gap-2 lg:gap-4">
        <div *ngFor="let row of rows; trackBy: trackById">
          <mat-card
            class="@container/card relative group"
            [ngClass]="{ 'is-selected': selectedRows.has(row.id) }"
            (click)="toggleRowSelection(row)"
            [cdkContextMenuTriggerFor]="card_item_context_menu">
            <div class="absolute z-20 top-4 left-4">
              <div id="card-item-checkbox"
                class="rounded bg-transparent w-6 h-6 ring-2 ring-inset ring-gray-700 flex items-center justify-center text-gray-700 opacity-0 group-hover:opacity-100"
                *ngIf="allowMultiSelect && !row.selected"></div>
              <div class="rounded bg-blue-500 w-6 h-6 flex items-center justify-center animate-in slide-in-from-bottom-2 text-white" *ngIf="allowMultiSelect && row.selected">
                <struct-icon icon="check"></struct-icon>
              </div>
            </div>

            <ng-container *ngIf="row.thumbnailUrl !== null && row.thumbnailUrl !== undefined">
              <div class="p-0 relative">
                <div class="flex bg-gray-100 items-center justify-center text-gray-300 h-48 w-full relative overflow-hidden">

                  <img
                    ngSrc="{{row.thumbnailUrl}}"
                    width="400"
                    height="400"
                    class="h-full w-auto max-w-full max-h-full object-cover object-center mix-blend-multiply"
                    *ngIf="isRenderableImage(row.thumbnailLabel)" />

                  <video
                    (mouseenter)="videoEnter($event, row)"
                    (mouseout)="videoLeave($event, row)"
                    muted
                    width="400"
                    height="400"
                    class="inlineVideo z-10 h-full w-auto max-w-full max-h-full object-cover object-center mix-blend-multiply"
                    *ngIf="isRenderableVideo(row.thumbnailLabel)">
                    <source src="{{row.thumbnailUrl}}" type="video/mp4" />
                  </video>

                  <struct-icon *ngIf="isRenderableVideo(row.thumbnailLabel) && !$any(row).videoPlaying" icon="play_circle" [useMaterialIcons]="true" size="64" class="text-white absolute z-20 opacity-50"></struct-icon>

                  <!-- Render the filetype instead of image -->
                  <struct-filetype-icon
                    size="72"
                    [filetype]="row.thumbnailLabel"
                    *ngIf="isOfficeDocument(row.thumbnailLabel) || isPdf(row.thumbnailLabel) || isUnknownFile(row.thumbnailLabel)">
                  </struct-filetype-icon>

                  <span id="card-image-label" *ngIf="row.thumbnailLabel !== null && row.thumbnailLabel !== undefined" [ngClass]="[row.thumbnailLabelBgColor === null ? 'bg-gray-700/75' : 'bg-' + row.thumbnailLabelBgColor, row.thumbnailLabelTextColor === null ? 'text-white' : 'text-' + row.thumbnailLabelTextColor]" class="absolute z-20 bottom-2 right-2 inline-flex items-center rounded px-2 py-1 text-[9px] font-semibold tracking-wide uppercase">
                    {{ row.thumbnailLabel }}
                  </span>
                </div>

                <div class="absolute inset-0 hidden group-hover:flex items-center justify-center animate-in slide-in-from-bottom-2 duration-600">
                  <div *ngIf="tableDefinition.rowActions && tableDefinition.rowActions.length > 0 && isRowActionEnabled(row)" class="bg-white flex p-1 rounded-full">
                    <struct-icon-button
                      *ngFor="let action of tableDefinition.rowActions"
                      class="z-20"
                      iconSize="16"
                      [icon]="action.icon"
                      (click)="executeAction(action, row.id)"
                      [attr.data-cy]="'struct.table.action.' + action.name + '.' + row.id"></struct-icon-button>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="row.thumbnailUrl === null || row.thumbnailUrl === undefined">
              <div class="p-0 relative">
                <div class="flex bg-gray-100 items-center justify-center text-gray-300 h-48 w-full relative overflow-hidden">
                  <div *ngIf="row.icon !== null" class="w-32 h-32 bg-gray-200/50 rounded-full flex items-center justify-center">
                    <struct-icon [icon]="row.icon" class="text-gray-300 w-16 h-16" size="32"></struct-icon>
                  </div>

                  <span *ngIf="row.thumbnailLabel !== null && row.thumbnailLabel !== undefined" [ngClass]="[row.thumbnailLabelBgColor === null ? 'bg-gray-700/75' : 'bg-' + row.thumbnailLabelBgColor, row.thumbnailLabelTextColor === null ? 'text-white' : 'text-' + row.thumbnailLabelTextColor]" class="absolute bottom-2 right-2 inline-flex items-center rounded px-2 py-1 text-[9px] font-semibold tracking-wide uppercase">
                    {{ row.thumbnailLabel }}
                  </span>
                </div>

                <div class="absolute inset-0 hidden group-hover:flex items-center justify-center animate-in slide-in-from-bottom-2 duration-600">
                  <div *ngIf="tableDefinition.rowActions && tableDefinition.rowActions.length > 0 && isRowActionEnabled(row)" class="bg-white flex p-1 rounded-full">
                    <struct-icon-button
                      *ngFor="let action of tableDefinition.rowActions"
                      iconSize="16"
                      [icon]="action.icon"
                      (click)="executeAction(action, row.id)"
                      [attr.data-cy]="'struct.table.action.' + action.name + '.' + row.id"></struct-icon-button>
                  </div>
                </div>
              </div>
            </ng-container>

            <mat-card-content class="flex-grow flex flex-col gap-2 max-h-32 py-4 overflow-hidden group-hover:overflow-y-auto struct-scrollbar">
              <ng-container *ngFor="let column of displayedColumns">
                <div
                  class="struct-column-{{ column.id }} flex flex-col gap-0 flex-grow items-start @sm/card:flex-row @sm/card:items-center @sm/card:gap-1"
                  role="gridcell"
                  *ngIf="DisplayType.parse(column.type) !== DisplayType.Media">
                  <span class="text-sm w-full truncate text-gray-400 flex-shrink-0 h-5">{{ column.title }}:</span>
                  <span class="text-sm flex-grow truncate w-full">
                    <ng-container *ngIf="DisplayType.parse(column.type) === DisplayType.Text">
                      <struct-text-column [value]="getValue(row, column.id)"></struct-text-column>
                    </ng-container>
                    <ng-container *ngIf="DisplayType.parse(column.type) === DisplayType.Number">
                      <struct-number-column [value]="getValue(row, column.id)"></struct-number-column>
                    </ng-container>
                    <ng-container *ngIf="DisplayType.parse(column.type) === DisplayType.Percentage">
                      <struct-percentage-column [value]="getValue(row, column.id)" [type]="2"></struct-percentage-column>
                    </ng-container>
                    <ng-container *ngIf="DisplayType.parse(column.type) === DisplayType.FixedList">
                      <struct-fixedlist-column [value]="getValue(row, column.id)"></struct-fixedlist-column>
                    </ng-container>
                    <ng-container *ngIf="DisplayType.parse(column.type) === DisplayType.UserUserGroup">
                      <struct-user-usergroup-column [value]="getValue(row, column.id)"></struct-user-usergroup-column>
                    </ng-container>
                    <ng-container *ngIf="DisplayType.parse(column.type) === DisplayType.Boolean">
                      <struct-boolean-column [value]="getValue(row, column.id)?.toString()?.toLowerCase() === 'true'"></struct-boolean-column>
                    </ng-container>
                    <ng-container *ngIf="DisplayType.parse(column.type) === DisplayType.Date || column.type === DisplayType.DateTime">
                      <struct-date-column [displayType]="column.type" [value]="getValue(row, column.id)"></struct-date-column>
                    </ng-container>
                    <ng-container *ngIf="DisplayType.parse(column.type) === DisplayType.Culture">
                      <struct-culture-column [value]="getValue(row, column.id)"></struct-culture-column>
                    </ng-container>
                  </span>
                </div>
              </ng-container>
            </mat-card-content>
          </mat-card>

          <ng-template #card_item_context_menu>
            <div class="struct-context-menu" cdkMenu *ngIf="tableDefinition.rowActions && tableDefinition.rowActions.length > 0 && isRowActionEnabled(row)">
              <button
                class="struct-context-menu-item"
                cdkMenuItem
                *ngFor="let action of tableDefinition.rowActions"
                iconSize="16"
                (click)="executeAction(action, row.id)"
                [attr.data-cy]="'struct.table.action.' + action.name + '.' + row.id">
                <struct-icon size="16" icon="{{ action.icon }}"></struct-icon>
                {{ action.name | translate }}
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <!--paging-->
    <div class="flex justify-end">
      <struct-paginator
        *ngIf="defaultDisplayMode === TableDisplayMode.Card"
        [classList]="'bg-gray-50'"
        [totalHits]="totalHits"
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="defaultPageSize"
        [showFirstLastButtons]="showFirstLastPageButton"
        [hidePageSize]="!showPageSizeSelector"
        [pageIndex]="searchDefinition.pageIndex"
        (pageChange)="pageChanged($event)"></struct-paginator>
    </div>
    <!--paging-->
  </section>
</div>

<ng-template #actionsOutlet>
  <ng-content select="struct-selected-items-actions"></ng-content>
</ng-template>
