import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UiModule } from '@struct/ui/ui.module';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { StructNotificationService } from '@struct/ui/struct-notification';

@Component({
    selector: 'accounts-sign-in-with-microsoft',
    imports: [CommonModule, UiModule, TranslateModule, RouterModule],
    templateUrl: './sign-in-with-microsoft.component.html'
})
export class SignInWithMicrosoftComponent implements OnInit {
  private tenant: string | null = null;

  constructor(private authService: AuthenticationService, private route: ActivatedRoute, private tr: TranslateService, private notificationService: StructNotificationService) {
  }

  ngOnInit(): void {
    const tenant = this.route.snapshot.queryParams['tenant'];
    this.authService.redirectToEntraIdLogin(tenant);
  }
}
