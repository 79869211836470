import { Component, Injectable, ViewChild, ElementRef } from '@angular/core';
import { CollectionViewer, SelectionChange, DataSource } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { UiModule } from '@struct/ui/ui.module';
import { A11yModule } from '@angular/cdk/a11y';
import { ProductPageComponent } from '../product-page/product-page.component';

/** Flat node with expandable and level information */
export class DynamicFlatNode {
  constructor(public item: string, public level = 1, public expandable = false, public isLoading = false) {}
}

/**
 * Database for dynamic data. When expanding a node in the tree, the data source will need to fetch
 * the descendants data from the database.
 */
@Injectable({ providedIn: 'root' })
export class DynamicDatabase {
  dataMap = new Map<string, string[]>([
    ['Building materials', ['Wood', 'Tiles']],
    ['Clothing', ['T-shirts', 'Shoes', 'Pants']],
    ['Tools', ['Power tools', 'Hand tools', 'Accessories', 'Kitchen appliances']],
    ['Electronics', ['Laptops', 'Monitors', 'TVs', 'Mobile', 'Printers']],
    ['Events', ['Wine tasting', 'Courses']],
    ['Food & drink', ['Wine', 'Bread']],

    ['Power tools', ['Power drills', 'Grinders']],
    ['Hand tools', ['Hammers', 'Hand saws', 'Screwdrivers']],
  ]);

  rootLevelNodes: string[] = ['Building materials', 'Clothing', 'Tools', 'Electronics', 'Events', 'Food & drink'];

  /** Initial data from database */
  initialData(): DynamicFlatNode[] {
    return this.rootLevelNodes.map(name => new DynamicFlatNode(name, 0, true));
  }

  getChildren(node: string): string[] | null {
    return this.dataMap.get(node) ?? null;
  }

  isExpandable(node: string): boolean {
    return this.dataMap.has(node);
  }
}
/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `FileNode` with nested
 * structure.
 */
export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }
  set data(value: DynamicFlatNode[]) {
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(private _treeControl: FlatTreeControl<DynamicFlatNode>, private _database: DynamicDatabase) {}

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this._treeControl.expansionModel.changed.subscribe(change => {
      if ((change as SelectionChange<DynamicFlatNode>).added || (change as SelectionChange<DynamicFlatNode>).removed) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  disconnect(collectionViewer: CollectionViewer): void {}

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: DynamicFlatNode, expand: boolean) {
    const children = this._database.getChildren(node.item);
    const index = this.data.indexOf(node);
    if (!children || index < 0) {
      // If no children, or cannot find the node, no op
      return;
    }

    node.isLoading = true;

    setTimeout(() => {
      if (expand) {
        const nodes = children.map(name => new DynamicFlatNode(name, node.level + 1, this._database.isExpandable(name)));
        this.data.splice(index + 1, 0, ...nodes);
      } else {
        let count = 0;
        for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) // eslint-disable-next-line no-empty
        {}
        this.data.splice(index + 1, count);
      }

      // notify the change
      this.dataChange.next(this.data);
      node.isLoading = false;
    }, 1000);
  }
}

@Component({
    selector: 'accounts-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
    imports: [CommonModule, UiModule, A11yModule, ProductPageComponent]
})
export class ShellComponent {
  @ViewChild('search-field') searchElement: ElementRef | null = null;

  showKeyboardShortcuts = false;

  quickSearchActive = false;
  quickLaunchActive = false;
  quickTaskActive = false;

  activeCatalogue = '';
  activeCatalogueIsMaster = false;

  quickSearchInputText = '';
  quickSearchIsLoading = false;

  constructor(database: DynamicDatabase) {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(this.treeControl, database);

    this.dataSource.data = database.initialData();
  }

  treeControl: FlatTreeControl<DynamicFlatNode>;

  dataSource: DynamicDataSource;

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;

  public toggleKeyboardShortcuts(): void {
    this.showKeyboardShortcuts = !this.showKeyboardShortcuts;
  }

  public setActiveCatalogue(name: string, master: boolean): void {
    this.activeCatalogue = name;
    this.activeCatalogueIsMaster = master;
  }

  public toggleQuickSearch(): void {
    this.quickSearchActive = !this.quickSearchActive;
    this.quickLaunchActive = false;
    this.quickTaskActive = false;
  }

  public toggleQuickLaunch(): void {
    this.quickLaunchActive = !this.quickLaunchActive;
    this.quickSearchActive = false;
    this.quickTaskActive = false;
  }

  public toggleQuickTask(): void {
    this.quickTaskActive = !this.quickTaskActive;
    this.quickSearchActive = false;
    this.quickLaunchActive = false;
  }

  public closeAllQuickies(): void {
    this.quickLaunchActive = false;
    this.quickSearchActive = false;
    this.quickTaskActive = false;
  }

  //Quick Search
  public setSearchText(text: string): void {
    this.quickSearchInputText = text;
    this.setQuickSearchLoading(true);

    setTimeout(() => {
      this.setQuickSearchLoading(false);
    }, 2000);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onSearchChange(event: any): void {
    //console.log(event.target.value);
    this.quickSearchInputText = event.target.value;
    this.setQuickSearchLoading(true);

    setTimeout(() => {
      this.setQuickSearchLoading(false);
    }, 2000);
  }

  setQuickSearchLoading(isLoading: boolean): void {
    this.quickSearchIsLoading = isLoading;
  }
}
