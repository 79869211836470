import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslatableAttributeReference } from '@struct/models/struct/app/domain/attributes/translatable-attribute-reference';
import { StructEntityType } from '@struct/models/struct/app/domain/shared/struct-entity-type';
import { TranslationsApiService } from "@struct/services/frontend-api/translations-api.service";

@Component({
    selector: 'struct-translatable-attributes-picker',
    templateUrl: './struct-translatable-attributes-picker.component.html',
    styleUrls: ['./struct-translatable-attributes-picker.component.scss'],
    standalone: false
})
export class StructTranslatableAttributesPickerComponent 
{
  @Input() multiple: boolean = false;
  @Input() value: any | null = null;
  @Input() valueText: string[] = [];
  @Input() required: boolean = false;
  @Input() loading: boolean = true;
  @Output() loadingChange = new EventEmitter<boolean>();
  @Output() valueChange = new EventEmitter();
  @Output() valueTextChange = new EventEmitter<string[]>();
  @Input({required: true}) name = 'attribute';
  @Input() entityType: StructEntityType = StructEntityType.Product;
  @Input() placeholder: string = 'placeholder';

  translatableAttributeReference : TranslatableAttributeReference[] = [];

  constructor(private TranslationsApiService: TranslationsApiService, cdr: ChangeDetectorRef){

    TranslationsApiService.getTranslatableAttributes(this.entityType).subscribe(x => {
      this.translatableAttributeReference = x ?? [];
      cdr.detectChanges();
      this.loadingChange.emit(false);
    });

  }

  languageChanged(event: any){
    this.valueChange.emit(event);
    const eventNames: string[] = [];
    if(this.multiple){
      event.forEach((element: any) => {
        const field = this.translatableAttributeReference.find(x => x.uid === element);
        eventNames.push(field?.name ?? '');
      });

      this.valueTextChange.emit(eventNames);
    }
    else{
      const field = this.translatableAttributeReference.find(x => x.uid === event);
      eventNames.push(field?.name ?? '');
      this.valueTextChange.emit(eventNames);
    }
  }
}