import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { UiModule } from '@struct/ui/ui.module';
import { TenantsComponent } from '../tenant/tenants/tenants.component';

@Component({
    selector: 'accounts-list-page',
    templateUrl: './list-page.component.html',
    styleUrls: ['./list-page.component.scss'],
    imports: [CommonModule, UiModule, TenantsComponent]
})
export class ListPageComponent {}
