import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { UiModule } from '@struct/ui/ui.module';
import { Observable, ReplaySubject, of, takeUntil } from 'rxjs';
import { AccountsFormSectionContentComponent } from '../../accounts-ui/accounts-form/accounts-form-section-content/accounts-form-section-content.component';
import { AccountsFormSectionDescriptionComponent } from '../../accounts-ui/accounts-form/accounts-form-section-description/accounts-form-section-description.component';
import { AccountsFormSectionTitleComponent } from '../../accounts-ui/accounts-form/accounts-form-section-title/accounts-form-section-title.component';
import { AccountsFormSectionComponent } from '../../accounts-ui/accounts-form/accounts-form-section/accounts-form-section.component';
import { AccountsFormComponent } from '../../accounts-ui/accounts-form/accounts-form/accounts-form.component';
import { Currency } from '../models/currency';
import { SubscriptionStatus } from '../models/subscription-status';
import { StructNotificationService, StructSpinnerService } from '@struct/ui/index';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { nameof } from '@struct/utilities/index';
import { CountryModel } from '@struct/models/accountmanagement/domain/models';
import { MiscApiService, SubscriptionApiService, SubscriptionPlanApiService } from '@struct/services/account-management';
import { User } from '@struct/models/struct/app/domain/usermanagement/user';
import { SubscriptionPlan } from '@struct/models/struct/shared/subscription/subscription-plan';
import { UserSubscription } from '@struct/models/struct/shared/subscription/user-subscription';
import { Subscription } from '@struct/models/struct/shared/subscription/subscription';
import { SubscriptionRole } from '@struct/models/struct/shared/subscription/subscription-role';
import { AddSubscriptionToUserCommand } from '@struct/models/struct/shared/subscription/commands/add-subscription-to-user-command';
import { RemoveSubscriptionToUserCommand } from '@struct/models/struct/shared/subscription/commands/remove-subscription-to-user-command';
import { UsersApiService } from '@struct/services/account-management';
import { UserSearchModel } from '@struct/models/struct/accountmanagement/api/models/users/user-search-model';
import { StructDialogConfig, StructDialogPosition, StructDialogWidth } from '@struct/ui/struct-dialog/StructDialogConfig';
import { InviteUserSubscriptionDialogComponent } from '../add-user-to-subscription-dialog/add-user-to-subscription-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionInvite } from '@struct/models/struct/usermanagementmodule';

@Component({
    selector: 'accounts-subscription-details',
    templateUrl: './subscription-details.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    imports: [
        CommonModule,
        UiModule,
        AccountsFormComponent,
        AccountsFormSectionComponent,
        AccountsFormSectionTitleComponent,
        AccountsFormSectionDescriptionComponent,
        AccountsFormSectionContentComponent,
    ]
})
export class SubscriptionDetailsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() subscription: Subscription | null = null;
  @ViewChild('addSubscriptionOwnerForm') subscriptionOwnerForm!: NgForm;

  addSubscriptionOwnerName: string | null = null;
  addSubscriptionOwnerEmail: string | null = null;
  subscriptionPlans$: Observable<SubscriptionPlan[]> | null = null;
  subscriptionPlan: SubscriptionPlan | null = null;
  paymentCurrency: string | null = null;
  currencies: Currency[] = [
    { id: 'DKK', name: 'DKK' },
    { id: 'EUR', name: 'EUR' },
    { id: 'USD', name: 'USD' },
  ];
  isNewSubscription: boolean = true;

  subscriptionStatusses: SubscriptionStatus[] = [
    { id: 0, name: 'Awaiting approval' },
    { id: 10, name: 'Active' },
    { id: 20, name: 'Suspended' },
    { id: 30, name: 'Terminated' },
  ];

  countries$: Observable<CountryModel[]> | null = null;
  usersInSubscription: UserSubscription[] | null = null;
  userInvitations: SubscriptionInvite[] | null = null;
  usersNotInSubscription: UserSearchModel[] | null = null;
    private destroyed$ = new ReplaySubject();

  constructor(
    private miscApi: MiscApiService,
    private subscriptionPlanApi: SubscriptionPlanApiService,
    private subscriptionApi: SubscriptionApiService,
    private notificationService: StructNotificationService,
    private dialog: MatDialog,
    private spinnerService: StructSpinnerService,
    private userApi: UsersApiService,
    private currentRoute: ActivatedRoute,
    private dialogService: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.subscriptionPlans$ = this.subscriptionPlanApi.getSubscriptionPlans().pipe(takeUntil(this.destroyed$));
    this.countries$ = this.miscApi.getCountries().pipe(takeUntil(this.destroyed$));
    if (this.subscription?.uid != '00000000-0000-0000-0000-000000000000') {
      this.isNewSubscription = false;
      
      // if (this.subscription) {
        
      //   this.subscriptionApi.getInvitesInSubscription(this.subscription.uid).subscribe(i => {
      //     this.userInvitations = i;
      //   });
      // }

      // if (this.subscription) {
      //   this.subscriptionApi.getUsersInSubscription(this.subscription.uid).subscribe(p => {
      //     this.usersInSubscription = p;
      //   });
      // }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isNewSubscription) {
      if (nameof<this>('subscription') in changes && this.subscription) {
        this.subscriptionApi.getUsersInSubscription(this.subscription.uid).subscribe(p => {
          this.usersInSubscription = p;
        });

        this.subscriptionApi.getInvitesInSubscription(this.subscription.uid).subscribe(i => {
          this.userInvitations = i;
        });

        this.subscriptionPlanApi.getSubscriptionPlan(this.subscription.subscriptionPlanUid).subscribe(c => {
          this.subscriptionPlan = c;
        });
      }
    }
  }

  inviteSubscriptionManager(): void {
    //let origId = this.currentRoute.snapshot.paramMap.get('uid');
    const data = this.currentRoute.snapshot.paramMap.get('uid');

    const dialogConfig = new StructDialogConfig();
    dialogConfig.position = StructDialogPosition.Center;
    dialogConfig.width = StructDialogWidth.Quarter;

    const config = new MatDialogConfig();
    config.width = '500px';
    config.data = data;
    config.height = '500px';
    this.dialogService
      .open(InviteUserSubscriptionDialogComponent, config)
      .afterClosed()
      .subscribe(x => {
        this.subscriptionApi.getInvitesInSubscription(this.subscription!.uid).subscribe(i => {
          this.userInvitations = i;
        });
      });
  }

  resendInvite(invite: SubscriptionInvite):void {
    this.subscriptionApi.resendSubscriptionInvite(invite.uid).subscribe(p=> {
      if(this.subscription) {
        this.subscriptionApi.getInvitesInSubscription(this.subscription!.uid).subscribe(i => {
          this.userInvitations = i;
        });
      }
    });
  }

  removeUser(user: UserSubscription): void {

    const command = new RemoveSubscriptionToUserCommand();
    command.subscriptionUid = this.subscription!.uid;
    command.userUid = user.userUid;

    this.subscriptionApi.removeUserFromSubscription(command).subscribe(c=> {
      this.subscriptionApi.getUsersInSubscription(this.subscription!.uid).subscribe(p => {
        this.usersInSubscription = p;
      });
    })
        return;
  }
  cancelInvite(invite: SubscriptionInvite):void {
      this.subscriptionApi.cancelSubscriptionInvite(invite.uid).subscribe(p=> {
        if(this.subscription) {
          this.subscriptionApi.getInvitesInSubscription(this.subscription!.uid).subscribe(i => {
            this.userInvitations = i;
          });
        }
      });
  }
  // getSubscriptionManagerAndOwners(): UserSubscription[] {
  //   if(this.subscriptionApi)
  //     return this.subscriptionApi.getUsersInSubscription(this.subscription?.uid);

  // }

  overrideQuotas(): void {
    if (this.subscription) {
      this.subscriptionPlanApi.getSubscriptionPlan(this.subscription.subscriptionPlanUid).subscribe(c => {
        this.subscriptionPlan = c;
        if (this.subscription) {
          this.subscription.setup.quotasOverride = this.subscriptionPlan.setup.quotas;
        }
      });
    }
    return;
  }

  overrideOptionalFeatures(): void {
    if (this.subscription) {
      this.subscriptionPlanApi.getSubscriptionPlan(this.subscription.subscriptionPlanUid).subscribe(c => {
        this.subscriptionPlan = c;
        if (this.subscription) {
          this.subscription.setup.optionalFeaturesOverride = this.subscriptionPlan.setup.optionalFeatures;
        }
      });
    }
    return;
  }

  selectSubscriptionPlan(subscriptionPlanUid: string | null): void {
    if (this.subscription && subscriptionPlanUid !== null) {
      this.subscription.subscriptionPlanUid = subscriptionPlanUid;
    }
  }
}
