import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@struct/ui/ui.module';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { UsersApiService } from '@struct/services/account-management';
import { AcceptInviteModel } from '@struct/models/struct/accountmanagement/api/models/users/accept-invite-model';
import { AdminInvite } from '@struct/models/struct/usermanagementmodule/admin-invite';
import { TenantInvite } from '@struct/models/struct/usermanagementmodule/tenant-invite';
import { OrganizationInvite } from '@struct/models/struct/usermanagementmodule/organization-invite';
import { SubscriptionInvite } from '@struct/models/struct/usermanagementmodule/subscription-invite';
import { RegisterUserComponent } from '../register-user/register-user.component';
import { AcceptExistingUserInviteComponent } from '../accept-existing-user-invite/accept-existing-user-invite.component';

@Component({
    selector: 'accounts-accept-invite',
    imports: [CommonModule, UiModule, TranslateModule, RouterModule, RegisterUserComponent, AcceptExistingUserInviteComponent],
    templateUrl: './accept-invite.component.html',
    styleUrls: ['./accept-invite.component.scss']
})
export class AcceptInviteComponent implements OnInit {
  invite: AcceptInviteModel | null = null;
  loaded = false;
  showMarketing = true;
  inviteToken: string = "";
  inviteUid: string = "";

  inviteIsExpired: boolean = false;
  inviteIsInvalid: boolean = false;
  inviteIsAlreadyAccepted: boolean = false;
  inviteIsAlreadyDeclined: boolean = false;
  inviteIsForExistingUser: boolean = false;
  inviteIsForNewUser: boolean = false;

  constructor(private usersApi: UsersApiService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.inviteUid = this.route.snapshot.queryParams['inviteUid'];
    this.inviteToken = this.route.snapshot.queryParams['token'];
    this.usersApi
      .getInviteModel(this.inviteUid, this.inviteToken)
      .subscribe(invite => {
        this.invite = invite;

        this.inviteIsInvalid = this.invite === null;
        this.inviteIsAlreadyAccepted = this.invite?.invite?.accepted === true;
        this.inviteIsAlreadyDeclined = this.invite?.invite?.accepted === false;
        this.inviteIsExpired = !this.inviteIsAlreadyAccepted && !this.inviteIsAlreadyDeclined && this.invite?.expired === true;
        this.inviteIsForExistingUser = !this.inviteIsAlreadyAccepted && !this.inviteIsAlreadyDeclined && !this.inviteIsExpired && this.invite?.user !== null;
        this.inviteIsForNewUser = !this.inviteIsAlreadyAccepted && !this.inviteIsAlreadyDeclined && !this.inviteIsExpired && this.invite?.user === null;

        this.loaded = true;
      });
  }

  isAdminInvite(): boolean {
    return (this.invite?.invite?.type ?? "") === AdminInvite.name;
  }

  isTenantInvite(): boolean {
    return (this.invite?.invite?.type ?? "") === TenantInvite.name;
  }

  getTenantInvite(): TenantInvite {
    return this.invite?.invite as TenantInvite ?? new TenantInvite();
  }

  isOrganizationInvite(): boolean {
    return (this.invite?.invite?.type ?? "") === OrganizationInvite.name;
  }

  getOrganizationInvite(): OrganizationInvite {
    return this.invite?.invite as OrganizationInvite ?? new OrganizationInvite();
  }

  isSubscriptionInvite(): boolean {
    return (this.invite?.invite?.type ?? "") === SubscriptionInvite.name;
  }

  getSubscriptionInvite(): SubscriptionInvite {
    return this.invite?.invite as SubscriptionInvite ?? new SubscriptionInvite();
  }

  toggleMarketingColumn() {
    this.showMarketing = !this.showMarketing;
  }
}
