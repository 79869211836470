import { Component, Input } from '@angular/core';

@Component({
    selector: 'struct-form-row',
    templateUrl: './struct-form-row.component.html',
    styleUrls: ['./struct-form-row.component.scss'],
    standalone: false
})
export class StructFormRowComponent {
  @Input() borderBelow = false;
  @Input() marginBelow = true;
  @Input() stack = false;
  @Input() hideDescription = false;
  @Input() hideTitleAndDescription = false;
}
