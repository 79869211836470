import { Component, Input } from '@angular/core';

@Component({
    selector: 'struct-fab-button',
    templateUrl: './struct-fab-button.component.html',
    styleUrls: ['./struct-fab-button.component.scss'],
    standalone: false
})
export class StructFabButtonComponent {
  @Input() icon: string | null = null;
  @Input() color: string | null = null;
  @Input() disabled: boolean;
  @Input() buttonType: string;
  @Input() iconSize: string;
  @Input() useMaterialIcons = false;

  constructor() {
    this.disabled = false;
    this.buttonType = 'button';
    this.iconSize = '20';
  }
}
