import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'accounts-form-content',
    templateUrl: './accounts-form-content.component.html',
    imports: [CommonModule]
})
export class AccountsFormContentComponent {

}
