import { Component, Input } from '@angular/core';

@Component({
    selector: 'struct-app-content-metabar',
    templateUrl: './struct-app-content-metabar.component.html',
    styleUrls: ['./struct-app-content-metabar.component.scss'],
    standalone: false
})
export class StructAppContentMetabarComponent {
  @Input() illustration: string | null = null;
  showMetaInformation: boolean = false;
}
