import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UiModule } from '@struct/ui/ui.module';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationApiService } from '@struct/services/account-management';
import { HttpErrorResponse } from '@angular/common/http';
import { ResetPasswordCommand } from '@struct/models/struct/usermanagementmodule/commands/reset-password-command';
import { StructNotificationService } from '@struct/ui/struct-notification';

interface ResetPasswordForm {
  password: FormControl<string | null>;
  confirmPassword: FormControl<string | null>;
}

@Component({
    selector: 'accounts-reset-password',
    imports: [CommonModule, UiModule, TranslateModule, RouterModule],
    templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup<ResetPasswordForm>;
  showSuccess: boolean = false;
  showError: boolean = false;
  errorMessage: string = '';
  showMarketing = true;
  private token: string = "";
  private email: string = "";
  working = false;
  passwordVisible: boolean = false;
  passwordConfirmVisible: boolean = false;

  constructor(private authApi: AuthenticationApiService, private tr: TranslateService, private route: ActivatedRoute, private notificationService: StructNotificationService, private router: Router)
    {
      this.resetPasswordForm = new FormGroup({
        password: new FormControl('', [Validators.required]),
        confirmPassword: new FormControl('', [Validators.required])
    });
  }

    ngOnInit(): void {
      this.token = this.route.snapshot.queryParams['token'];
      this.email = this.route.snapshot.queryParams['email'];
  }

  public hasError(controlName: string, errorName: string) : boolean {
    return this.resetPasswordForm.get(controlName)?.hasError(errorName) ?? false;
  }

  public resetPassword() : void {
    this.showError = this.showSuccess = false;

    const request = new ResetPasswordCommand({
      password: this.resetPasswordForm.value.password ?? "",
      confirmPassword: this.resetPasswordForm.value.confirmPassword ?? "",
      token: this.token,
      email: this.email
    });

    this.authApi.resetPassword(request)
    .subscribe({
      next:(response) => {
        if(response.succeeded) {
          this.notificationService.showSuccessNotification(this.tr.get("ResetPassword.Success"))
          this.router.navigate(['/']);
        }
        else{
          this.showError = true;
          this.errorMessage = "ResetPassword.Error_" + response.errors[0].code;
        }
      },
      error: (err: HttpErrorResponse) => {
        this.showError = true;
        this.errorMessage = err.message;
      }})
  }

  togglePasswordVisibility(e:Event) {
    e.preventDefault();
    this.passwordVisible = !this.passwordVisible;
  }

  togglePasswordConfirmVisibility(e:Event) {
    e.preventDefault();
    this.passwordConfirmVisible = !this.passwordConfirmVisible;
  }

  toggleMarketingColumn() {
    this.showMarketing = !this.showMarketing;
  }
}
