import { Component, Input } from '@angular/core';
import { StructUserUsergroupModel } from './struct-user-usergroup-model';
import { StructAvatarInitialsColor } from '@struct/ui/struct-avatar/struct-avatar-initials-color';

@Component({
    selector: 'struct-user-usergroup-column',
    templateUrl: './struct-user-usergroup-column.component.html',
    standalone: false
})
export class StructUserUserGroupColumnComponent {
  @Input() value : string | string[] | StructUserUsergroupModel[] | null = null;


  avatarColors = StructAvatarInitialsColor;

  isUserGroupModel() : boolean{
    if(this.value === null){
      return false;
    }

    return Array.isArray(this.value) && this.value[0] instanceof StructUserUsergroupModel
  }

  getUserGroupModels() : StructUserUsergroupModel[]{
    if(this.value === null){
      return [];
    }

    return <StructUserUsergroupModel[]>this.value;
  }

  isSimpleValue() : boolean{
    if(this.value === null){
      return false;
    }

    return typeof this.value === 'string';
  }

  getSimpleValue() : string{
    if(this.value === null){
      return '';
    }

    return <string>this.value;
  }

  isSimpleValueArray() : boolean{
    if(this.value === null){
      return false;
    }

    return Array.isArray(this.value) && typeof this.value[0] === 'string';
  }

  getSimpleValueArray() : string[]{
    if(this.value === null){
      return [];
    }

    return <string[]>this.value;
  }
}
