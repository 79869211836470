import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { StructColor, StructColorService } from '@struct/ui/color.service';

@Component({
    selector: 'struct-color-picker',
    templateUrl: './struct-color-picker.component.html',
    styleUrls: ['./struct-color-picker.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: false
})
export class StructColorPickerComponent {
  @Input() multiple: boolean = false;
  @Input() value: string| null = null;
  @Input() required: boolean = false;
  @Output() valueChange: EventEmitter<string>= new EventEmitter<string>();
  @Input({required:true}) name = 'name';
  @Input() placeholder: string = 'placeholder';

  colors : StructColor[] = [];

  constructor(colorService : StructColorService){
    this.colors = colorService.getColors();
  }

  colorChanged(event: any){
    this.valueChange.emit(event?.toString());
  }
}
