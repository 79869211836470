import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'accounts-list-content',
    templateUrl: './accounts-list-content.component.html',
    styleUrls: ['./accounts-list-content.component.scss'],
    imports: [CommonModule]
})
export class AccountsListContentComponent {
  
  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entities : any[] | null = null;

  @Input()
  entityName : string | null = null;

}
