import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
    selector: 'struct-textarea',
    templateUrl: './struct-textarea.component.html',
    styleUrls: ['./struct-textarea.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: false
})
export class StructTextareaComponent {
  @Input() required : boolean;
  @Input() name : string | null = null;
  @Input() placeholder : string | null = null;
  @Input() hintStart : string | null = null;
  @Input() hintEnd : string | null = null;
  @Input() value : string | null | null = null;
  @Input() disabled : string | boolean;
  @Input() cols : number | null = null;
  @Input() rows : number | null = null;
  @Input() malformed = false;
  @Output() valueChange = new EventEmitter<string>();

  constructor(){
    this.disabled = false;
    this.required = false;
  }

  onNgModelChange(newModel : string) : void {
    this.valueChange.emit(newModel);
  }
}
