import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs/internal/Observable";
import {tap} from "rxjs";
import { Router } from "@angular/router";
import { AuthenticationService } from "./authentication.service";


@Injectable()
export class UnauthorizedErrorHandler implements HttpInterceptor {

    constructor(private router: Router, private authService : AuthenticationService) {
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //handle errors globally
        return next.handle(req).pipe(
            tap({
                error: (err: any ) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            this.authService.removeAuthenticatedUser();
                            this.router.navigate(['/sign-in'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
                        }
                        if (err.status === 403) {
                            this.router.navigate(['/unauthorized']);
                        }
                      }
                },
                next: x => x
            })
        );
    }
}
