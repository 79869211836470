import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { UiModule } from '@struct/ui/ui.module';
import { OrganizationsApiService } from '@struct/services/account-management';
import { AuthenticationService } from '../../authentication/authentication.service';
import { OrganizationUserInviteCommand } from "@struct/models/accountmanagement/domain/organizations/commands/organization-user-invite-command";
import { ActivatedRoute } from '@angular/router';
import { AccountsHeaderComponent } from "../../accounts-ui/accounts-header/accounts-header.component";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StructNotificationService } from '@struct/ui/struct-notification';
import { of } from 'rxjs';
@Component({
    selector: 'accounts-invite-user-myorganization-dialog',
    templateUrl: './invite-user-myorganization-dialog.component.html',
    imports: [
        CommonModule,
        UiModule,
        AccountsHeaderComponent,
    ]
})
export class InviteUserMyOrganizationDialogComponent implements OnInit {
  working = false;
  command = new OrganizationUserInviteCommand();
  organizationRoles: OrganizationRole[] = [];


  @ViewChild('inviteform') inviteForm: NgForm | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private authService: AuthenticationService,
     private currentRoute: ActivatedRoute,
     private dialogRef: MatDialogRef<InviteUserMyOrganizationDialogComponent>,
     private organizationApi: OrganizationsApiService,
     private notificationService: StructNotificationService
     ) {}

  ngOnInit(): void {
      this.organizationRoles = [
        new OrganizationRole({ id: 'Owner', name: 'Owner' }),
        new OrganizationRole({ id: 'User', name: 'User' })
      ];
      this.command.organizationId =this.data;

      // this.authService.getAuthenticatedUser().subscribe(user => {
      //   this.command.invitedBy = user?.email ?? '';
      // });
  }

  sendInvite(): void {
    //this.dialogRef.close();
    this.working = true;


    this.organizationApi.inviteUserToOrganization(this.command).subscribe((response) => {
    //   this.working = false;
          if(!response.succeeded) {
            this.notificationService.showErrorNotification(of(response.errors[0].message));
          } else {
            this.dialogRef.close();
          }

    });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}


export class OrganizationRole {
    id!: string ;
    name!: string;

    constructor(data?: Partial<OrganizationRole>) {
      Object.assign(this, data);
    }
  }
