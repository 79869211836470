import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiModule } from '@struct/ui/ui.module';

@Component({
    selector: 'accounts-header',
    templateUrl: './accounts-header.component.html',
    styleUrls: ['./accounts-header.component.scss'],
    imports: [CommonModule, UiModule, RouterModule]
})
export class AccountsHeaderComponent {

  @Input()
  goBackToRoute : string | null = null;

  @Input()
  headerButtonText : string | null = null;

  @Input()
  headerButtonIsDelete : boolean | null = false;

  @Output()
  headerButtonClicked = new EventEmitter<void>();
}
