import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'struct-app-content-container',
    templateUrl: './struct-app-content-container.component.html',
    styleUrls: ['./struct-app-content-container.component.scss'],
    standalone: false
})
export class StructAppContentContainerComponent {
  @ViewChild("appContentContainer")
  private appContentContainerDiv!: ElementRef<HTMLElement>;

  @Input()
  disabled = false;

  @Input()
  showSpinner = false;

  @Input()
  noScrolling: boolean = false;

  scrolling:boolean = false;

  onScroll(e:Event) {
    //this.scrolling = true;
    //console.log(e);
    if (this.appContentContainerDiv) {
      if ((this.appContentContainerDiv.nativeElement as HTMLElement).scrollTop === 0) {
        this.scrolling = false;
      }

      if ((this.appContentContainerDiv.nativeElement as HTMLElement).scrollTop > 80) {
        this.scrolling = true;
      }
    }
  }

}
