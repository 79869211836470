import { Component, Input } from '@angular/core';

@Component({
    selector: 'struct-comments-column',
    templateUrl: './struct-comments-column.component.html',
    standalone: false
})
export class StructCommentsColumnComponent {
  @Input() value: number | null = null;
}
