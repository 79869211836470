import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'accounts-list-item-badge',
    templateUrl: './accounts-list-item-badge.component.html',
    styleUrls: ['./accounts-list-item-badge.component.scss'],
    imports: [CommonModule]
})
export class AccountsListItemBadgeComponent {

  @Input()
  bgColorClass : string | null = null;

}
