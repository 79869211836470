<aside class="h-full w-full bg-gray-100 overflow-hidden z-0 left-28" (click)="expandTemporarilyIfCollapsed()">
    <div [ngClass]="collapsed ? 'lg:w-20' : 'lg:w-full'" class="hidden relative lg:flex lg:flex-col h-full overflow-x-hidden overflow-y-auto struct-scrollbar z-10">
        <div class="flex flex-1">
            <section class="flex flex-col flex-1 overflow-hidden">
                <header class="flex h-20 flex-shrink-0 items-center fade-in" *ngIf="!collapsed && (menuComponent?.hideDefaultHeader ?? false) !== true">
                    <div class="flex flex-col md:flex-row md:min-w-0 md:flex-1 md:items-center md:justify-between px-4">
                        <div class="min-w-0 flex-1 flex items-center">
                            <span class="text-lg font-medium w-full truncate" [translate]="menuComponent?.menuTitle ?? ''"></span>
                        </div>
                    </div>
                </header>

                <ng-template structDynamicHost></ng-template>

            </section>
        </div>
        <div class="sticky bottom-0 flex px-4 py-2 justify-end flex-grow-0 animate-in slide-in-from-right-12 duration-400">
            <struct-icon-button *ngIf="!collapsed" (clicked)="toggleMenuCollapse($event, true)" icon="move_left" matTooltip="Collapse menu" [matTooltipPosition]="'right'" class="bg-gray-100/75 rounded-full"></struct-icon-button>
            <struct-icon-button *ngIf="collapsed" (clicked)="toggleMenuCollapse($event, false)" icon="move_right" matTooltip="Expand menu" [matTooltipPosition]="'right'" class="bg-gray-100/75 rounded-full"></struct-icon-button>
        </div>
    </div>
</aside>
