import { Component, computed, inject, Input, signal } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DisplayType } from '@struct/models/struct/shared/search/display-type';

@Component({
    selector: 'struct-date-column',
    templateUrl: './struct-date-column.component.html',
    styleUrls: ['./struct-date-column.component.scss'],
    standalone: false
})
export class StructDateColumnComponent {
  @Input({required: true}) value? : Date
  @Input({required: true}) displayType : DisplayType = DisplayType.DateTime;
  
  private readonly _locale = signal(inject<unknown>(MAT_DATE_LOCALE));
  readonly dateFormatString = computed(() => {
    if (this._locale() === 'en-US') {
      if(this.displayType === DisplayType.DateTime) {
        return 'YYYY/M/dd HH:mm';
      }
      else{
        return 'YYYY/M/dd';
      }
    } else {
      if(this.displayType === DisplayType.DateTime) {
        return 'dd/M/YYYY HH:mm';
      }
      else{
        return 'dd/M/YYYY';
      }
    }
    return '';
  });
}
