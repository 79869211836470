import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StructEntityType } from '@struct/models/struct/app/domain/shared/struct-entity-type';

@Component({
    selector: 'struct-entity-type-picker',
    templateUrl: './struct-entity-type-picker.component.html',
    styleUrls: ['./struct-entity-type-picker.component.scss'],
    standalone: false
})
export class StructEntityTypePickerComponent {
  @Input() value: StructEntityType | null = null;
  @Input() required: boolean = false;
  @Input() multiple: boolean = false;
  @Input() disabled: boolean = false;
  @Input({required:true}) name: string = "entityPicker";
  @Output() valueChange: EventEmitter<StructEntityType>= new EventEmitter<StructEntityType>();

  entityTypes = [
    { id: StructEntityType[StructEntityType.Product], text: 'Product' },
    { id: StructEntityType[StructEntityType.Variant], text: 'Variant' },
    { id: StructEntityType[StructEntityType.VariantGroup], text: 'Variant group' },
    { id: StructEntityType[StructEntityType.Category], text: 'Category' },
  ];

  valueChanged(event: any){
    this.valueChange.emit(event?.toString());
  }
}
