import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'accounts-unauthorized',
    imports: [CommonModule],
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {}
