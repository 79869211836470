import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { OperationType } from '@struct/models/struct/app/domain/bulkupdate/operation-type';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'struct-operation-type-picker',
    templateUrl: './struct-operation-type-picker.component.html',
    styleUrls: ['./struct-operation-type-picker.component.scss'],
    standalone: false
})
export class StructOperationTypePickerComponent implements OnChanges, OnInit {
  @Input() multiple: boolean = false;
  @Input() value: OperationType| null = null;
  @Input() required: boolean = false;
  @Output() valueChange: EventEmitter<OperationType>= new EventEmitter<OperationType>();
  @Input({required: true}) name = 'OperationType';
  @Input() placeholder: string = "placeholder";
  @Input() supportedOperationTypes: OperationType[] = [OperationType.OverrideValue, OperationType.PlusOrMinus, OperationType.Replace, OperationType.Add, OperationType.Remove, OperationType.StringReplace, OperationType.Multiply, OperationType.Divide]

  operationTypes: { key: string, value: string }[] = []

  ngOnChanges(changes: SimpleChanges) {
    if (changes) { // also add this check 
      this.operationTypes = this.convertEnumToArray(OperationType);
    }
  }

convertEnumToArray(enumObject: any): { key: string, value: string }[]{

  const enums: { key: string, value: string }[]=[] as { key: string, value: string }[];
  Object.keys(enumObject).forEach(k=>{
    if (typeof enumObject[k] === "string") {
      if(this.supportedOperationTypes.findIndex(t => t === enumObject[k]) > -1) {
        this.tr.get(["General.Enums.OperationTypes." + enumObject[k]]).subscribe(t => {        
          // Add keys and values to array if supported
          enums.push({key: enumObject[k], value: t["General.Enums.OperationTypes." + enumObject[k]]})
        });
      }
    }
  });
  return enums;
}


  constructor(private tr: TranslateService, cdr: ChangeDetectorRef){
  }
  ngOnInit(): void {
    this.operationTypes = this.convertEnumToArray(OperationType);
  }

  operationTypeChanged(event: any){
    this.valueChange.emit(event);
  }
}


