import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
    selector: 'struct-checkbox',
    templateUrl: './struct-checkbox.component.html',
    styleUrls: ['./struct-checkbox.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: false
})
export class StructCheckboxComponent {
  @Input() disabled: boolean = false;
  @Input() value: boolean | null = null;
  @Input() indeterminate: boolean | null = null;
  @Input() checked = false;
  @Output() valueChange = new EventEmitter<boolean>();
  random: string;

  constructor() {
    this.random = this.makeRandom();
  }

  onNgModelChange(value: boolean): void {
    this.valueChange.emit(value);
  }

  makeRandom() {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-';
    let text = '';
    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
}
