import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@struct/ui/ui.module';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { RegisterUserComponent } from '../register-user/register-user.component';
import { UsersApiService } from '@struct/services/account-management';
import { CommandError } from '@struct/models/struct/shared/models';
import { ConfirmEmailCommand } from '@struct/models/struct/usermanagementmodule/commands/confirm-email-command';

@Component({
    selector: 'accounts-verify-email',
    imports: [CommonModule, UiModule, TranslateModule, RouterModule],
    templateUrl: './verify-email.component.html'
})
export class VerifyEmailComponent implements OnInit {
  verificationSucceeded: boolean = false;
  verificationErrors: CommandError[] | null = null;
  loaded = false;

  constructor(private usersApi: UsersApiService, private route : ActivatedRoute){}

  ngOnInit(): void {
    const token = this.route.snapshot.queryParamMap.get('token') ?? "";
    const email = this.route.snapshot.queryParamMap.get('email') ?? "";
    this.usersApi.confirmEmail(new ConfirmEmailCommand({email: email, token: token})).subscribe((x) => {
      if(x.succeeded){
        this.verificationSucceeded = true;
      }
      else{
        this.verificationErrors = x.errors;
      }
      this.loaded = true;
    });
  }
}
