import { determineQueryOperator, mapToQueryOperator, QueryOperatorSplit } from '../struct-search-models';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ReplaySubject, Subject, takeUntil, tap } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TableColumn } from '../models/table-column';
import {
  DateQueryOperator,
  NumberQueryOperator,
  TextQueryOperator
} from "@struct/models/struct/shared/search/datafieldqueries";
import {BooleanOperator} from "@struct/models/struct/shared/search";
const escape = (str: string) => str.replace(/[!".*+?^${}()|[\]\\]/g, '\\$&');

@Component({
    template: '',
    standalone: false
})
export abstract class StructColumnHeaderComponent<T extends TextQueryOperator | NumberQueryOperator | DateQueryOperator | BooleanOperator> implements OnInit, OnDestroy {
  @Input() column!: TableColumn;
  @Output() searchChanged = new EventEmitter<boolean>();
  @Output() selectionChanged = new EventEmitter();

  floatLabelValue = true;
  focusState = false;
  private searchColumnChanged = new Subject<string>();
  private destroyed$ = new ReplaySubject();

  ngOnInit() {
    this.searchColumnChanged
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(200),
        tap(() => {
          this.searchChanged.emit(true);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  handleColumnOperatorChange(queryOperator: T) {
    this.setColumnQueryOperator(queryOperator);
    const queryOperatorSplit = mapToQueryOperator(queryOperator);
    this.column.operator = queryOperatorSplit?.prefix ?? null;
  }

  private removePrefixSuffix(str: string, queryOperatorSplit: QueryOperatorSplit): string {
    const prefix = new RegExp(`^${escape(queryOperatorSplit.prefix)}`);
    str = str.replace(prefix, '');
    const escapedSuffix = escape(queryOperatorSplit.suffix);
    const regex = new RegExp(escapedSuffix + '(?!.*' + escapedSuffix + ')', 'g');
    str = str.replace(regex, '');
    return str;
  }
  onValueChanged(event: string) {
    this.column.searchText = event;
    const str = (this.column.operator ?? '') + event;
    const queryOperator = determineQueryOperator(str) as T;
    if (queryOperator) {
      this.setColumnQueryOperator(queryOperator);
      const queryOperatorSplit = mapToQueryOperator(queryOperator);
      if (queryOperatorSplit !== null) {
        this.cdr.detectChanges();
        this.column.operator = queryOperatorSplit?.prefix;
        this.column.searchText = this.removePrefixSuffix(str, queryOperatorSplit);
        this.cdr.markForCheck();
      }
    } else if (!queryOperator) {
      this.setColumnQueryOperator(this.getDefaultQueryOperator());
    }
  }

  onKeyDown($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      $event.preventDefault();
    }
    if ($event.key === 'Delete' || ($event.key === 'Backspace' && this.column.searchText === '')) {
      this.setColumnQueryOperator(this.getDefaultQueryOperator());
      this.column.operator = null;
    }
    this.searchColumnChanged.next('');
  }

  onSearchColumnChange() {
    this.searchColumnChanged.next('');
  }

  abstract getDefaultQueryOperator(): T;

  abstract getCurrentColumOperator(): T;

  abstract setColumnQueryOperator(queryOperator: T): void;

  abstract shouldFloatLabelValue(queryOperator: T): boolean;
  abstract get cdr(): ChangeDetectorRef;
}
