import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'struct-flag-icon-button',
    templateUrl: './struct-flag-icon-button.component.html',
    styleUrls: ['./struct-flag-icon-button.component.scss'],
    standalone: false
})
export class StructFlagIconButtonComponent {
  @Input() countryCode: string | null = null;
  @Input() disabled: boolean;
  @Input() buttonType: string;
  @Input() iconSize: string;

  @Output() clicked = new EventEmitter();

  constructor() {
    this.buttonType = 'button';
    this.iconSize = '16';
    this.disabled = false;
  }
}
