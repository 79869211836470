import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UiModule } from '@struct/ui/ui.module';
import { Router, RouterModule } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { of, switchMap } from 'rxjs';
import { StructNotificationService } from '@struct/ui/struct-notification';
import { environment } from 'apps/accounts/src/environments/environment';

@Component({
    selector: 'accounts-sign-in-with-microsoft-callback',
    imports: [CommonModule, UiModule, TranslateModule, RouterModule],
    templateUrl: './sign-in-with-microsoft-callback.component.html'
})
export class SignInWithMicrosoftCallbackComponent implements OnInit {
  private tenant: string | null = null;
  errorMsg: string | undefined = undefined;
  loading = true;
  showError = false;

  constructor(private authService: AuthenticationService, private tr: TranslateService, private notificationService: StructNotificationService, private router: Router) {
  }

  ngOnInit(): void {
    this.authService
      .handleEntraIdLoginCallback()
      .pipe(
        switchMap(authResult => {
          if (authResult.success) {
            this.tenant = authResult.tenant();

            return this.authService.signInWithMicrosoft(authResult.accessToken);
          } else {
            return of(null);
          }
        })
      )
      .subscribe((response) => {
        if(response?.isSuccess){
          if(this.tenant === null || this.tenant === undefined || this.tenant === '') {
            window.location.href = environment.accountAppUrl;
          }
          else{
            window.location.href = environment.tenantAppUrl.replace('{tenant}', this.tenant);
          }
        }
        else{
          this.showError = true;
          this.errorMsg = response?.messageKey;
        }
        this.loading = false;
      });
  }
}
