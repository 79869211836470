import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SignInRequest } from '@struct/models/struct/authenticationmodule/models/sign-in-request';
import { SignInResponse } from '@struct/models/struct/authenticationmodule/models/sign-in-response';
import { Observable } from 'rxjs';

@Component({
    selector: 'struct-sign-in',
    templateUrl: './struct-sign-in.component.html',
    styleUrls: ['./struct-sign-in.component.scss'],
    standalone: false
})
export class StructSignInComponent implements OnInit {
  @Input({ required: true }) signInService!: UiSignInContract;
  loginForm!: FormGroup;
  working = false;
  showMarketing = true;
  authFailed: boolean = false;
  errorMessageKey: string | null = null;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.authFailed = false;
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  signInUsingMicrosoft(){
    this.signInService.signInUsingMicrosoft();
  }

  signIn() {
    this.working = true;
    if (!this.loginForm.valid) {
      return;
    }
    const email = this.loginForm.get('email')?.value;
    const password = this.loginForm.get('password')?.value;
    const model = new SignInRequest({ email: email, password: password });

    this.signInService.signIn(model).subscribe(response => {
      this.working = false;
        if (!response.isSuccess) {
          this.authFailed = true;
          this.errorMessageKey = response.messageKey;
          console.log(this.errorMessageKey);
        }
      });
  }

  toggleMarketingColumn() {
    this.showMarketing = !this.showMarketing;
  }
}

export interface UiSignInContract {
  signIn(request: SignInRequest): Observable<SignInResponse>;
  signInUsingMicrosoft(): void;
}
