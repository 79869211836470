
// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject,Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DeleteAssetsCommand } from "@struct/models/struct/app/domain/assets/commands/asset/delete-assets-command";
import { DeleteAssetsCommandResponse } from "@struct/models/struct/app/domain/assets/commands/asset/delete-assets-command-response";
import { MoveAssetsCommand } from "@struct/models/struct/app/domain/assets/commands/asset/move-assets-command";
import { SetAssetsTagsCommand } from "@struct/models/struct/app/domain/assets/commands/asset/set-assets-tags-command";
import { Asset } from "@struct/models/struct/app/domain/assets/asset";
import { AssetFile } from "@struct/models/struct/app/domain/assets/asset-file";
import { AssetEditorModel } from "@struct/models/struct/app/domain/editor/asset-editor-model";
import { FilenameToAssetModel } from "@struct/models/struct/app/frontendapi/models/assets/filename-to-asset-model";
import { FindExistingAssetsByFilenameModel } from "@struct/models/struct/app/frontendapi/models/assets/find-existing-assets-by-filename-model";
import { SaveTabModel } from "@struct/models/struct/app/frontendapi/models/editormodel/save-tab-model";
import { StructEntityUpdatedModel } from "@struct/models/struct/app/frontendapi/models/editormodel/struct-entity-updated-model";

@Injectable({ providedIn: "root" })
export class AssetsApiService {
 
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    withCredentials: true
  };

  constructor(private http: HttpClient, @Inject('frontEndApiUrl') private apiUrl: string) { }


  getEditorModel(assetId: number): Observable<AssetEditorModel> {
    return this.http.get<AssetEditorModel>(`${this.apiUrl}assets/${assetId}`, this.httpOptions );
  }

  saveTab(model: SaveTabModel): Observable<StructEntityUpdatedModel> {
    return this.http.patch<StructEntityUpdatedModel>(`${this.apiUrl}assets/savetab`, model, this.httpOptions);
  }

  findExistingAssetsByFilename(model: FindExistingAssetsByFilenameModel): Observable<FilenameToAssetModel[]> {
    return this.http.post<FilenameToAssetModel[]>(`${this.apiUrl}assets/findExistingAssetsByFilename`, model, this.httpOptions);
  }

  uploadAssetVersion(assetUid: string): Observable<AssetFile> {
    return this.http.post<AssetFile>(`${this.apiUrl}assets/uploadnewversion?assetUid=${assetUid}`, null, this.httpOptions);
  }

  uploadAsset(assetTypeUid: string, folderUid: string, replaceExisting: boolean): Observable<Asset> {
    return this.http.post<Asset>(`${this.apiUrl}assets/upload?assetTypeUid=${assetTypeUid}&folderUid=${folderUid}&replaceExisting=${replaceExisting}`, null, this.httpOptions);
  }

  getAssetReferences(assetUids: string[]): Observable<Asset[]> {
    return this.http.post<Asset[]>(`${this.apiUrl}assets/batch/references`, assetUids, this.httpOptions);
  }

  deleteAssets(model: DeleteAssetsCommand): Observable<DeleteAssetsCommandResponse> {
    return this.http.post<DeleteAssetsCommandResponse>(`${this.apiUrl}assets/delete`, model, this.httpOptions);
  }

  getAssetTags(assetUid: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}assets/tags/${assetUid}`, this.httpOptions );
  }

  getAssetsTags(assetUid: string[]): Observable<{ [key: string]: string[] }> {
    return this.http.get<{ [key: string]: string[] }>(`${this.apiUrl}assets/batch/tags/${assetUid}`, this.httpOptions );
  }

  getAssetsTagsById(assetIds: number[]): Observable<{ [key: string]: string[] }> {
    return this.http.post<{ [key: string]: string[] }>(`${this.apiUrl}assets/tagsById/${assetIds}`, assetIds, this.httpOptions);
  }

  getAllTags(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}assets/tags/`, this.httpOptions );
  }

  setAssetTags(command: SetAssetsTagsCommand): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}assets/tags`, command, this.httpOptions);
  }

  moveAssets(command: MoveAssetsCommand): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}assets/move`, command, this.httpOptions);
  }
}

