import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { UiModule } from '@struct/ui/ui.module';
import { AuthenticationService } from '../../authentication/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { AccountsHeaderComponent } from "../../accounts-ui/accounts-header/accounts-header.component";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StructNotificationService } from '@struct/ui/struct-notification';
import { SubscriptionUserInviteCommand } from "@struct/models/accountmanagement/domain/subscriptions/commands/subscription-user-invite-command";

import { of } from 'rxjs';
import { SubscriptionApiService } from '@struct/services/account-management/subscription-api.service';
@Component({
  selector: 'accounts-invite-user-subsscription-dialog',
  templateUrl: './add-user-to-subscription-dialog.component.html',
  imports: [
    CommonModule,
    UiModule,
    AccountsHeaderComponent,
  ],
})
export class InviteUserSubscriptionDialogComponent implements OnInit {
  working = false;
  command = new SubscriptionUserInviteCommand();
  subscriptionsRoles: SubscriptionRole[] = [];


  @ViewChild('inviteform') inviteForm: NgForm | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private authService: AuthenticationService,
     private currentRoute: ActivatedRoute,
     private dialogRef: MatDialogRef<InviteUserSubscriptionDialogComponent>,
     private subscriptionApi: SubscriptionApiService,
     private notificationService: StructNotificationService
     ) {}

  ngOnInit(): void {
      this.subscriptionsRoles = [
        new SubscriptionRole({ id: 'Owner', name: 'Owner' }),
        new SubscriptionRole({ id: 'Manager', name: 'Manager' })
      ];
      this.command.subscriptionUid =this.data;

      // this.authService.getAuthenticatedUser().subscribe(user => {
      //   this.command.invitedBy = user?.email ?? '';
      // });
  }

  sendInvite(): void {
    //this.dialogRef.close();
    this.working = true;


    this.subscriptionApi.inviteUserToSubScription(this.command).subscribe((response) => {
    //   this.working = false;
          if(!response.succeeded) {
            this.notificationService.showErrorNotification(of(response.errors[0].message));
          } else {
            this.dialogRef.close();
          }

    });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}


export class SubscriptionRole {
    id!: string ;
    name!: string;

    constructor(data?: Partial<SubscriptionRole>) {
      Object.assign(this, data);
    }
  }
