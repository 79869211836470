import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiModule } from '@struct/ui/ui.module';

@Component({
    selector: 'accounts-list-item',
    templateUrl: './accounts-list-item.component.html',
    styleUrls: ['./accounts-list-item.component.scss'],
    imports: [CommonModule, RouterModule, UiModule]
})
export class AccountsListItemComponent {
  
  @Input()
  iconName : string | null = null;

  @Input()
  routerLink : string | null = null;
}
