import {Component, Inject} from '@angular/core';

import {NotificationData} from "@struct/ui/struct-notification/models/notification-data";
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";


@Component({
    selector: 'struct-info-notification',
    templateUrl: './info-notification.component.html',
    styleUrls: ['./info-notification.component.scss'],
    standalone: false
})
export class InfoNotificationComponent {
  constructor(
    public snackbarRef: MatSnackBarRef<InfoNotificationComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: NotificationData
  ) {
  }
}
