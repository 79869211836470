// 
// This file is auto generated by NTypewriter
// ==========================================
// Do not manually modify this file directly.
//
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeploymentInfo } from "@struct/models/accountmanagement/domain/tenants/deployment-info";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICreateTenantCommand  {
  uid: string;
  subscriptionUid: string;
  name: string;
  slug: string;
  isEarlyAdopter: boolean;
  deploymentInfo: DeploymentInfo;
}

export class CreateTenantCommand  implements ICreateTenantCommand {
  uid = "00000000-0000-0000-0000-000000000000";

  subscriptionUid = "00000000-0000-0000-0000-000000000000";

  name = "";

  slug = "";

  isEarlyAdopter = false;

  deploymentInfo: DeploymentInfo = new DeploymentInfo();


  constructor(data?: Partial<ICreateTenantCommand>) {
    Object.assign(this, data);
  }
}