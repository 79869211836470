import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
    selector: 'struct-datepicker',
    templateUrl: './struct-datepicker.component.html',
    styleUrls: ['./struct-datepicker.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: false
})
export class StructDatepickerComponent {
  @Input() required: boolean;
  @Input() name: string | null = null;
  @Input() placeholder: string | null = null;
  @Input() hintStart: string | null = null;
  @Input() hintEnd: string | null = null;
  @Input() disabled: boolean | string;
  @Input() value: string | Date | null = null;
  @Output() valueChange = new EventEmitter();

  random: string;

  constructor() {
    this.required = false;
    this.disabled = false;
    this.random = this.makeRandom();
  }

  makeRandom() {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-';
    let text = '';
    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  onNgModelChange(value: string | null) {
    this.valueChange.emit(value);
  }
}
