<div class="w-64 md:w-72">
  <!-- Sidebar component, swap this element with another sidebar if you like -->
  <div class="flex flex-col flex-grow pt-5 bg-white overflow-y-auto">
    <div class="flex">
      <div class="flex-grow flex items-center flex-shrink-0 px-6">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40" class="w-6 h-6 text-blue-200">
          <g>
            <path class="text-blue-300" fill="currentColor" d="M6.9,26.3V15.1l-6.9-4v15.3c0.1,2.7,1.9,5,4.3,6.5L16.5,40v-8L6.9,26.3z"></path>
            <path class="text-blue-500" fill="currentColor" d="M18.2,6.7l9.6,5.6l6.9-4L22.5,1.2C19.9-0.3,16.6-0.4,14,1L1.6,8.2l6.9,4L18.2,6.7z"></path>
            <path class="text-blue-400" fill="currentColor" d="M29.5,15.1v11.2l-9.6,5.6v8l12.2-7.1c2.6-1.5,4.4-4.2,4.4-7V11.1L29.5,15.1z"></path>
          </g>
        </svg>
        <span class="ml-2 text-sm font-medium">Struct</span>
      </div>
      <div class="flex-grow-0 mr-6 md:hidden">
        <struct-icon-button (click)="closeSideNav()" icon="close"></struct-icon-button>
      </div>
    </div>
    <div class="mt-5 flex flex-col">
      <div class="px-5">
        <div class="flex items-center">
          <struct-avatar class="pointer-events-none" size="small" name="{{user?.firstName + ' ' + user?.lastName}}"></struct-avatar>
          <div class="flex flex-col ml-3">
            <p class="text-lg font-medium text-gray-800 leading-4">{{user?.firstName + ' ' + user?.lastName}}</p>
            <p class="text-sm text-gray-400">{{user?.organization}}</p>
          </div>
        </div>
      </div>
      <hr class="border-gray-100 my-8" />
      <nav class="flex-1 pb-4 flex flex-col gap-1">


        <a
          routerLink="subscription-plans"
          (click)="closeSideNav()"
          routerLinkActive="is-active"
          class="struct-navbar-item">
          <struct-icon icon="subscription_plans" size="14" class="mr-3 ml-5"></struct-icon>
          Subscription plans
        </a>

        <a
          routerLink="subscriptions"
          (click)="closeSideNav()"
          routerLinkActive="is-active"
          class="struct-navbar-item">
          <struct-icon icon="subscriptions" size="14" class="mr-3 ml-5"></struct-icon>
          Subscriptions
        </a>

        <a
          routerLink="users"
          (click)="closeSideNav()"
          routerLinkActive="is-active"
          class="struct-navbar-item">
          <struct-icon icon="users" size="14" class="mr-3 ml-5"></struct-icon>
          Users
        </a>

        <a
          routerLink="databasetemplates"
          (click)="closeSideNav()"
          routerLinkActive="is-active"
          class="struct-navbar-item">
          <struct-icon icon="database" size="14" class="mr-3 ml-5"></struct-icon>
          Database templates
        </a>

        <a
          routerLink="tenants"
          (click)="closeSideNav()"
          routerLinkActive="is-active"
          class="struct-navbar-item">
          <struct-icon icon="tenants" size="14" class="mr-3 ml-5"></struct-icon>
          Tenants
        </a>

        <hr class="border-gray-100 my-8" />

        <a
        routerLink="organizations"
        (click)="closeSideNav()"
        routerLinkActive="is-active"
        class="struct-navbar-item">
        <struct-icon icon="tenants" size="14" class="mr-3 ml-5"></struct-icon>
        Organizations
      </a>

      <a
        routerLink="mytenants"
        (click)="closeSideNav()"
        routerLinkActive="is-active"
        class="struct-navbar-item">
        <struct-icon icon="tenants" size="14" class="mr-3 ml-5"></struct-icon>
        My Tenants
      </a>
      <a
        routerLink="myorganization"
        (click)="closeSideNav()"
        routerLinkActive="is-active"
        class="struct-navbar-item">
        <struct-icon icon="tenants" size="14" class="mr-3 ml-5"></struct-icon>
        My Organization
      </a>

        <a
          routerLink="profile"
          (click)="closeSideNav()"
          routerLinkActive="is-active"
          class="struct-navbar-item">
          <struct-icon icon="my_profile" size="14" class="mr-3 ml-5"></struct-icon>
          My profile
        </a>

        <a
          (click)="logout()"
          (click)="closeSideNav()"
          class="struct-navbar-item cursor-pointer">
          <struct-icon icon="log_out" size="14" class="mr-3 ml-5"></struct-icon>
          Log out
        </a>

        <!-- <span class="text-sm text-gray-300 px-8 py-2">PIM pages</span>

        <a
          routerLink="shell"
          (click)="closeSideNav()"
          routerLinkActive="struct-navbar-item-active border-blue-500 !text-blue-500 !hover:text-blue-900"
          class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-md font-medium border-r-2 border-transparent">
          <struct-icon icon="token" size="16" class="mr-3 ml-5"></struct-icon>
          Shell
        </a>

        <a
          routerLink="dashboard"
          (click)="closeSideNav()"
          routerLinkActive="struct-navbar-item-active border-blue-500 !text-blue-500 !hover:text-blue-900"
          class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-md font-medium border-r-2 border-transparent">
          <struct-icon icon="token" size="16" class="mr-3 ml-5"></struct-icon>
          Dashboard
        </a>

        <a
          routerLink="search-page"
          (click)="closeSideNav()"
          routerLinkActive="struct-navbar-item-active border-blue-500 !text-blue-500 !hover:text-blue-900"
          class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-md font-medium border-r-2 border-transparent">
          <struct-icon icon="token" size="16" class="mr-3 ml-5"></struct-icon>
          Search page
        </a>

        <a
          routerLink="list-page"
          (click)="closeSideNav()"
          routerLinkActive="struct-navbar-item-active border-blue-500 !text-blue-500 !hover:text-blue-900"
          class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-md font-medium border-r-2 border-transparent">
          <struct-icon icon="token" size="16" class="mr-3 ml-5"></struct-icon>
          List page
        </a>

        <a
          routerLink="product-page"
          (click)="closeSideNav()"
          routerLinkActive="struct-navbar-item-active border-blue-500 !text-blue-500 !hover:text-blue-900"
          class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-md font-medium border-r-2 border-transparent">
          <struct-icon icon="token" size="16" class="mr-3 ml-5"></struct-icon>
          Product page
        </a>

        <a
          routerLink="import-page"
          (click)="closeSideNav()"
          routerLinkActive="struct-navbar-item-active border-blue-500 !text-blue-500 !hover:text-blue-900"
          class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-md font-medium border-r-2 border-transparent">
          <struct-icon icon="token" size="16" class="mr-3 ml-5"></struct-icon>
          Import page
        </a>

        <a
          routerLink="data-supplier-onboarding"
          (click)="closeSideNav()"
          routerLinkActive="struct-navbar-item-active border-blue-500 !text-blue-500 !hover:text-blue-900"
          class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-md font-medium border-r-2 border-transparent">
          <struct-icon icon="token" size="16" class="mr-3 ml-5"></struct-icon>
          Data Supplier Onboarding
        </a>

        <a
          routerLink="attributes/create"
          (click)="closeSideNav()"
          routerLinkActive="struct-navbar-item-active border-blue-500 !text-blue-500 !hover:text-blue-900"
          class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-md font-medium border-r-2 border-transparent">
          <struct-icon icon="token" size="16" class="mr-3 ml-5"></struct-icon>
          Attributes -> create
        </a>

        <a
          routerLink="product-model-setup/product-structures"
          (click)="closeSideNav()"
          routerLinkActive="struct-navbar-item-active border-blue-500 !text-blue-500 !hover:text-blue-900"
          class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-md font-medium border-r-2 border-transparent"
        >
          <struct-icon icon="token" size="16" class="mr-3 ml-5"></struct-icon>
          Product model setup -> product structures
        </a>

        <a
          routerLink="settings"
          (click)="closeSideNav()"
          routerLinkActive="struct-navbar-item-active border-blue-500 !text-blue-500 !hover:text-blue-900"
          class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-md font-medium border-r-2 border-transparent">
          <struct-icon icon="token" size="16" class="mr-3 ml-5"></struct-icon>
          Settings
        </a>-->
      </nav>
    </div>
  </div>
</div>
