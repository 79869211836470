import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { UiModule } from "@struct/ui/ui.module";
import { AccountsHeaderComponent } from "../../accounts-ui/accounts-header/accounts-header.component";
import { AccountsFormComponent } from "../../accounts-ui/accounts-form/accounts-form/accounts-form.component";
import { AccountsFormSectionComponent } from "../../accounts-ui/accounts-form/accounts-form-section/accounts-form-section.component";
import { AccountsFormSectionTitleComponent } from "../../accounts-ui/accounts-form/accounts-form-section-title/accounts-form-section-title.component";
import { AccountsFormSectionDescriptionComponent } from "../../accounts-ui/accounts-form/accounts-form-section-description/accounts-form-section-description.component";
import { AccountsFormSectionContentComponent } from "../../accounts-ui/accounts-form/accounts-form-section-content/accounts-form-section-content.component";
import { OrganizationsApiService } from "@struct/services/account-management";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Organization } from "@struct/models/struct/usermanagementmodule";
import { UpdateOrganizationCommand } from "@struct/models/accountmanagement/domain/organizations/commands/update-organization-command";
import { map, Observable, of, ReplaySubject, switchMap, takeUntil } from "rxjs";
import { ClientSideSearchDataProvider, ClientSideSearchTableDefinition, IRowAction, IRowSingleAction, ITableDefinition, RowActionResult, StructTableComponent, TableColumn } from "@struct/ui/struct-table";
import { OrganizationsDataProvider } from "../organizations/organizations-list.component";
import { DisplayType } from "@struct/models/struct/shared/search";
import { StructDialogConfig, StructDialogPosition, StructDialogWidth } from "@struct/ui/struct-dialog/StructDialogConfig";
import { InviteUserOrganizationDialogComponent } from "../invite-user-organization-dialog/invite-user-organization-dialog.component";
import { ConfirmDialogData, StructConfirmDialogService } from "@struct/ui/struct-confirm-dialog";
import { StructNotificationService } from "@struct/ui/struct-notification";
import { AuthenticationService } from "../../authentication/authentication.service";


@Component({
    selector: 'accounts-edit-organization',
    templateUrl: 'edit-organization.component.html',
    imports: [CommonModule, UiModule, RouterModule, AccountsHeaderComponent, AccountsFormComponent, AccountsFormSectionComponent, AccountsFormSectionTitleComponent, AccountsFormSectionDescriptionComponent, AccountsFormSectionContentComponent, AccountsFormSectionComponent]
})
  export class OrganizationsEditComponent implements OnInit, OnDestroy {
    //spinnerService: any;
    private destroyed$ = new ReplaySubject();
    tableDefinitionUsers: ITableDefinition | null = null;
    tableDefinitionInvites: ITableDefinition | null = null;
    tableDefinitionDatabase: ITableDefinition | null = null;

    tableScope = 'organizationusers';
    

    constructor(private organizationApi: OrganizationsApiService, private authService: AuthenticationService, private currentRoute: ActivatedRoute, private tr : TranslateService, private dialogService: MatDialog,private router : Router,private confirmDialog: StructConfirmDialogService,private notificationService: StructNotificationService) {}
    ngOnDestroy(): void {
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }
    ngOnInit(): void {
        const uid = this.currentRoute.snapshot.paramMap.get('uid');
        const rowActionsUsers: IRowAction[] = [];
        const rowActionsDatabase: IRowAction[] = [];
        const rowActionsInvites: IRowAction[] = [new CancelOrganizationInviteAction(this.confirmDialog, this.organizationApi, this.tr, this.notificationService), new ResendOrganizationInviteAction(this.confirmDialog, this.organizationApi, this.tr, this.notificationService)];


        
        const tableColumnsUsers = [
          new TableColumn({id: 'firstname', title: this.tr.instant('Organization.Firstname'), type: DisplayType.Text}),
          new TableColumn({id: 'lastname', title: this.tr.instant('Organization.Lastname'), type: DisplayType.Text}),
          new TableColumn({id: 'email', title: this.tr.instant('Organization.Email'), type: DisplayType.Text}),
          new TableColumn({id: 'role', title: this.tr.instant('Organization.Role'), type: DisplayType.Text}),
          new TableColumn({id: 'id', title: this.tr.instant('Organization.Id'), type: DisplayType.Text}),
        ];

        const tableColumnsInvites = [
          new TableColumn({id: 'email', title: this.tr.instant('Organization.Email'), type: DisplayType.Text}),
          new TableColumn({id: 'role', title: this.tr.instant('Organization.Role'), type: DisplayType.Text}),
          new TableColumn({id: 'uid', title: this.tr.instant('Organization.Id'), type: DisplayType.Text}),
        ];

        const tableColumnsDatabase = [
          new TableColumn({id: 'uid', title: 'Uid', type: DisplayType.Text}),
          new TableColumn({id: 'created', title: this.tr.instant('DatabaseTemplate.Created'), type: DisplayType.Date}),
          new TableColumn({id: 'createdBy', title: this.tr.instant('DatabaseTemplate.CreatedBy'), type: DisplayType.Text}),
          new TableColumn({id: 'name', title: this.tr.instant('DatabaseTemplate.Name'), type: DisplayType.Text}),
          new TableColumn({id: 'status', title: this.tr.instant('DatabaseTemplate.Status'), type: DisplayType.FixedList}),
          new TableColumn({id: 'description', title: this.tr.instant('DatabaseTemplate.Description'), type: DisplayType.Text}),
        ];

        if (uid !== null) {
            this.organizationApi
              .getOrganizationByUid(uid)
              .pipe(
                takeUntil(this.destroyed$),
                map(data => {
                  this.organization = data;
                })
              )
              .subscribe(() => {
                    const dataProviderUsers = new OrganizationUsersDataProvider(this.organizationApi, this.organization.uid);
                    this.tableDefinitionUsers = new ClientSideSearchTableDefinition('organizationUsers', tableColumnsUsers, rowActionsUsers, 'id', 'organizations', null, dataProviderUsers);
                  
                    const dataProviderInvites = new OrganizationUserInvitesDataProvider(this.organizationApi, this.organization.uid);
                    this.tableDefinitionInvites = new ClientSideSearchTableDefinition('organizationInvites', tableColumnsInvites, rowActionsInvites, 'uid', 'organizations', null, dataProviderInvites);
                    
                    const dataProviderDatabase = new OrganizationDatabaseTemplateDataOrgProvider(this.organizationApi, this.organization.uid);
                    this.tableDefinitionDatabase = new ClientSideSearchTableDefinition('organizationDatabase', tableColumnsDatabase, rowActionsDatabase, 'uid', 'organizations', null, dataProviderDatabase);

                  }
              );
          }

    }
    @ViewChild('organizationForm') organizationForm!: NgForm;
    organization: Organization = new Organization();
    showError = false;

    

    submitOrganization() {
        if (this.organizationForm.valid) {
            //this.spinnerService.changeShowSpinner(true);

            const command = new UpdateOrganizationCommand({
                name: this.organization.name,
                uid: this.organization.uid
            });

            this.organizationApi
            .updateOrganization(command)
            .subscribe(() => {
                //this.spinnerService.changeShowSpinner(false);
                this.router.navigateByUrl('/organizations');
            });
           
          } else {
            for (const i in this.organizationForm.controls) {
              this.organizationForm.controls[i].markAsTouched();
              this.showError = true;
            }
          }
    } 

    inviteUser(): void {
      //let origId = this.currentRoute.snapshot.paramMap.get('uid');
      const data = this.currentRoute.snapshot.paramMap.get('uid');

      const dialogConfig = new StructDialogConfig();
      dialogConfig.position = StructDialogPosition.Center;
      dialogConfig.width = StructDialogWidth.Quarter;

      const config = new MatDialogConfig();
      config.width = '500px';
      config.data = data;
      config.height = '500px';
      this.dialogService.open(InviteUserOrganizationDialogComponent, config).afterClosed().subscribe(x => console.log(x));
    }
  }


  export class ResendOrganizationInviteAction implements IRowSingleAction {
    name = 'Resend invite';
    icon = 'edit';
  
    constructor(
      private confirmDialogService: StructConfirmDialogService,
      private organizationApi: OrganizationsApiService,
      private tr: TranslateService,
      private notificationService: StructNotificationService
    ){}

    onClick(rowId: string, table: StructTableComponent): Observable<RowActionResult> {
      return this.confirmDialogService.open(<ConfirmDialogData>{ description: this.tr.get('Organization.ConfirmResendInvite') }).pipe(
        switchMap(t => {
          if (t.confirmed) {
            table.working = true;
            return this.organizationApi.resendOrganizationInvite(rowId).pipe(
              map(() => {
                //this.notificationService.showSuccessNotification(this.tr.get('DataSupplier.Settings.AttributeTemplates.AttributeTemplateDeletedSuccess'));
                return new RowActionResult(true);
              })
            );
          } else {
            return of(new RowActionResult(false));
          }
        })
      );
    }
  }


  export class CancelOrganizationInviteAction implements IRowSingleAction {
    name = 'Cancel invite';
    icon = 'delete';
  
    constructor(
      private confirmDialogService: StructConfirmDialogService,
      private organizationApi: OrganizationsApiService,
      private tr: TranslateService,
      private notificationService: StructNotificationService
    ){}

    onClick(rowId: string, table: StructTableComponent): Observable<RowActionResult> {
      return this.confirmDialogService.open(<ConfirmDialogData>{ description: this.tr.get('Organization.ConfirmCancelInvite') }).pipe(
        switchMap(t => {
          if (t.confirmed) {
            table.working = true;
            return this.organizationApi.cancelOrganizationInvite(rowId).pipe(
              map(() => {
                //this.notificationService.showSuccessNotification(this.tr.get('DataSupplier.Settings.AttributeTemplates.AttributeTemplateDeletedSuccess'));
                return new RowActionResult(true);
              })
            );
          } else {
            return of(new RowActionResult(false));
          }
        })
      );
    }
  }

  export class OrganizationUsersDataProvider implements ClientSideSearchDataProvider {
    constructor(private organizationApi: OrganizationsApiService, private orgUid: string) {
    }
  
    getData(): Observable<any[]> {
      return this.organizationApi.getOrganizationUsers(this.orgUid);
    }
  }

  export class OrganizationUserInvitesDataProvider implements ClientSideSearchDataProvider {
    constructor(private organizationApi: OrganizationsApiService, private orgUid: string) {
    }
  
    getData(): Observable<any[]> {
      return this.organizationApi.getOrganizationUserInvites(this.orgUid);
    }
  }

  export class OrganizationDatabaseTemplateDataOrgProvider implements ClientSideSearchDataProvider {
    constructor(private organizationApi: OrganizationsApiService, private orgUid: string) {
    }
  
    getData(): Observable<any[]> {
      return this.organizationApi.getOrganizationDatabaseTemplatesByOrgUid(this.orgUid);
    }
  }