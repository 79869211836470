import { Component } from '@angular/core';

@Component({
    selector: 'struct-input-prefix',
    templateUrl: './struct-input-prefix.component.html',
    styleUrls: ['./struct-input-prefix.component.scss'],
    standalone: false
})
export class StructInputPrefixComponent  {  
}
