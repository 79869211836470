import { Component, Input } from '@angular/core';

@Component({
    selector: 'struct-app-content-footer',
    templateUrl: './struct-app-content-footer.component.html',
    styleUrls: ['./struct-app-content-footer.component.scss'],
    standalone: false
})
export class StructAppContentFooterComponent {
  @Input() isActive = true;
  @Input() showBorder = true;
}
