import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, of, takeUntil } from 'rxjs';
import { NgForm } from '@angular/forms';
import { TenantDetailsComponent } from '../tenant-details/tenant-details.component';
import { ConfirmDialogData, ConfirmDialogResult, StructConfirmDialogComponent } from '@struct/ui/struct-confirm-dialog/struct-confirm-dialog.component';
import { StructSpinnerService } from '@struct/ui/struct-spinner/struct-spinner.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { UiModule } from '@struct/ui/ui.module';
import { AccountsHeaderComponent } from '../../accounts-ui/accounts-header/accounts-header.component';
import { AccountsFormDangerZoneComponent } from '../../accounts-ui/accounts-form/accounts-form-danger-zone/accounts-form-danger-zone.component';
import {TenantApiService} from "@struct/services/account-management/tenant-api.service";
import {Tenant} from "@struct/models/accountmanagement/domain/tenants";
import { UpdateTenantCommand } from '@struct/models/accountmanagement/domain/tenants/commands/update-tenant-command';

@Component({
    selector: 'accounts-edit-tenant',
    templateUrl: './edit-tenant.component.html',
    styleUrls: ['./edit-tenant.component.scss'],
    imports: [CommonModule, UiModule, AccountsHeaderComponent, TenantDetailsComponent]
})
export class EditTenantComponent implements OnInit, OnDestroy {
  private destroyed$ = new ReplaySubject();
  tenant: Tenant = new Tenant();
  tenantName: string | null = null;
  showError = false;
  loaded: boolean = false;

  @ViewChild('tenantform') tenantform: NgForm | null = null;
  @ViewChild('tenantDetails') subscriptionDetailsComponent: TenantDetailsComponent | null = null;

  constructor(private tenantApi: TenantApiService, private currentRoute: ActivatedRoute, private router: Router, private dialog: MatDialog, private spinnerService: StructSpinnerService) {}

  ngOnInit(): void {
    const uid = this.currentRoute.snapshot.paramMap.get('uid');
    if (uid !== null) {
      this.tenantApi
        .getTenant(uid)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(tenant => {
          this.tenant = tenant;
          this.spinnerService.changeShowSpinner(false);
          this.loaded = true;
        });
    }
  }
  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  deleteTenant(): void {
    const dialogRef = this.dialog.open(StructConfirmDialogComponent, {
      data: <ConfirmDialogData>{
        userValueConfirm: true,
        userValueConfirmString: this.tenant?.name,
        description: of("Are you sure you want to delete this tenant?")
      },
    });

    dialogRef.afterClosed().subscribe((result: ConfirmDialogResult) => {
      if (result?.confirmed) {
        this.spinnerService.changeShowSpinner(true);
        const uid = this.currentRoute.snapshot.paramMap.get('uid');
        if (uid !== null) {
          this.tenantApi.deleteTenant(uid).subscribe(() => {
            this.spinnerService.changeShowSpinner(false);
            this.router.navigateByUrl('/tenants');
          });
        }
      }
    });
  }

  submitTenant(): void {

    const cmd = new UpdateTenantCommand({
        uid: this.tenant.uid,
        isEarlyAdopter: this.tenant.isEarlyAdopter,
        name: this.tenant.name,
        slug: this.tenant.slug,
    });

    this.spinnerService.changeShowSpinner(true);
    
    this.tenantApi.updateTenant(cmd).subscribe(c=> {

        this.spinnerService.changeShowSpinner(false);
        this.router.navigateByUrl('/tenants');
    });

    // if (this.tenantform && !this.tenantform.valid) {
    //   for (const i in this.tenantform.controls) {
    //     this.tenantform.controls[i].markAsTouched();
    //   }
    //   this.showError = true;
    // } else if (this.tenant) {
    //   this.spinnerService.changeShowSpinner(true);
    //   const cmd = new UpdateTenantCommand({
    //     uid: this.tenant.uid,
    //     name: this.tenant.name,
    //     slug: this.tenant.slug,
    //     status: this.tenant.status,
    //     stamp: this.tenant.stamp,
    //     subscriptionUid: this.tenant.subscriptionUid,
    //     authenticationProvider: this.tenant.authenticationProvider,
    //     applications: this.tenant.applications,
    //   });

    //   this.tenantApi
    //     .updateTenant(cmd)
    //     .pipe(takeUntil(this.destroyed$))
    //     .subscribe(() => {
    //       this.spinnerService.changeShowSpinner(false);
    //       this.router.navigateByUrl('/tenants');
    //     });
    // }
  }
}
