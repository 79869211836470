import { AfterViewInit, Directive, ElementRef } from '@angular/core';
 
@Directive({
    selector: '[structAutofocus]',
    standalone: false
})
export class AutofocusDirective implements AfterViewInit {
  constructor(private element: ElementRef<HTMLInputElement>) { }
 
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.element.nativeElement.focus();
    });
  }
}