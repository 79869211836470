import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommandResponse } from '@struct/models/struct/shared/models';
import { ForgotPasswordCommand } from '@struct/models/struct/usermanagementmodule/commands/forgot-password-command';
import { Observable } from 'rxjs';

@Component({
    selector: 'struct-forgot-password',
    templateUrl: './struct-forgot-password.component.html',
    styleUrls: ['./struct-forgot-password.component.scss'],
    standalone: false
})
export class StructForgotPasswordComponent {
  @Input({ required: true }) forgotPasswordService!: UiForgotPasswordContract;
  forgotPasswordForm: FormGroup
  errorMessageKey: string | null = null;
  showMarketing = true;
  showSuccess: boolean = false;
  showError: boolean = false;
  working = false;

  constructor() {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl("", [Validators.required])
    })
  }

  public hasError(controlName: string, errorName: string) : boolean {
    return this.forgotPasswordForm.get(controlName)?.hasError(errorName) ?? false;
  }

  public submit() : void {

    this.working = true;

    this.forgotPasswordService.forgotPassword(new ForgotPasswordCommand({email: this.forgotPasswordForm.value.email }))
    .subscribe(response => {
      if (response.succeeded) {
        this.showSuccess = true;
      } else {
        this.showError = true;
        this.errorMessageKey = response.errors[0].code;
      }
      this.working = false;
    });
  }

  toggleMarketingColumn() {
    this.showMarketing = !this.showMarketing;
  }
}

export interface UiForgotPasswordContract {
  forgotPassword(request: ForgotPasswordCommand): Observable<CommandResponse>;
}
