import { Directive, Output, EventEmitter, HostBinding, HostListener, ElementRef, ContentChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileHandle } from './FileHandle';
import { FileHelper } from './file-helper';

@Directive({
    selector: '[structFileDragAndDrop]',
    standalone: false
})
export class StructFileDragAndDropDirective {
  @HostBinding('class.drag-is-over') fileOver: boolean = false;
  @Output() fileDropped: EventEmitter<FileHandle[]> = new EventEmitter();
  timeoutNumber : number | undefined;
  @ContentChild("structFileDragAndDropContent") dragDropContent!  : ElementRef<HTMLElement>

  constructor(private sanitizer: DomSanitizer, private elm : ElementRef) {}


  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    clearTimeout(this.timeoutNumber);
    this.timeoutNumber = undefined;
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    
    if (this.timeoutNumber != undefined) {
      clearTimeout(this.timeoutNumber);
    }
    this.timeoutNumber = setTimeout(() => { 
      this.fileOver = false;
    }, 30);
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;

    const files: FileHandle[] = [];
    if (evt.dataTransfer != undefined) {

      for (let i = 0; i < evt.dataTransfer.files.length; i++) {
        const file = evt.dataTransfer.files[i];
        const fileHandle = FileHelper.getFileHandle(file, this.sanitizer);
        files.push(fileHandle);
      }

      if (files.length > 0) {
        this.fileDropped.emit(files);
      }
    }
  }
}
