import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BooleanOperator, SimpleQuery } from '@struct/models/struct/shared/search';
import { FieldQuery } from '@struct/models/struct/shared/search/datafieldqueries';
import { debounceTime, Subject, Subscription, tap } from 'rxjs';
import { TableColumn } from '../models';

@Component({
    template: '',
    standalone: false
})
export abstract class StructColumnHeaderBaseComponent implements OnInit, OnDestroy {
  @Input({ required:true }) column!: TableColumn;
  @Input() sortField: string | null = null;
  @Input() sortAsc: boolean | null = null;

  @Output() searchChanged = new EventEmitter<SimpleQuery | null>();
  @Output() sortingChanged = new EventEmitter<string>();
  
  private searchColumnChanged = new Subject<string>();
  private columnChangeSubscription : Subscription | null = null;

  ngOnInit() {
    
    this.mapQuery(this.column.query);

    this.columnChangeSubscription = this.searchColumnChanged
      .pipe(
        debounceTime(200),
        tap((searchText) => {
          this.column.query = this.buildQuery(searchText);
          this.searchChanged.emit(this.column.query);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.columnChangeSubscription?.unsubscribe();
  }
  
  onValueChanged(event: string | Event) {
    if(event instanceof Event){
      this.searchColumnChanged.next((event.target as HTMLInputElement).value);
      this.column.searchText = (event.target as HTMLInputElement).value;
    }
    else{
      this.searchColumnChanged.next(event);
      this.column.searchText = event;
    }
  }

  mapQuery(query: SimpleQuery | null){
    if(query === null){
      return;
    }

    const searchTexts: string[] = [];
    
    query.fieldQueries.forEach(x => {
      const uiText = this.getUiText(x);
      searchTexts.push(uiText);
    });

    this.column.searchText = query.booleanOperator === BooleanOperator.And ? searchTexts.join("&&") : searchTexts.join("||");
  }

  buildQuery(str: string | null | undefined): SimpleQuery | null {
    if (str === null || str === undefined || str.length === 0) {
      return null;
    }

    const result = new SimpleQuery();
    if (str.indexOf("&&") !== -1) {
      str.split("&&").forEach( (subval) => {
        const query = this.getFieldQuery(subval.trim());
        result.booleanOperator = BooleanOperator.And;
        if(query !== null)
          {
            result.fieldQueries.push(query);
          }
      });
    }
    else if (str.indexOf("||") !== -1) {
      str.split("||").forEach((subval) => {
        const query = this.getFieldQuery(subval.trim());
        result.booleanOperator = BooleanOperator.Or;
        if(query !== null)
        {
          result.fieldQueries.push(query);
        }
      });
    }
    else {
      const query = this.getFieldQuery(str);
      if(query != null)
      {
        result.fieldQueries.push(query);
      }
    }
    if(result.fieldQueries.length === 0){
      return null;
    }
    return result;
  }

  sortByMe(event: any): void {
    this.sortingChanged.emit(this.column.id);
    event.stopPropagation();
  }

  protected abstract getFieldQuery(str: string | null | undefined) : FieldQuery | null;
  protected abstract getUiText(query: FieldQuery) : string;
}
