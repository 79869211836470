<accounts-form *ngIf="subscription !== null">
  <accounts-form-section *ngIf="subscription.status !== null">
    <accounts-form-section-title> Subscription status </accounts-form-section-title>
    <accounts-form-section-description> Manage subscription status </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Subscription status
          </struct-form-row-headline>
          <struct-form-row-description>
            Subscription status description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-simple-select name="Subscription status" [(ngModel)]="subscription.status" bindValue="name" bindLabel="name" [required]="true" placeholder="Subscription status" [options]="subscriptionStatusses"></struct-simple-select>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Subscription plan
          </struct-form-row-headline>
          <struct-form-row-description> 
            Subscription plan this subscription is based on
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-simple-select name="Subscription plan" [(ngModel)]="subscription.subscriptionPlanUid" bindValue="uid" bindLabel="name" [required]="true" placeholder="Subscription plan" [options]="(subscriptionPlans$ | async) ?? []"></struct-simple-select>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Payment currency
          </struct-form-row-headline>
          <struct-form-row-description> 
            Currency the subscription is paid in
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-simple-select name="Payment currency" [(ngModel)]="subscription.paymentCurrency" bindValue="name" bindLabel="name" [required]="true" placeholder="Payment currency" [options]="currencies"></struct-simple-select>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

      </struct-form-layout>

      <struct-form-layout *ngIf="!isNewSubscription">
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Price
          </struct-form-row-headline>
          <struct-form-row-description>
            Price description 
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="3" *ngIf="subscription.paymentCurrency ==='DKK'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscription!.setup!.price!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="3" *ngIf="subscription.paymentCurrency ==='EUR'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscription.setup!.price!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="3" *ngIf="subscription.paymentCurrency ==='USD'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price'" [(value)]="subscription.setup!.price!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>
    </accounts-form-section-content>
  </accounts-form-section>
  <accounts-form-section>
    <accounts-form-section-title> Company Details </accounts-form-section-title>
    <accounts-form-section-description> Add the company details for the subscriber </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Subscription Name
          </struct-form-row-headline>
          <struct-form-row-description>
            Subscription Name description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-input [name]="'Subscription Name'" [(value)]="subscription.name" [required]="true"></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Company Name
          </struct-form-row-headline>
          <struct-form-row-description>
            Company Name description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-input [name]="'Company Name'" [(value)]="subscription.companyName" [required]="true"></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            VAT number
          </struct-form-row-headline>
          <struct-form-row-description>
            VAT number description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-input [name]="'VAT number'" [(value)]="subscription.vatNumber" [required]="true" [hintStart]="'Include countrycode ex: DK12345678'"></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Address
          </struct-form-row-headline>
          <struct-form-row-description>
            Address of customer
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-input [name]="'Address'" [(value)]="subscription.address" [required]="true" ></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Address 2
          </struct-form-row-headline>
          <struct-form-row-description>
            Additional address
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-input [name]="'Address 2'" [(value)]="subscription.address2" [required]="false" ></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Postal & city
          </struct-form-row-headline>
          <struct-form-row-description>
            Postal & city description
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                  <struct-input [name]="'Postal'" [(value)]="subscription.postal" [required]="true"></struct-input>
                </div>
                <div class="md:col-span-2">
                  <struct-input [name]="'City'" [(value)]="subscription.city" [required]="true"></struct-input>
                </div>
              </div>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Country
          </struct-form-row-headline>
          <struct-form-row-description> 
            Country of subscriber
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-simple-select name="Country" [(ngModel)]="subscription.country" bindValue="id" bindLabel="name" [required]="true" placeholder="Country" [options]="(countries$ | async) ?? []"></struct-simple-select>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

      </struct-form-layout>



    </accounts-form-section-content>
  </accounts-form-section>

  <accounts-form-section>
    <accounts-form-section-title> Contact details </accounts-form-section-title>
    <accounts-form-section-description> Add the contact details for the subscriber </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Contact Name
          </struct-form-row-headline>
          <struct-form-row-description>
            Name of the contact
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-input [name]="'Contact Name'" [(value)]="subscription.contactPerson" [required]="true"></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Contact Phone
          </struct-form-row-headline>
          <struct-form-row-description>
            Phone of the contact
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-input [name]="'Contact Phone'" [(value)]="subscription.contactPhone" [required]="true"></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Contact Email
          </struct-form-row-headline>
          <struct-form-row-description>
            Email of the contact
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-input [name]="'Contact Email'" [(value)]="subscription.contactEmail" [required]="true"></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
        

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Invoice Email
          </struct-form-row-headline>
          <struct-form-row-description>
            Email where invoices are sent to
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <struct-input [name]="'Invoice Email'" [(value)]="subscription.invoiceEmail" [required]="true"></struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>
      
    </accounts-form-section-content>
  </accounts-form-section>


 

  <accounts-form-section *ngIf="!isNewSubscription">
    <accounts-form-section-title> Managers and owners </accounts-form-section-title>
    <accounts-form-section-description> Managers and owners to this subscription </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="true">
          <struct-form-row-headline>
            Assigned to subscription
          </struct-form-row-headline>
          <struct-form-row-description>
            
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <div *ngFor="let manager of usersInSubscription" class="mt-1 grid grid-cols-12 items-center">
                <span class="col-span-3">
                  {{ manager.firstName }} {{ manager.lastName }}
                </span>
                <span class="col-span-3">
                  {{ manager.email }}
                </span>
                <span class="col-span-3">
                  {{manager.role}}
                </span>
                <span class="col-span-3 text-left">
                  <struct-button buttonType="button" color="primary" (click)="removeUser(manager)">Remove user</struct-button>
                  <!-- <ng-container *ngIf="!manager.emailVerified && !manager.enableResendEmailVerification">
                    <div class="md:w-fit md:bg-blue-200 px-2">Awaiting accept of invite</div>
                  </ng-container>
                  <ng-container *ngIf="manager.enableResendEmailVerification">
                    <struct-button buttonType="button" color="primary" (click)="resendEmailVerification(manager)">Resend verification mail</struct-button>
                  </ng-container> -->
                </span>
              </div>
              <!-- <div class="">
                <struct-button buttonType="button" color="primary" [disabled]="usersNotInSubscription?.length===0" (click)="inviteSubscriptionManager()">Invite</struct-button>
              </div> -->
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Invitations
          </struct-form-row-headline>
          <struct-form-row-description>
            
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="6">
              <div *ngFor="let invite of userInvitations" class="mt-1 grid grid-cols-12 items-center">
                <span class="col-span-3">
                  {{ invite.email }}
                </span>
                <span class="col-span-3">
                  {{invite.subscriptionRole}}
                </span>
                <span class="col-span-3">
                  {{invite.expires | date}}
                </span>
                <span class="col-span-3 text-left">
                  <struct-button buttonType="button" color="primary" (click)="resendInvite(invite)">Resend invite</struct-button>
                  <struct-button buttonType="button" color="primary" (click)="cancelInvite(invite)">Cancel invite</struct-button>


                  <!-- <ng-container *ngIf="!manager.emailVerified && !manager.enableResendEmailVerification">
                    <div class="md:w-fit md:bg-blue-200 px-2">Awaiting accept of invite</div>
                  </ng-container>
                  <ng-container *ngIf="manager.enableResendEmailVerification">
                    <struct-button buttonType="button" color="primary" (click)="resendEmailVerification(manager)">Resend verification mail</struct-button>
                  </ng-container> -->
                </span>
              </div>
              <div class="">
                <struct-button buttonType="button" color="primary"  (click)="inviteSubscriptionManager()">Invite</struct-button>
              </div>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

      </struct-form-layout>
    </accounts-form-section-content>
  </accounts-form-section>

  <accounts-form-section  *ngIf="!isNewSubscription">
    <accounts-form-section-title> Enabled Modules </accounts-form-section-title>
    <accounts-form-section-description> Select which modules should be enabled on subscription </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Azure AD Federation
          </struct-form-row-headline>
          <struct-form-row-description>
            Enable Azure AD Federation
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscription.setup!.enabledModules!.azureADFederationEnabled">Azure AD Federation Enabled</struct-checkbox>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.setup!.enabledModules!.azureADFederationEnabled && subscription.paymentCurrency ==='DKK'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price DKK'" [(value)]="subscription.setup!.enabledModules!.azureADFederationPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.setup!.enabledModules!.azureADFederationEnabled && subscription.paymentCurrency ==='EUR'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price EUR'" [(value)]="subscription.setup!.enabledModules!.azureADFederationPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.setup!.enabledModules!.azureADFederationEnabled && subscription.paymentCurrency ==='USD'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price USD'" [(value)]="subscription.setup!.enabledModules!.azureADFederationPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            AI Assistant
          </struct-form-row-headline>
          <struct-form-row-description>
            Enable AI Assistant
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscription.setup!.enabledModules!.aiAssistantEnabled">AI Assistant Enabled</struct-checkbox>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.setup!.enabledModules!.aiAssistantEnabled && subscription.paymentCurrency ==='DKK'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price DKK'" [(value)]="subscription.setup!.enabledModules!.aiAssistantPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.setup!.enabledModules!.aiAssistantEnabled && subscription.paymentCurrency ==='EUR'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price EUR'" [(value)]="subscription.setup!.enabledModules!.aiAssistantPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.setup!.enabledModules!.aiAssistantEnabled && subscription.paymentCurrency ==='USD'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price USD'" [(value)]="subscription.setup!.enabledModules!.aiAssistantPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>


        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Publications
          </struct-form-row-headline>
          <struct-form-row-description>
            Enable Publications
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscription.setup!.enabledModules!.publicationEnabled">Publications Enabled</struct-checkbox>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.setup!.enabledModules!.publicationEnabled && subscription.paymentCurrency ==='DKK'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price DKK'" [(value)]="subscription.setup!.enabledModules!.publicationPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.setup!.enabledModules!.publicationEnabled && subscription.paymentCurrency ==='EUR'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price EUR'" [(value)]="subscription.setup!.enabledModules!.publicationPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.setup!.enabledModules!.publicationEnabled && subscription.paymentCurrency ==='USD'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price USD'" [(value)]="subscription.setup!.enabledModules!.publicationPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>


        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Supplier Portal
          </struct-form-row-headline>
          <struct-form-row-description>
            Enable Supplier Portal
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscription.setup!.enabledModules!.supplierPortalEnabled">Publications Enabled</struct-checkbox>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.setup!.enabledModules!.supplierPortalEnabled && subscription.paymentCurrency ==='DKK'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price DKK'" [(value)]="subscription.setup!.enabledModules!.supplierPortalPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.setup!.enabledModules!.supplierPortalEnabled && subscription.paymentCurrency ==='EUR'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price EUR'" [(value)]="subscription.setup!.enabledModules!.supplierPortalPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.setup!.enabledModules!.supplierPortalEnabled && subscription.paymentCurrency ==='USD'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Price USD'" [(value)]="subscription.setup!.enabledModules!.supplierPortalPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

      </struct-form-layout>
      
    </accounts-form-section-content>
  </accounts-form-section>





  <accounts-form-section  *ngIf="!isNewSubscription && !subscription.setup!.quotasOverride">
    <accounts-form-section-title> Quotas </accounts-form-section-title>
    <accounts-form-section-description> Quota setup for a subscription plan. Quotas can be overriden on a subscription. 
      <div><struct-button buttonType="button" color="primary" (click)="overrideQuotas()">Override</struct-button></div>
    </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Language Quota
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings.
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Included Languages'" [(value)]="subscriptionPlan!.setup!.quotas!.languageQuota!.includedLanguages" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Language Pack Size'" [(value)]="subscriptionPlan!.setup!.quotas!.languageQuota!.extraLanguagePackSize" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='DKK'">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Language Pack Price'" [(value)]="subscriptionPlan!.setup!.quotas!.languageQuota!.extraLanguagePackPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='EUR'">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Language Pack Price'" [(value)]="subscriptionPlan!.setup!.quotas!.languageQuota!.extraLanguagePackPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='USD'">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Language Pack Price'" [(value)]="subscriptionPlan!.setup!.quotas!.languageQuota!.extraLanguagePackPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            User Quota
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings.
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Included Users'" [(value)]="subscriptionPlan!.setup!.quotas!.userQuota!.includedUsers" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Language Pack Size'" [(value)]="subscriptionPlan!.setup!.quotas!.userQuota!.extraUsersPackSize" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='DKK'">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Language Pack Price'" [(value)]="subscriptionPlan!.setup!.quotas!.userQuota!.extraUsersPackPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='EUR'">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Language Pack Price'" [(value)]="subscriptionPlan!.setup!.quotas!.userQuota!.extraUsersPackPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='USD'">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Language Pack Price'" [(value)]="subscriptionPlan!.setup!.quotas!.userQuota!.extraUsersPackPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>


        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Tenant Quota
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings.
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Included Tenants'" [(value)]="subscriptionPlan!.setup!.quotas!.tenantQuota!.includedTenants" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Max Number Tenants'" [(value)]="subscriptionPlan!.setup!.quotas!.tenantQuota!.maxNumTenants" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='DKK'">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Tenant Price DKK'" [(value)]="subscriptionPlan!.setup!.quotas!.tenantQuota!.extraTenantPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='EUR'">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Tenant Price EUR'" [(value)]="subscriptionPlan!.setup!.quotas!.tenantQuota!.extraTenantPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='USD'">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Tenant Price USD'" [(value)]="subscriptionPlan!.setup!.quotas!.tenantQuota!.extraTenantPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>


        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Custom Extension
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Number of customs extensions'" [(value)]="subscriptionPlan!.setup!.quotas!.customExtensionQuota!.numCustomExtensionsIncluded" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Change Log Retention
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Days change log retention'" [(value)]="subscriptionPlan!.setup!.quotas!.changelogRetentionQuota!.numDaysRetention" [required]="true">
                <struct-input-suffix><span>DAYS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>


        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Asset Storage Quota
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings.
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Asset Storage Included In GB'" [(value)]="subscriptionPlan!.setup!.quotas!.assetStorageQuota!.assetStorageIncludedInGB" [required]="true">
                <struct-input-suffix><span>GB</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Asset Storage Pack Size In GB'" [(value)]="subscriptionPlan!.setup!.quotas!.assetStorageQuota!.extraAssetStoragePackSizeInGB" [required]="true">
                <struct-input-suffix><span>GB</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='DKK'">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Asset Storage Pack Price DKK'" [(value)]="subscriptionPlan!.setup!.quotas!.assetStorageQuota!.extraAssetStoragePackPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='EUR'">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Asset Storage Pack Price EUR'" [(value)]="subscriptionPlan!.setup!.quotas!.assetStorageQuota!.extraAssetStoragePackPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='USD'">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Asset Storage Pack Price USD'" [(value)]="subscriptionPlan!.setup!.quotas!.assetStorageQuota!.extraAssetStoragePackPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>


        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Asset Bandwidth Quota
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings.
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Asset Bandwidth Included In GB'" [(value)]="subscriptionPlan!.setup!.quotas!.assetBandwidthQuota!.assetBandwidthIncludedInGB" [required]="true">
                <struct-input-suffix><span>GB</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Asset Bandwidth Pack Size In GB'" [(value)]="subscriptionPlan!.setup!.quotas!.assetBandwidthQuota!.extraAssetBandwidthPackSizeInGB" [required]="true">
                <struct-input-suffix><span>GB</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='DKK'">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Asset Bandwidth Pack Price DKK'" [(value)]="subscriptionPlan!.setup!.quotas!.assetBandwidthQuota!.extraAssetBandwidthPackPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='EUR'">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Asset Bandwidth Pack Price EUR'" [(value)]="subscriptionPlan!.setup!.quotas!.assetBandwidthQuota!.extraAssetBandwidthPackPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='USD'">
              <struct-input [inputType]="'number'" [disabled]="true" [textAlign]="'right'" [name]="'Extra Asset Bandwidth Pack Price USD'" [(value)]="subscriptionPlan!.setup!.quotas!.assetBandwidthQuota!.extraAssetBandwidthPackPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>




      </struct-form-layout>
      
    </accounts-form-section-content>
  </accounts-form-section>


  <accounts-form-section *ngIf="!isNewSubscription && subscription.setup!.quotasOverride">
    <accounts-form-section-title>
      Quotas
    </accounts-form-section-title>
    <accounts-form-section-description>
      Quota setup for a subscription plan. Quotas can be overriden on a subscription.
      <div style="color: red; font-weight: bold;">
        Quotas are overridden from subscription plan
      </div>
    </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Language Quota
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings.
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Included Languages'" [(value)]="subscription.setup!.quotasOverride!.languageQuota!.includedLanguages" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Language Pack Size'" [(value)]="subscription.setup!.quotasOverride!.languageQuota!.extraLanguagePackSize" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='DKK'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Language Pack Price'" [(value)]="subscription.setup!.quotasOverride!.languageQuota!.extraLanguagePackPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='EUR'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Language Pack Price'" [(value)]="subscription.setup!.quotasOverride!.languageQuota!.extraLanguagePackPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='USD'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Language Pack Price'" [(value)]="subscription.setup!.quotasOverride!.languageQuota!.extraLanguagePackPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>


        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            User Quota
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings.
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Included Users'" [(value)]="subscription.setup!.quotasOverride!.userQuota!.includedUsers" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Language Pack Size'" [(value)]="subscription.setup!.quotasOverride!.userQuota!.extraUsersPackSize" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='DKK'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Language Pack Price'" [(value)]="subscription.setup!.quotasOverride!.userQuota!.extraUsersPackPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='EUR'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Language Pack Price'" [(value)]="subscription.setup!.quotasOverride!.userQuota!.extraUsersPackPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='USD'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Language Pack Price'" [(value)]="subscription.setup!.quotasOverride!.userQuota!.extraUsersPackPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Tenant Quota
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings.
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Included Tenants'" [(value)]="subscription.setup!.quotasOverride!.tenantQuota!.includedTenants" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Max Number Tenants'" [(value)]="subscription.setup!.quotasOverride!.tenantQuota!.maxNumTenants" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='DKK'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Tenant Price DKK'" [(value)]="subscription.setup!.quotasOverride!.tenantQuota!.extraTenantPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='EUR'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Tenant Price EUR'" [(value)]="subscription.setup!.quotasOverride!.tenantQuota!.extraTenantPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='USD'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Tenant Price USD'" [(value)]="subscription.setup!.quotasOverride!.tenantQuota!.extraTenantPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>


        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Custom Extension
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Number of customs extensions'" [(value)]="subscription.setup!.quotasOverride!.customExtensionQuota!.numCustomExtensionsIncluded" [required]="true">
                <struct-input-suffix><span>PCS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            
          </struct-form-row-grid>
        </struct-form-row>

        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Change Log Retention
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Days change log retention'" [(value)]="subscription.setup!.quotasOverride!.changelogRetentionQuota!.numDaysRetention" [required]="true">
                <struct-input-suffix><span>DAYS</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>


        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Asset Storage Quota
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings.
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Asset Storage Included In GB'" [(value)]="subscription.setup!.quotasOverride!.assetStorageQuota!.assetStorageIncludedInGB" [required]="true">
                <struct-input-suffix><span>GB</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Asset Storage Pack Size In GB'" [(value)]="subscription.setup!.quotasOverride!.assetStorageQuota!.extraAssetStoragePackSizeInGB" [required]="true">
                <struct-input-suffix><span>GB</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='DKK'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Asset Storage Pack Price DKK'" [(value)]="subscription.setup!.quotasOverride!.assetStorageQuota!.extraAssetStoragePackPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='EUR'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Asset Storage Pack Price EUR'" [(value)]="subscription.setup!.quotasOverride!.assetStorageQuota!.extraAssetStoragePackPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='USD'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Asset Storage Pack Price USD'" [(value)]="subscription.setup!.quotasOverride!.assetStorageQuota!.extraAssetStoragePackPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>


        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Asset Bandwidth Quota
          </struct-form-row-headline>
          <struct-form-row-description>
            Quota settings.
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Asset Bandwidth Included In GB'" [(value)]="subscription.setup!.quotasOverride!.assetBandwidthQuota!.assetBandwidthIncludedInGB" [required]="true">
                <struct-input-suffix><span>GB</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Asset Bandwidth Pack Size In GB'" [(value)]="subscription.setup!.quotasOverride!.assetBandwidthQuota!.extraAssetBandwidthPackSizeInGB" [required]="true">
                <struct-input-suffix><span>GB</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='DKK'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Asset Bandwidth Pack Price DKK'" [(value)]="subscription.setup!.quotasOverride!.assetBandwidthQuota!.extraAssetBandwidthPackPrice!.priceDKK" [required]="true">
                <struct-input-suffix><span>DKK</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='EUR'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Asset Bandwidth Pack Price EUR'" [(value)]="subscription.setup!.quotasOverride!.assetBandwidthQuota!.extraAssetBandwidthPackPrice!.priceEUR" [required]="true">
                <struct-input-suffix><span>EUR</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
            <struct-form-row-grid-tile data-cols="2" *ngIf="subscription.paymentCurrency ==='USD'">
              <struct-input [inputType]="'number'" [textAlign]="'right'" [name]="'Extra Asset Bandwidth Pack Price USD'" [(value)]="subscription.setup!.quotasOverride!.assetBandwidthQuota!.extraAssetBandwidthPackPrice!.priceUSD" [required]="true">
                <struct-input-suffix><span>USD</span></struct-input-suffix>
              </struct-input>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>

      </struct-form-layout>
    </accounts-form-section-content>
  </accounts-form-section>

  <accounts-form-section  *ngIf="!isNewSubscription && !subscription.setup!.optionalFeaturesOverride">
    <accounts-form-section-title>Optional Features </accounts-form-section-title>
    <accounts-form-section-description>Optional Features 
      <div>
        <struct-button buttonType="button" color="primary" (click)="overrideOptionalFeatures()">Override</struct-button>
    </div>
    </accounts-form-section-description>
    <accounts-form-section-content>
     

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Advanced Media Library
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [disabled]="true" [(value)]="subscriptionPlan!.setup!.optionalFeatures!.advancedMediaLibrary">Advanced Media Library</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Business Rules
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [disabled]="true" [(value)]="subscriptionPlan!.setup!.optionalFeatures!.businessRules">Business Rules</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Dynamic Categories
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [disabled]="true" [(value)]="subscriptionPlan!.setup!.optionalFeatures!.dynamicCategories">Dynamic Categories</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Dimensioned Attributes
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [disabled]="true" [(value)]="subscriptionPlan!.setup!.optionalFeatures!.dimensionedAttributes">Dimensioned Attributes</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Business Units
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [disabled]="true" [(value)]="subscriptionPlan!.setup!.optionalFeatures!.businessUnits">Business Units</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Enrichment Insights
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [disabled]="true" [(value)]="subscriptionPlan!.setup!.optionalFeatures!.enrichmentInsights">Enrichment Insights</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Workflows
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [disabled]="true" [(value)]="subscriptionPlan!.setup!.optionalFeatures!.workflows">Workflows</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            User Tasks
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [disabled]="true" [(value)]="subscriptionPlan!.setup!.optionalFeatures!.userTasks">User Tasks</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>




      
    </accounts-form-section-content>
  </accounts-form-section>



  <accounts-form-section   *ngIf="!isNewSubscription && subscription.setup!.optionalFeaturesOverride">
    <accounts-form-section-title>
      Optional Features
    </accounts-form-section-title>
    <accounts-form-section-description>
      Which optional features are included
      <div style="color: red; font-weight: bold;">
        Optional Features are overridden from subscription plan
      </div>
    </accounts-form-section-description>
    <accounts-form-section-content>
      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Advanced Media Library
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscription.setup!.optionalFeaturesOverride!.advancedMediaLibrary">Advanced Media Library</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Business Rules
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscription.setup!.optionalFeaturesOverride!.businessRules">Business Rules</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Dynamic Categories
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscription.setup!.optionalFeaturesOverride!.dynamicCategories">Dynamic Categories</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Dimensioned Attributes
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscription.setup!.optionalFeaturesOverride!.dimensionedAttributes">Dimensioned Attributes</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Business Units
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscription.setup!.optionalFeaturesOverride!.businessUnits">Business Units</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Enrichment Insights
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscription.setup!.optionalFeaturesOverride!.enrichmentInsights">Enrichment Insights</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            Workflows
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscription.setup!.optionalFeaturesOverride!.workflows">Workflows</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

      <struct-form-layout>
        <struct-form-row [borderBelow]="false">
          <struct-form-row-headline>
            User Tasks
          </struct-form-row-headline>
          <struct-form-row-description>
            Check to enable
          </struct-form-row-description>
          <struct-form-row-grid>
            <struct-form-row-grid-tile data-cols="2">
              <struct-checkbox [(value)]="subscription.setup!.optionalFeaturesOverride!.userTasks">User Tasks</struct-checkbox>
            </struct-form-row-grid-tile>
          </struct-form-row-grid>
        </struct-form-row>
      </struct-form-layout>

    </accounts-form-section-content>
  </accounts-form-section>

</accounts-form>