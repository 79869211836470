import { Component } from '@angular/core';

@Component({
    selector: 'struct-input-suffix',
    templateUrl: './struct-input-suffix.component.html',
    styleUrls: ['./struct-input-suffix.component.scss'],
    standalone: false
})
export class StructInputSuffixComponent {
}
